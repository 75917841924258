import {_ as _extends} from "./extends-1379b0c5.js";
import {_ as _objectWithoutPropertiesLoose} from "./objectWithoutPropertiesLoose-fe69c3b9.js";
import {r as react} from "./index-c6c556cc.js";
import {M as MuiPickersAdapterContext} from "./LocalizationProvider-2b24e255.js";
import {u as useThemeProps} from "./useThemeProps-58507f80.js";
import {c as clsx} from "./clsx.m-e1b0361e.js";
import {j as jsxRuntime} from "./jsx-runtime-c4f7c71f.js";
import {c as createSvgIcon} from "./createSvgIcon-e773c0f9.js";
import {g as generateUtilityClasses} from "./generateUtilityClasses-f1d2e319.js";
import {s as styled} from "./styled-d39d6e04.js";
import {d as dialogTitleClasses, I as IconButton, G as Grid, a as InputAdornment, b as Dialog, e as dialogClasses, c as DialogActions} from "./InputAdornment-2a20ff53.js";
import {T as Typography} from "./Typography-89a03a72.js";
import {g as generateUtilityClass, c as composeClasses} from "./generateUtilityClass-a8a7346c.js";
import {B as Button} from "./Button-3b491d7d.js";
import {P as PropTypes} from "./index-d379a862.js";
import {u as useControlled} from "./useControlled-697dd2b3.js";
import {u as useEnhancedEffect} from "./useEnhancedEffect-f0068896.js";
import {u as useTheme} from "./useTheme-9b2d8ad1.js";
import {u as useId} from "./useId-60647959.js";
import {b as alpha} from "./createTheme-1192a460.js";
import {T as TransitionGroup} from "./TransitionGroup-738674fd.js";
import {F as Fade} from "./Modal-c5aadcc8.js";
import {B as ButtonBase} from "./ButtonBase-e0475103.js";
import {u as useForkRef} from "./useForkRef-8d56fe3c.js";
import {C as CSSTransition} from "./CSSTransition-bc876051.js";
import {c as capitalize} from "./createTheme-723fa293.js";
function getDialogContentUtilityClass(slot) {
  return generateUtilityClass("MuiDialogContent", slot);
}
generateUtilityClasses("MuiDialogContent", ["root", "dividers"]);
const _excluded$d = ["className", "dividers"];
const useUtilityClasses$6 = (ownerState) => {
  const {
    classes: classes2,
    dividers
  } = ownerState;
  const slots = {
    root: ["root", dividers && "dividers"]
  };
  return composeClasses(slots, getDialogContentUtilityClass, classes2);
};
const DialogContentRoot = styled("div", {
  name: "MuiDialogContent",
  slot: "Root",
  overridesResolver: (props, styles) => {
    const {
      ownerState
    } = props;
    return [styles.root, ownerState.dividers && styles.dividers];
  }
})(({
  theme,
  ownerState
}) => _extends({
  flex: "1 1 auto",
  WebkitOverflowScrolling: "touch",
  overflowY: "auto",
  padding: "20px 24px"
}, ownerState.dividers ? {
  padding: "16px 24px",
  borderTop: `1px solid ${(theme.vars || theme).palette.divider}`,
  borderBottom: `1px solid ${(theme.vars || theme).palette.divider}`
} : {
  [`.${dialogTitleClasses.root} + &`]: {
    paddingTop: 0
  }
}));
const DialogContent = /* @__PURE__ */ react.forwardRef(function DialogContent2(inProps, ref) {
  const props = useThemeProps({
    props: inProps,
    name: "MuiDialogContent"
  });
  const {
    className,
    dividers = false
  } = props, other = _objectWithoutPropertiesLoose(props, _excluded$d);
  const ownerState = _extends({}, props, {
    dividers
  });
  const classes2 = useUtilityClasses$6(ownerState);
  return /* @__PURE__ */ jsxRuntime.jsx(DialogContentRoot, _extends({
    className: clsx(classes2.root, className),
    ownerState,
    ref
  }, other));
});
var DialogContent$1 = DialogContent;
function arrayIncludes(array, itemOrItems) {
  if (Array.isArray(itemOrItems)) {
    return itemOrItems.every((item) => array.indexOf(item) !== -1);
  }
  return array.indexOf(itemOrItems) !== -1;
}
const onSpaceOrEnter = (innerFn, onFocus) => (event) => {
  if (event.key === "Enter" || event.key === " ") {
    innerFn();
    event.preventDefault();
    event.stopPropagation();
  }
  if (onFocus) {
    onFocus(event);
  }
};
function createDelegatedEventHandler(fn, onEvent) {
  return (event) => {
    fn(event);
    if (onEvent) {
      onEvent(event);
    }
  };
}
const _excluded$c = ["disabled", "onSelect", "selected", "value"];
const classes$6 = generateUtilityClasses("PrivatePickersMonth", ["root", "selected"]);
const PickersMonthRoot = styled(Typography)(({
  theme
}) => _extends({
  flex: "1 0 33.33%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "unset",
  backgroundColor: "transparent",
  border: 0,
  outline: 0
}, theme.typography.subtitle1, {
  margin: "8px 0",
  height: 36,
  borderRadius: 18,
  cursor: "pointer",
  "&:focus, &:hover": {
    backgroundColor: alpha(theme.palette.action.active, theme.palette.action.hoverOpacity)
  },
  "&:disabled": {
    pointerEvents: "none",
    color: theme.palette.text.secondary
  },
  [`&.${classes$6.selected}`]: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    "&:focus, &:hover": {
      backgroundColor: theme.palette.primary.dark
    }
  }
}));
const PickersMonth = (props) => {
  const {
    disabled,
    onSelect,
    selected,
    value
  } = props, other = _objectWithoutPropertiesLoose(props, _excluded$c);
  const handleSelection = () => {
    onSelect(value);
  };
  return /* @__PURE__ */ jsxRuntime.jsx(PickersMonthRoot, _extends({
    component: "button",
    type: "button",
    className: clsx(classes$6.root, selected && classes$6.selected),
    tabIndex: disabled ? -1 : 0,
    onClick: handleSelection,
    onKeyDown: onSpaceOrEnter(handleSelection),
    color: selected ? "primary" : void 0,
    variant: selected ? "h5" : "subtitle1",
    disabled
  }, other));
};
const useLocalizationContext = () => {
  const localization = react.useContext(MuiPickersAdapterContext);
  if (localization === null) {
    throw new Error("MUI: Can not find utils in context. It looks like you forgot to wrap your component in LocalizationProvider, or pass dateAdapter prop directly.");
  }
  return localization;
};
const useUtils = () => useLocalizationContext().utils;
const useDefaultDates = () => useLocalizationContext().defaultDates;
const useNow = () => {
  const utils = useUtils();
  const now = react.useRef(utils.date());
  return now.current;
};
function getMonthPickerUtilityClass(slot) {
  return generateUtilityClass("MuiMonthPicker", slot);
}
generateUtilityClasses("MuiMonthPicker", ["root"]);
const _excluded$b = ["className", "date", "disabled", "disableFuture", "disablePast", "maxDate", "minDate", "onChange", "onMonthChange", "shouldDisableMonth", "readOnly"];
const useUtilityClasses$5 = (ownerState) => {
  const {
    classes: classes2
  } = ownerState;
  const slots = {
    root: ["root"]
  };
  return composeClasses(slots, getMonthPickerUtilityClass, classes2);
};
const MonthPickerRoot = styled("div", {
  name: "MuiMonthPicker",
  slot: "Root",
  overridesResolver: (props, styles) => styles.root
})({
  width: 310,
  display: "flex",
  flexWrap: "wrap",
  alignContent: "stretch",
  margin: "0 4px"
});
const MonthPicker = /* @__PURE__ */ react.forwardRef(function MonthPicker2(inProps, ref) {
  const props = useThemeProps({
    props: inProps,
    name: "MuiMonthPicker"
  });
  const {
    className,
    date,
    disabled,
    disableFuture,
    disablePast,
    maxDate,
    minDate,
    onChange,
    onMonthChange,
    shouldDisableMonth,
    readOnly
  } = props, other = _objectWithoutPropertiesLoose(props, _excluded$b);
  const ownerState = props;
  const classes2 = useUtilityClasses$5(ownerState);
  const utils = useUtils();
  const now = useNow();
  const currentMonth = utils.getMonth(date || now);
  const isMonthDisabled = (month) => {
    const firstEnabledMonth = utils.startOfMonth(disablePast && utils.isAfter(now, minDate) ? now : minDate);
    const lastEnabledMonth = utils.startOfMonth(disableFuture && utils.isBefore(now, maxDate) ? now : maxDate);
    if (utils.isBefore(month, firstEnabledMonth)) {
      return true;
    }
    if (utils.isAfter(month, lastEnabledMonth)) {
      return true;
    }
    if (!shouldDisableMonth) {
      return false;
    }
    return shouldDisableMonth(month);
  };
  const onMonthSelect = (month) => {
    if (readOnly) {
      return;
    }
    const newDate = utils.setMonth(date || now, month);
    onChange(newDate, "finish");
    if (onMonthChange) {
      onMonthChange(newDate);
    }
  };
  return /* @__PURE__ */ jsxRuntime.jsx(MonthPickerRoot, _extends({
    ref,
    className: clsx(classes2.root, className),
    ownerState
  }, other, {
    children: utils.getMonthArray(date || now).map((month) => {
      const monthNumber = utils.getMonth(month);
      const monthText = utils.format(month, "monthShort");
      return /* @__PURE__ */ jsxRuntime.jsx(PickersMonth, {
        value: monthNumber,
        selected: monthNumber === currentMonth,
        onSelect: onMonthSelect,
        disabled: disabled || isMonthDisabled(month),
        children: monthText
      }, monthText);
    })
  }));
});
function useValidation(props, validate, isSameError) {
  const {
    value,
    onError
  } = props;
  const utils = useUtils();
  const previousValidationErrorRef = react.useRef(null);
  const validationError = validate(utils, value, props);
  react.useEffect(() => {
    if (onError && !isSameError(validationError, previousValidationErrorRef.current)) {
      onError(validationError, value);
    }
    previousValidationErrorRef.current = validationError;
  }, [isSameError, onError, previousValidationErrorRef, validationError, value]);
  return validationError;
}
const validateDate = (utils, value, {
  disablePast,
  disableFuture,
  minDate,
  maxDate,
  shouldDisableDate
}) => {
  const now = utils.date();
  const date = utils.date(value);
  if (date === null) {
    return null;
  }
  switch (true) {
    case !utils.isValid(value):
      return "invalidDate";
    case Boolean(shouldDisableDate && shouldDisableDate(date)):
      return "shouldDisableDate";
    case Boolean(disableFuture && utils.isAfterDay(date, now)):
      return "disableFuture";
    case Boolean(disablePast && utils.isBeforeDay(date, now)):
      return "disablePast";
    case Boolean(minDate && utils.isBeforeDay(date, minDate)):
      return "minDate";
    case Boolean(maxDate && utils.isAfterDay(date, maxDate)):
      return "maxDate";
    default:
      return null;
  }
};
const isSameDateError = (a, b) => a === b;
const useDateValidation = (props) => useValidation(props, validateDate, isSameDateError);
const createCalendarStateReducer = (reduceAnimations, disableSwitchToMonthOnDayFocus, utils) => (state, action) => {
  switch (action.type) {
    case "changeMonth":
      return _extends({}, state, {
        slideDirection: action.direction,
        currentMonth: action.newMonth,
        isMonthSwitchingAnimating: !reduceAnimations
      });
    case "finishMonthSwitchingAnimation":
      return _extends({}, state, {
        isMonthSwitchingAnimating: false
      });
    case "changeFocusedDay": {
      if (state.focusedDay !== null && utils.isSameDay(action.focusedDay, state.focusedDay)) {
        return state;
      }
      const needMonthSwitch = Boolean(action.focusedDay) && !disableSwitchToMonthOnDayFocus && !utils.isSameMonth(state.currentMonth, action.focusedDay);
      return _extends({}, state, {
        focusedDay: action.focusedDay,
        isMonthSwitchingAnimating: needMonthSwitch && !reduceAnimations,
        currentMonth: needMonthSwitch ? utils.startOfMonth(action.focusedDay) : state.currentMonth,
        slideDirection: utils.isAfterDay(action.focusedDay, state.currentMonth) ? "left" : "right"
      });
    }
    default:
      throw new Error("missing support");
  }
};
const useCalendarState = ({
  date,
  defaultCalendarMonth,
  disableFuture,
  disablePast,
  disableSwitchToMonthOnDayFocus = false,
  maxDate,
  minDate,
  onMonthChange,
  reduceAnimations,
  shouldDisableDate
}) => {
  var _ref;
  const now = useNow();
  const utils = useUtils();
  const reducerFn = react.useRef(createCalendarStateReducer(Boolean(reduceAnimations), disableSwitchToMonthOnDayFocus, utils)).current;
  const [calendarState, dispatch] = react.useReducer(reducerFn, {
    isMonthSwitchingAnimating: false,
    focusedDay: date || now,
    currentMonth: utils.startOfMonth((_ref = date != null ? date : defaultCalendarMonth) != null ? _ref : now),
    slideDirection: "left"
  });
  const handleChangeMonth = react.useCallback((payload) => {
    dispatch(_extends({
      type: "changeMonth"
    }, payload));
    if (onMonthChange) {
      onMonthChange(payload.newMonth);
    }
  }, [onMonthChange]);
  const changeMonth = react.useCallback((newDate) => {
    const newDateRequested = newDate != null ? newDate : now;
    if (utils.isSameMonth(newDateRequested, calendarState.currentMonth)) {
      return;
    }
    handleChangeMonth({
      newMonth: utils.startOfMonth(newDateRequested),
      direction: utils.isAfterDay(newDateRequested, calendarState.currentMonth) ? "left" : "right"
    });
  }, [calendarState.currentMonth, handleChangeMonth, now, utils]);
  const isDateDisabled = react.useCallback((day) => validateDate(utils, day, {
    disablePast,
    disableFuture,
    minDate,
    maxDate,
    shouldDisableDate
  }) !== null, [disableFuture, disablePast, maxDate, minDate, shouldDisableDate, utils]);
  const onMonthSwitchingAnimationEnd = react.useCallback(() => {
    dispatch({
      type: "finishMonthSwitchingAnimation"
    });
  }, []);
  const changeFocusedDay = react.useCallback((newFocusedDate) => {
    if (!isDateDisabled(newFocusedDate)) {
      dispatch({
        type: "changeFocusedDay",
        focusedDay: newFocusedDate
      });
    }
  }, [isDateDisabled]);
  return {
    calendarState,
    changeMonth,
    changeFocusedDay,
    isDateDisabled,
    onMonthSwitchingAnimationEnd,
    handleChangeMonth
  };
};
const classes$5 = generateUtilityClasses("PrivatePickersFadeTransitionGroup", ["root"]);
const animationDuration = 500;
const PickersFadeTransitionGroupRoot = styled(TransitionGroup)({
  display: "block",
  position: "relative"
});
const PickersFadeTransitionGroup = ({
  children,
  className,
  reduceAnimations,
  transKey
}) => {
  if (reduceAnimations) {
    return children;
  }
  return /* @__PURE__ */ jsxRuntime.jsx(PickersFadeTransitionGroupRoot, {
    className: clsx(classes$5.root, className),
    children: /* @__PURE__ */ jsxRuntime.jsx(Fade, {
      appear: false,
      mountOnEnter: true,
      unmountOnExit: true,
      timeout: {
        appear: animationDuration,
        enter: animationDuration / 2,
        exit: 0
      },
      children
    }, transKey)
  });
};
const DAY_SIZE = 36;
const DAY_MARGIN = 2;
const DIALOG_WIDTH = 320;
const VIEW_HEIGHT = 358;
function getPickersDayUtilityClass(slot) {
  return generateUtilityClass("MuiPickersDay", slot);
}
const pickersDayClasses = generateUtilityClasses("MuiPickersDay", ["root", "dayWithMargin", "dayOutsideMonth", "hiddenDaySpacingFiller", "today", "selected", "disabled"]);
const _excluded$a = ["autoFocus", "className", "day", "disabled", "disableHighlightToday", "disableMargin", "hidden", "isAnimating", "onClick", "onDayFocus", "onDaySelect", "onFocus", "onKeyDown", "outsideCurrentMonth", "selected", "showDaysOutsideCurrentMonth", "children", "today"];
const useUtilityClasses$4 = (ownerState) => {
  const {
    selected,
    disableMargin,
    disableHighlightToday,
    today,
    outsideCurrentMonth,
    showDaysOutsideCurrentMonth,
    classes: classes2
  } = ownerState;
  const slots = {
    root: ["root", selected && "selected", !disableMargin && "dayWithMargin", !disableHighlightToday && today && "today", outsideCurrentMonth && showDaysOutsideCurrentMonth && "dayOutsideMonth"],
    hiddenDaySpacingFiller: ["hiddenDaySpacingFiller"]
  };
  return composeClasses(slots, getPickersDayUtilityClass, classes2);
};
const styleArg = ({
  theme,
  ownerState
}) => _extends({}, theme.typography.caption, {
  width: DAY_SIZE,
  height: DAY_SIZE,
  borderRadius: "50%",
  padding: 0,
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.text.primary,
  "&:hover": {
    backgroundColor: alpha(theme.palette.action.active, theme.palette.action.hoverOpacity)
  },
  "&:focus": {
    backgroundColor: alpha(theme.palette.action.active, theme.palette.action.hoverOpacity),
    [`&.${pickersDayClasses.selected}`]: {
      willChange: "background-color",
      backgroundColor: theme.palette.primary.dark
    }
  },
  [`&.${pickersDayClasses.selected}`]: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    transition: theme.transitions.create("background-color", {
      duration: theme.transitions.duration.short
    }),
    "&:hover": {
      willChange: "background-color",
      backgroundColor: theme.palette.primary.dark
    }
  },
  [`&.${pickersDayClasses.disabled}`]: {
    color: theme.palette.text.disabled
  }
}, !ownerState.disableMargin && {
  margin: `0 ${DAY_MARGIN}px`
}, ownerState.outsideCurrentMonth && ownerState.showDaysOutsideCurrentMonth && {
  color: theme.palette.text.secondary
}, !ownerState.disableHighlightToday && ownerState.today && {
  [`&:not(.${pickersDayClasses.selected})`]: {
    border: `1px solid ${theme.palette.text.secondary}`
  }
});
const overridesResolver = (props, styles) => {
  const {
    ownerState
  } = props;
  return [styles.root, !ownerState.disableMargin && styles.dayWithMargin, !ownerState.disableHighlightToday && ownerState.today && styles.today, !ownerState.outsideCurrentMonth && ownerState.showDaysOutsideCurrentMonth && styles.dayOutsideMonth, ownerState.outsideCurrentMonth && !ownerState.showDaysOutsideCurrentMonth && styles.hiddenDaySpacingFiller];
};
const PickersDayRoot = styled(ButtonBase, {
  name: "MuiPickersDay",
  slot: "Root",
  overridesResolver
})(styleArg);
const PickersDayFiller = styled("div", {
  name: "MuiPickersDay",
  slot: "Root",
  overridesResolver
})(({
  theme,
  ownerState
}) => _extends({}, styleArg({
  theme,
  ownerState
}), {
  visibility: "hidden"
}));
const noop = () => {
};
const PickersDayRaw = /* @__PURE__ */ react.forwardRef(function PickersDay(inProps, forwardedRef) {
  const props = useThemeProps({
    props: inProps,
    name: "MuiPickersDay"
  });
  const {
    autoFocus = false,
    className,
    day,
    disabled = false,
    disableHighlightToday = false,
    disableMargin = false,
    isAnimating,
    onClick,
    onDayFocus = noop,
    onDaySelect,
    onFocus,
    onKeyDown,
    outsideCurrentMonth,
    selected = false,
    showDaysOutsideCurrentMonth = false,
    children,
    today: isToday = false
  } = props, other = _objectWithoutPropertiesLoose(props, _excluded$a);
  const ownerState = _extends({}, props, {
    autoFocus,
    disabled,
    disableHighlightToday,
    disableMargin,
    selected,
    showDaysOutsideCurrentMonth,
    today: isToday
  });
  const classes2 = useUtilityClasses$4(ownerState);
  const utils = useUtils();
  const ref = react.useRef(null);
  const handleRef = useForkRef(ref, forwardedRef);
  useEnhancedEffect(() => {
    if (autoFocus && !disabled && !isAnimating && !outsideCurrentMonth) {
      ref.current.focus();
    }
  }, [autoFocus, disabled, isAnimating, outsideCurrentMonth]);
  const handleFocus = (event) => {
    if (onDayFocus) {
      onDayFocus(day);
    }
    if (onFocus) {
      onFocus(event);
    }
  };
  const handleClick = (event) => {
    if (!disabled) {
      onDaySelect(day, "finish");
    }
    if (onClick) {
      onClick(event);
    }
  };
  const theme = useTheme();
  function handleKeyDown(event) {
    if (onKeyDown !== void 0) {
      onKeyDown(event);
    }
    switch (event.key) {
      case "ArrowUp":
        onDayFocus(utils.addDays(day, -7));
        event.preventDefault();
        break;
      case "ArrowDown":
        onDayFocus(utils.addDays(day, 7));
        event.preventDefault();
        break;
      case "ArrowLeft":
        onDayFocus(utils.addDays(day, theme.direction === "ltr" ? -1 : 1));
        event.preventDefault();
        break;
      case "ArrowRight":
        onDayFocus(utils.addDays(day, theme.direction === "ltr" ? 1 : -1));
        event.preventDefault();
        break;
      case "Home":
        onDayFocus(utils.startOfWeek(day));
        event.preventDefault();
        break;
      case "End":
        onDayFocus(utils.endOfWeek(day));
        event.preventDefault();
        break;
      case "PageUp":
        onDayFocus(utils.getNextMonth(day));
        event.preventDefault();
        break;
      case "PageDown":
        onDayFocus(utils.getPreviousMonth(day));
        event.preventDefault();
        break;
    }
  }
  if (outsideCurrentMonth && !showDaysOutsideCurrentMonth) {
    return /* @__PURE__ */ jsxRuntime.jsx(PickersDayFiller, {
      className: clsx(classes2.root, classes2.hiddenDaySpacingFiller, className),
      ownerState
    });
  }
  return /* @__PURE__ */ jsxRuntime.jsx(PickersDayRoot, _extends({
    className: clsx(classes2.root, className),
    ownerState,
    ref: handleRef,
    centerRipple: true,
    disabled,
    "aria-label": !children ? utils.format(day, "fullDate") : void 0,
    tabIndex: selected ? 0 : -1,
    onFocus: handleFocus,
    onKeyDown: handleKeyDown,
    onClick: handleClick
  }, other, {
    children: !children ? utils.format(day, "dayOfMonth") : children
  }));
});
const areDayPropsEqual = (prevProps, nextProps) => {
  return prevProps.autoFocus === nextProps.autoFocus && prevProps.isAnimating === nextProps.isAnimating && prevProps.today === nextProps.today && prevProps.disabled === nextProps.disabled && prevProps.selected === nextProps.selected && prevProps.disableMargin === nextProps.disableMargin && prevProps.showDaysOutsideCurrentMonth === nextProps.showDaysOutsideCurrentMonth && prevProps.disableHighlightToday === nextProps.disableHighlightToday && prevProps.className === nextProps.className && prevProps.outsideCurrentMonth === nextProps.outsideCurrentMonth && prevProps.onDayFocus === nextProps.onDayFocus && prevProps.onDaySelect === nextProps.onDaySelect;
};
const PickersDay2 = /* @__PURE__ */ react.memo(PickersDayRaw, areDayPropsEqual);
const _excluded$9 = ["children", "className", "reduceAnimations", "slideDirection", "transKey"];
const classes$4 = generateUtilityClasses("PrivatePickersSlideTransition", ["root", "slideEnter-left", "slideEnter-right", "slideEnterActive", "slideEnterActive", "slideExit", "slideExitActiveLeft-left", "slideExitActiveLeft-right"]);
const slideAnimationDuration = 350;
const PickersSlideTransitionRoot = styled(TransitionGroup)(({
  theme
}) => {
  const slideTransition = theme.transitions.create("transform", {
    duration: slideAnimationDuration,
    easing: "cubic-bezier(0.35, 0.8, 0.4, 1)"
  });
  return {
    display: "block",
    position: "relative",
    overflowX: "hidden",
    "& > *": {
      position: "absolute",
      top: 0,
      right: 0,
      left: 0
    },
    [`& .${classes$4["slideEnter-left"]}`]: {
      willChange: "transform",
      transform: "translate(100%)",
      zIndex: 1
    },
    [`& .${classes$4["slideEnter-right"]}`]: {
      willChange: "transform",
      transform: "translate(-100%)",
      zIndex: 1
    },
    [`& .${classes$4.slideEnterActive}`]: {
      transform: "translate(0%)",
      transition: slideTransition
    },
    [`& .${classes$4.slideExit}`]: {
      transform: "translate(0%)"
    },
    [`& .${classes$4["slideExitActiveLeft-left"]}`]: {
      willChange: "transform",
      transform: "translate(-100%)",
      transition: slideTransition,
      zIndex: 0
    },
    [`& .${classes$4["slideExitActiveLeft-right"]}`]: {
      willChange: "transform",
      transform: "translate(100%)",
      transition: slideTransition,
      zIndex: 0
    }
  };
});
const PickersSlideTransition = (_ref) => {
  let {
    children,
    className,
    reduceAnimations,
    slideDirection,
    transKey
  } = _ref, other = _objectWithoutPropertiesLoose(_ref, _excluded$9);
  if (reduceAnimations) {
    return /* @__PURE__ */ jsxRuntime.jsx("div", {
      className: clsx(classes$4.root, className),
      children
    });
  }
  const transitionClasses = {
    exit: classes$4.slideExit,
    enterActive: classes$4.slideEnterActive,
    enter: classes$4[`slideEnter-${slideDirection}`],
    exitActive: classes$4[`slideExitActiveLeft-${slideDirection}`]
  };
  return /* @__PURE__ */ jsxRuntime.jsx(PickersSlideTransitionRoot, {
    className: clsx(classes$4.root, className),
    childFactory: (element) => /* @__PURE__ */ react.cloneElement(element, {
      classNames: transitionClasses
    }),
    children: /* @__PURE__ */ jsxRuntime.jsx(CSSTransition, _extends({
      mountOnEnter: true,
      unmountOnExit: true,
      timeout: slideAnimationDuration,
      classNames: transitionClasses
    }, other, {
      children
    }), transKey)
  });
};
const weeksContainerHeight = (DAY_SIZE + DAY_MARGIN * 4) * 6;
const PickersCalendarDayHeader = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center"
});
const PickersCalendarWeekDayLabel = styled(Typography)(({
  theme
}) => ({
  width: 36,
  height: 40,
  margin: "0 2px",
  textAlign: "center",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: theme.palette.text.secondary
}));
const PickersCalendarLoadingContainer = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  minHeight: weeksContainerHeight
});
const PickersCalendarSlideTransition = styled(PickersSlideTransition)({
  minHeight: weeksContainerHeight
});
const PickersCalendarWeekContainer = styled("div")({
  overflow: "hidden"
});
const PickersCalendarWeek = styled("div")({
  margin: `${DAY_MARGIN}px 0`,
  display: "flex",
  justifyContent: "center"
});
function DayPicker(props) {
  const {
    autoFocus,
    onFocusedDayChange,
    className,
    currentMonth,
    selectedDays,
    disabled,
    disableHighlightToday,
    focusedDay,
    isDateDisabled,
    isMonthSwitchingAnimating,
    loading,
    onSelectedDaysChange,
    onMonthSwitchingAnimationEnd,
    readOnly,
    reduceAnimations,
    renderDay,
    renderLoading = () => /* @__PURE__ */ jsxRuntime.jsx("span", {
      children: "..."
    }),
    showDaysOutsideCurrentMonth,
    slideDirection,
    TransitionProps
  } = props;
  const now = useNow();
  const utils = useUtils();
  const handleDaySelect = react.useCallback((day, isFinish = "finish") => {
    if (readOnly) {
      return;
    }
    onSelectedDaysChange(day, isFinish);
  }, [onSelectedDaysChange, readOnly]);
  const currentMonthNumber = utils.getMonth(currentMonth);
  const validSelectedDays = selectedDays.filter((day) => !!day).map((day) => utils.startOfDay(day));
  const transitionKey = currentMonthNumber;
  const slideNodeRef = react.useMemo(() => /* @__PURE__ */ react.createRef(), [transitionKey]);
  return /* @__PURE__ */ jsxRuntime.jsxs(react.Fragment, {
    children: [/* @__PURE__ */ jsxRuntime.jsx(PickersCalendarDayHeader, {
      children: utils.getWeekdays().map((day, i) => /* @__PURE__ */ jsxRuntime.jsx(PickersCalendarWeekDayLabel, {
        "aria-hidden": true,
        variant: "caption",
        children: day.charAt(0).toUpperCase()
      }, day + i.toString()))
    }), loading ? /* @__PURE__ */ jsxRuntime.jsx(PickersCalendarLoadingContainer, {
      children: renderLoading()
    }) : /* @__PURE__ */ jsxRuntime.jsx(PickersCalendarSlideTransition, _extends({
      transKey: transitionKey,
      onExited: onMonthSwitchingAnimationEnd,
      reduceAnimations,
      slideDirection,
      className
    }, TransitionProps, {
      nodeRef: slideNodeRef,
      children: /* @__PURE__ */ jsxRuntime.jsx(PickersCalendarWeekContainer, {
        ref: slideNodeRef,
        role: "grid",
        children: utils.getWeekArray(currentMonth).map((week) => /* @__PURE__ */ jsxRuntime.jsx(PickersCalendarWeek, {
          role: "row",
          children: week.map((day) => {
            const pickersDayProps = {
              key: day == null ? void 0 : day.toString(),
              day,
              isAnimating: isMonthSwitchingAnimating,
              disabled: disabled || isDateDisabled(day),
              autoFocus: autoFocus && focusedDay !== null && utils.isSameDay(day, focusedDay),
              today: utils.isSameDay(day, now),
              outsideCurrentMonth: utils.getMonth(day) !== currentMonthNumber,
              selected: validSelectedDays.some((selectedDay) => utils.isSameDay(selectedDay, day)),
              disableHighlightToday,
              showDaysOutsideCurrentMonth,
              onDayFocus: onFocusedDayChange,
              onDaySelect: handleDaySelect
            };
            return renderDay ? renderDay(day, validSelectedDays, pickersDayProps) : /* @__PURE__ */ jsxRuntime.jsx("div", {
              role: "cell",
              children: /* @__PURE__ */ jsxRuntime.jsx(PickersDay2, _extends({}, pickersDayProps))
            }, pickersDayProps.key);
          })
        }, `week-${week[0]}`))
      })
    }))]
  });
}
function useViews({
  onChange,
  onViewChange,
  openTo,
  view,
  views
}) {
  var _views, _views2;
  const [openView, setOpenView] = useControlled({
    name: "Picker",
    state: "view",
    controlled: view,
    default: openTo && arrayIncludes(views, openTo) ? openTo : views[0]
  });
  const previousView = (_views = views[views.indexOf(openView) - 1]) != null ? _views : null;
  const nextView = (_views2 = views[views.indexOf(openView) + 1]) != null ? _views2 : null;
  const changeView = react.useCallback((newView) => {
    setOpenView(newView);
    if (onViewChange) {
      onViewChange(newView);
    }
  }, [setOpenView, onViewChange]);
  const openNext = react.useCallback(() => {
    if (nextView) {
      changeView(nextView);
    }
  }, [nextView, changeView]);
  const handleChangeAndOpenNext = react.useCallback((date, currentViewSelectionState) => {
    const isSelectionFinishedOnCurrentView = currentViewSelectionState === "finish";
    const globalSelectionState = isSelectionFinishedOnCurrentView && Boolean(nextView) ? "partial" : currentViewSelectionState;
    onChange(date, globalSelectionState);
    if (isSelectionFinishedOnCurrentView) {
      openNext();
    }
  }, [nextView, onChange, openNext]);
  return {
    handleChangeAndOpenNext,
    nextView,
    previousView,
    openNext,
    openView,
    setOpenView: changeView
  };
}
const ArrowDropDown = createSvgIcon(/* @__PURE__ */ jsxRuntime.jsx("path", {
  d: "M7 10l5 5 5-5z"
}), "ArrowDropDown");
const ArrowLeft = createSvgIcon(/* @__PURE__ */ jsxRuntime.jsx("path", {
  d: "M15.41 16.59L10.83 12l4.58-4.59L14 6l-6 6 6 6 1.41-1.41z"
}), "ArrowLeft");
const ArrowRight = createSvgIcon(/* @__PURE__ */ jsxRuntime.jsx("path", {
  d: "M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z"
}), "ArrowRight");
const Calendar = createSvgIcon(/* @__PURE__ */ jsxRuntime.jsx("path", {
  d: "M17 12h-5v5h5v-5zM16 1v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2h-1V1h-2zm3 18H5V8h14v11z"
}), "Calendar");
const Clock$1 = createSvgIcon(/* @__PURE__ */ jsxRuntime.jsxs(react.Fragment, {
  children: [/* @__PURE__ */ jsxRuntime.jsx("path", {
    d: "M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"
  }), /* @__PURE__ */ jsxRuntime.jsx("path", {
    d: "M12.5 7H11v6l5.25 3.15.75-1.23-4.5-2.67z"
  })]
}), "Clock");
const DateRange = createSvgIcon(/* @__PURE__ */ jsxRuntime.jsx("path", {
  d: "M9 11H7v2h2v-2zm4 0h-2v2h2v-2zm4 0h-2v2h2v-2zm2-7h-1V2h-2v2H8V2H6v2H5c-1.11 0-1.99.9-1.99 2L3 20c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 16H5V9h14v11z"
}), "DateRange");
const Pen = createSvgIcon(/* @__PURE__ */ jsxRuntime.jsx("path", {
  d: "M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34a.9959.9959 0 00-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"
}), "Pen");
const Time = createSvgIcon(/* @__PURE__ */ jsxRuntime.jsxs(react.Fragment, {
  children: [/* @__PURE__ */ jsxRuntime.jsx("path", {
    d: "M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"
  }), /* @__PURE__ */ jsxRuntime.jsx("path", {
    d: "M12.5 7H11v6l5.25 3.15.75-1.23-4.5-2.67z"
  })]
}), "Time");
const _excluded$8 = ["children", "className", "components", "componentsProps", "isLeftDisabled", "isLeftHidden", "isRightDisabled", "isRightHidden", "leftArrowButtonText", "onLeftClick", "onRightClick", "rightArrowButtonText"];
const classes$3 = generateUtilityClasses("MuiPickersArrowSwitcher", ["root", "spacer", "button"]);
const PickersArrowSwitcherRoot = styled("div", {
  name: "MuiPickersArrowSwitcher",
  slot: "Root",
  overridesResolver: (props, styles) => styles.root
})({
  display: "flex"
});
const PickersArrowSwitcherSpacer = styled("div", {
  name: "MuiPickersArrowSwitcher",
  slot: "Spacer",
  overridesResolver: (props, styles) => styles.spacer
})(({
  theme
}) => ({
  width: theme.spacing(3)
}));
const PickersArrowSwitcherButton = styled(IconButton, {
  name: "MuiPickersArrowSwitcher",
  slot: "Button",
  overridesResolver: (props, styles) => styles.button
})(({
  ownerState
}) => _extends({}, ownerState.hidden && {
  visibility: "hidden"
}));
const PickersArrowSwitcher = /* @__PURE__ */ react.forwardRef(function PickersArrowSwitcher2(props, ref) {
  const {
    children,
    className,
    components = {},
    componentsProps = {},
    isLeftDisabled,
    isLeftHidden,
    isRightDisabled,
    isRightHidden,
    leftArrowButtonText,
    onLeftClick,
    onRightClick,
    rightArrowButtonText
  } = props, other = _objectWithoutPropertiesLoose(props, _excluded$8);
  const theme = useTheme();
  const isRtl = theme.direction === "rtl";
  const leftArrowButtonProps = componentsProps.leftArrowButton || {};
  const LeftArrowIcon = components.LeftArrowIcon || ArrowLeft;
  const rightArrowButtonProps = componentsProps.rightArrowButton || {};
  const RightArrowIcon = components.RightArrowIcon || ArrowRight;
  const ownerState = props;
  return /* @__PURE__ */ jsxRuntime.jsxs(PickersArrowSwitcherRoot, _extends({
    ref,
    className: clsx(classes$3.root, className),
    ownerState
  }, other, {
    children: [/* @__PURE__ */ jsxRuntime.jsx(PickersArrowSwitcherButton, _extends({
      as: components.LeftArrowButton,
      size: "small",
      "aria-label": leftArrowButtonText,
      title: leftArrowButtonText,
      disabled: isLeftDisabled,
      edge: "end",
      onClick: onLeftClick
    }, leftArrowButtonProps, {
      className: clsx(classes$3.button, leftArrowButtonProps.className),
      ownerState: _extends({}, ownerState, leftArrowButtonProps, {
        hidden: isLeftHidden
      }),
      children: isRtl ? /* @__PURE__ */ jsxRuntime.jsx(RightArrowIcon, {}) : /* @__PURE__ */ jsxRuntime.jsx(LeftArrowIcon, {})
    })), children ? /* @__PURE__ */ jsxRuntime.jsx(Typography, {
      variant: "subtitle1",
      component: "span",
      children
    }) : /* @__PURE__ */ jsxRuntime.jsx(PickersArrowSwitcherSpacer, {
      className: classes$3.spacer,
      ownerState
    }), /* @__PURE__ */ jsxRuntime.jsx(PickersArrowSwitcherButton, _extends({
      as: components.RightArrowButton,
      size: "small",
      "aria-label": rightArrowButtonText,
      title: rightArrowButtonText,
      edge: "start",
      disabled: isRightDisabled,
      onClick: onRightClick
    }, rightArrowButtonProps, {
      className: clsx(classes$3.button, rightArrowButtonProps.className),
      ownerState: _extends({}, ownerState, rightArrowButtonProps, {
        hidden: isRightHidden
      }),
      children: isRtl ? /* @__PURE__ */ jsxRuntime.jsx(LeftArrowIcon, {}) : /* @__PURE__ */ jsxRuntime.jsx(RightArrowIcon, {})
    }))]
  }));
});
const getMeridiem = (date, utils) => {
  if (!date) {
    return null;
  }
  return utils.getHours(date) >= 12 ? "pm" : "am";
};
const convertValueToMeridiem = (value, meridiem, ampm) => {
  if (ampm) {
    const currentMeridiem = value >= 12 ? "pm" : "am";
    if (currentMeridiem !== meridiem) {
      return meridiem === "am" ? value - 12 : value + 12;
    }
  }
  return value;
};
const convertToMeridiem = (time, meridiem, ampm, utils) => {
  const newHoursAmount = convertValueToMeridiem(utils.getHours(time), meridiem, ampm);
  return utils.setHours(time, newHoursAmount);
};
const getSecondsInDay = (date, utils) => {
  return utils.getHours(date) * 3600 + utils.getMinutes(date) * 60 + utils.getSeconds(date);
};
const createIsAfterIgnoreDatePart = (disableIgnoringDatePartForTimeValidation = false, utils) => (dateLeft, dateRight) => {
  if (disableIgnoringDatePartForTimeValidation) {
    return utils.isAfter(dateLeft, dateRight);
  }
  return getSecondsInDay(dateLeft, utils) > getSecondsInDay(dateRight, utils);
};
function useNextMonthDisabled(month, {
  disableFuture,
  maxDate
}) {
  const utils = useUtils();
  return react.useMemo(() => {
    const now = utils.date();
    const lastEnabledMonth = utils.startOfMonth(disableFuture && utils.isBefore(now, maxDate) ? now : maxDate);
    return !utils.isAfter(lastEnabledMonth, month);
  }, [disableFuture, maxDate, month, utils]);
}
function usePreviousMonthDisabled(month, {
  disablePast,
  minDate
}) {
  const utils = useUtils();
  return react.useMemo(() => {
    const now = utils.date();
    const firstEnabledMonth = utils.startOfMonth(disablePast && utils.isAfter(now, minDate) ? now : minDate);
    return !utils.isBefore(firstEnabledMonth, month);
  }, [disablePast, minDate, month, utils]);
}
function useMeridiemMode(date, ampm, onChange) {
  const utils = useUtils();
  const meridiemMode = getMeridiem(date, utils);
  const handleMeridiemChange = react.useCallback((mode) => {
    const timeWithMeridiem = date == null ? null : convertToMeridiem(date, mode, Boolean(ampm), utils);
    onChange(timeWithMeridiem, "partial");
  }, [ampm, date, onChange, utils]);
  return {
    meridiemMode,
    handleMeridiemChange
  };
}
const PickersCalendarHeaderRoot = styled("div")({
  display: "flex",
  alignItems: "center",
  marginTop: 16,
  marginBottom: 8,
  paddingLeft: 24,
  paddingRight: 12,
  maxHeight: 30,
  minHeight: 30
});
const PickersCalendarHeaderLabel = styled("div")(({
  theme
}) => _extends({
  display: "flex",
  maxHeight: 30,
  overflow: "hidden",
  alignItems: "center",
  cursor: "pointer",
  marginRight: "auto"
}, theme.typography.body1, {
  fontWeight: theme.typography.fontWeightMedium
}));
const PickersCalendarHeaderLabelItem = styled("div")({
  marginRight: 6
});
const PickersCalendarHeaderSwitchViewButton = styled(IconButton)({
  marginRight: "auto"
});
const PickersCalendarHeaderSwitchView = styled(ArrowDropDown)(({
  theme,
  ownerState
}) => _extends({
  willChange: "transform",
  transition: theme.transitions.create("transform"),
  transform: "rotate(0deg)"
}, ownerState.openView === "year" && {
  transform: "rotate(180deg)"
}));
function getSwitchingViewAriaText(view) {
  return view === "year" ? "year view is open, switch to calendar view" : "calendar view is open, switch to year view";
}
function PickersCalendarHeader(props) {
  const {
    components = {},
    componentsProps = {},
    currentMonth: month,
    disabled,
    disableFuture,
    disablePast,
    getViewSwitchingButtonText = getSwitchingViewAriaText,
    leftArrowButtonText = "Previous month",
    maxDate,
    minDate,
    onMonthChange,
    onViewChange,
    openView: currentView,
    reduceAnimations,
    rightArrowButtonText = "Next month",
    views
  } = props;
  const utils = useUtils();
  const switchViewButtonProps = componentsProps.switchViewButton || {};
  const selectNextMonth = () => onMonthChange(utils.getNextMonth(month), "left");
  const selectPreviousMonth = () => onMonthChange(utils.getPreviousMonth(month), "right");
  const isNextMonthDisabled = useNextMonthDisabled(month, {
    disableFuture,
    maxDate
  });
  const isPreviousMonthDisabled = usePreviousMonthDisabled(month, {
    disablePast,
    minDate
  });
  const handleToggleView = () => {
    if (views.length === 1 || !onViewChange || disabled) {
      return;
    }
    if (views.length === 2) {
      onViewChange(views.find((view) => view !== currentView) || views[0]);
    } else {
      const nextIndexToOpen = views.indexOf(currentView) !== 0 ? 0 : 1;
      onViewChange(views[nextIndexToOpen]);
    }
  };
  if (views.length === 1 && views[0] === "year") {
    return null;
  }
  const ownerState = props;
  return /* @__PURE__ */ jsxRuntime.jsxs(PickersCalendarHeaderRoot, {
    ownerState,
    children: [/* @__PURE__ */ jsxRuntime.jsxs(PickersCalendarHeaderLabel, {
      role: "presentation",
      onClick: handleToggleView,
      ownerState,
      children: [/* @__PURE__ */ jsxRuntime.jsx(PickersFadeTransitionGroup, {
        reduceAnimations,
        transKey: utils.format(month, "monthAndYear"),
        children: /* @__PURE__ */ jsxRuntime.jsx(PickersCalendarHeaderLabelItem, {
          "aria-live": "polite",
          ownerState,
          children: utils.format(month, "monthAndYear")
        })
      }), views.length > 1 && !disabled && /* @__PURE__ */ jsxRuntime.jsx(PickersCalendarHeaderSwitchViewButton, _extends({
        size: "small",
        as: components.SwitchViewButton,
        "aria-label": getViewSwitchingButtonText(currentView)
      }, switchViewButtonProps, {
        children: /* @__PURE__ */ jsxRuntime.jsx(PickersCalendarHeaderSwitchView, {
          as: components.SwitchViewIcon,
          ownerState
        })
      }))]
    }), /* @__PURE__ */ jsxRuntime.jsx(Fade, {
      in: currentView === "day",
      children: /* @__PURE__ */ jsxRuntime.jsx(PickersArrowSwitcher, {
        leftArrowButtonText,
        rightArrowButtonText,
        components,
        componentsProps,
        onLeftClick: selectPreviousMonth,
        onRightClick: selectNextMonth,
        isLeftDisabled: isPreviousMonthDisabled,
        isRightDisabled: isNextMonthDisabled
      })
    })]
  });
}
const WrapperVariantContext = /* @__PURE__ */ react.createContext(null);
function getPickersYearUtilityClass(slot) {
  return generateUtilityClass("PrivatePickersYear", slot);
}
const pickersYearClasses = generateUtilityClasses("PrivatePickersYear", ["root", "modeMobile", "modeDesktop", "yearButton", "disabled", "selected"]);
const useUtilityClasses$3 = (ownerState) => {
  const {
    wrapperVariant,
    disabled,
    selected,
    classes: classes2
  } = ownerState;
  const slots = {
    root: ["root", wrapperVariant && `mode${capitalize(wrapperVariant)}`],
    yearButton: ["yearButton", disabled && "disabled", selected && "selected"]
  };
  return composeClasses(slots, getPickersYearUtilityClass, classes2);
};
const PickersYearRoot = styled("div")(({
  ownerState
}) => _extends({
  flexBasis: "33.3%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center"
}, (ownerState == null ? void 0 : ownerState.wrapperVariant) === "desktop" && {
  flexBasis: "25%"
}));
const PickersYearButton = styled("button")(({
  theme
}) => _extends({
  color: "unset",
  backgroundColor: "transparent",
  border: 0,
  outline: 0
}, theme.typography.subtitle1, {
  margin: "8px 0",
  height: 36,
  width: 72,
  borderRadius: 18,
  cursor: "pointer",
  "&:focus, &:hover": {
    backgroundColor: alpha(theme.palette.action.active, theme.palette.action.hoverOpacity)
  },
  [`&.${pickersYearClasses.disabled}`]: {
    color: theme.palette.text.secondary
  },
  [`&.${pickersYearClasses.selected}`]: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    "&:focus, &:hover": {
      backgroundColor: theme.palette.primary.dark
    }
  }
}));
const PickersYear = /* @__PURE__ */ react.forwardRef(function PickersYear2(props, forwardedRef) {
  const {
    autoFocus,
    className,
    children,
    disabled,
    onClick,
    onKeyDown,
    selected,
    value
  } = props;
  const ref = react.useRef(null);
  const refHandle = useForkRef(ref, forwardedRef);
  const wrapperVariant = react.useContext(WrapperVariantContext);
  const ownerState = _extends({}, props, {
    wrapperVariant
  });
  const classes2 = useUtilityClasses$3(ownerState);
  react.useEffect(() => {
    if (autoFocus) {
      ref.current.focus();
    }
  }, [autoFocus]);
  return /* @__PURE__ */ jsxRuntime.jsx(PickersYearRoot, {
    className: clsx(classes2.root, className),
    ownerState,
    children: /* @__PURE__ */ jsxRuntime.jsx(PickersYearButton, {
      ref: refHandle,
      disabled,
      type: "button",
      tabIndex: selected ? 0 : -1,
      onClick: (event) => onClick(event, value),
      onKeyDown: (event) => onKeyDown(event, value),
      className: classes2.yearButton,
      ownerState,
      children
    })
  });
});
const findClosestEnabledDate = ({
  date,
  disableFuture,
  disablePast,
  maxDate,
  minDate,
  shouldDisableDate,
  utils
}) => {
  const today = utils.startOfDay(utils.date());
  if (disablePast && utils.isBefore(minDate, today)) {
    minDate = today;
  }
  if (disableFuture && utils.isAfter(maxDate, today)) {
    maxDate = today;
  }
  let forward = date;
  let backward = date;
  if (utils.isBefore(date, minDate)) {
    forward = utils.date(minDate);
    backward = null;
  }
  if (utils.isAfter(date, maxDate)) {
    if (backward) {
      backward = utils.date(maxDate);
    }
    forward = null;
  }
  while (forward || backward) {
    if (forward && utils.isAfter(forward, maxDate)) {
      forward = null;
    }
    if (backward && utils.isBefore(backward, minDate)) {
      backward = null;
    }
    if (forward) {
      if (!shouldDisableDate(forward)) {
        return forward;
      }
      forward = utils.addDays(forward, 1);
    }
    if (backward) {
      if (!shouldDisableDate(backward)) {
        return backward;
      }
      backward = utils.addDays(backward, -1);
    }
  }
  return today;
};
const parsePickerInputValue = (utils, value) => {
  const parsedValue = utils.date(value);
  return utils.isValid(parsedValue) ? parsedValue : null;
};
function getYearPickerUtilityClass(slot) {
  return generateUtilityClass("MuiYearPicker", slot);
}
generateUtilityClasses("MuiYearPicker", ["root"]);
const useUtilityClasses$2 = (ownerState) => {
  const {
    classes: classes2
  } = ownerState;
  const slots = {
    root: ["root"]
  };
  return composeClasses(slots, getYearPickerUtilityClass, classes2);
};
const YearPickerRoot = styled("div", {
  name: "MuiYearPicker",
  slot: "Root",
  overridesResolver: (props, styles) => styles.root
})({
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  overflowY: "auto",
  height: "100%",
  margin: "0 4px"
});
const YearPicker = /* @__PURE__ */ react.forwardRef(function YearPicker2(inProps, ref) {
  const props = useThemeProps({
    props: inProps,
    name: "MuiYearPicker"
  });
  const {
    autoFocus,
    className,
    date,
    disabled,
    disableFuture,
    disablePast,
    isDateDisabled,
    maxDate,
    minDate,
    onChange,
    onFocusedDayChange,
    onYearChange,
    readOnly,
    shouldDisableYear
  } = props;
  const ownerState = props;
  const classes2 = useUtilityClasses$2(ownerState);
  const now = useNow();
  const theme = useTheme();
  const utils = useUtils();
  const selectedDate = date || now;
  const currentYear = utils.getYear(selectedDate);
  const wrapperVariant = react.useContext(WrapperVariantContext);
  const selectedYearRef = react.useRef(null);
  const [focusedYear, setFocusedYear] = react.useState(currentYear);
  const handleYearSelection = (event, year, isFinish = "finish") => {
    if (readOnly) {
      return;
    }
    const submitDate = (newDate2) => {
      onChange(newDate2, isFinish);
      if (onFocusedDayChange) {
        onFocusedDayChange(newDate2 || now);
      }
      if (onYearChange) {
        onYearChange(newDate2);
      }
    };
    const newDate = utils.setYear(selectedDate, year);
    if (isDateDisabled(newDate)) {
      const closestEnabledDate = findClosestEnabledDate({
        utils,
        date: newDate,
        minDate,
        maxDate,
        disablePast: Boolean(disablePast),
        disableFuture: Boolean(disableFuture),
        shouldDisableDate: isDateDisabled
      });
      submitDate(closestEnabledDate || now);
    } else {
      submitDate(newDate);
    }
  };
  const focusYear = react.useCallback((year) => {
    if (!isDateDisabled(utils.setYear(selectedDate, year))) {
      setFocusedYear(year);
    }
  }, [selectedDate, isDateDisabled, utils]);
  const yearsInRow = wrapperVariant === "desktop" ? 4 : 3;
  const handleKeyDown = (event, year) => {
    switch (event.key) {
      case "ArrowUp":
        focusYear(year - yearsInRow);
        event.preventDefault();
        break;
      case "ArrowDown":
        focusYear(year + yearsInRow);
        event.preventDefault();
        break;
      case "ArrowLeft":
        focusYear(year + (theme.direction === "ltr" ? -1 : 1));
        event.preventDefault();
        break;
      case "ArrowRight":
        focusYear(year + (theme.direction === "ltr" ? 1 : -1));
        event.preventDefault();
        break;
    }
  };
  return /* @__PURE__ */ jsxRuntime.jsx(YearPickerRoot, {
    ref,
    className: clsx(classes2.root, className),
    ownerState,
    children: utils.getYearRange(minDate, maxDate).map((year) => {
      const yearNumber = utils.getYear(year);
      const selected = yearNumber === currentYear;
      return /* @__PURE__ */ jsxRuntime.jsx(PickersYear, {
        selected,
        value: yearNumber,
        onClick: handleYearSelection,
        onKeyDown: handleKeyDown,
        autoFocus: autoFocus && yearNumber === focusedYear,
        ref: selected ? selectedYearRef : void 0,
        disabled: disabled || disablePast && utils.isBeforeYear(year, now) || disableFuture && utils.isAfterYear(year, now) || shouldDisableYear && shouldDisableYear(year),
        children: utils.format(year, "year")
      }, utils.format(year, "year"));
    })
  });
});
const PickerViewRoot = styled("div")({
  overflowX: "hidden",
  width: DIALOG_WIDTH,
  maxHeight: VIEW_HEIGHT,
  display: "flex",
  flexDirection: "column",
  margin: "0 auto"
});
const defaultReduceAnimations = typeof navigator !== "undefined" && /(android)/i.test(navigator.userAgent);
const getCalendarPickerUtilityClass = (slot) => generateUtilityClass("MuiCalendarPicker", slot);
generateUtilityClasses("MuiCalendarPicker", ["root", "viewTransitionContainer"]);
const _excluded$7 = ["autoFocus", "onViewChange", "date", "disableFuture", "disablePast", "defaultCalendarMonth", "loading", "maxDate", "minDate", "onChange", "onMonthChange", "reduceAnimations", "renderLoading", "shouldDisableDate", "shouldDisableMonth", "shouldDisableYear", "view", "views", "openTo", "className", "disabled", "readOnly"];
const useUtilityClasses$1 = (ownerState) => {
  const {
    classes: classes2
  } = ownerState;
  const slots = {
    root: ["root"],
    viewTransitionContainer: ["viewTransitionContainer"]
  };
  return composeClasses(slots, getCalendarPickerUtilityClass, classes2);
};
const CalendarPickerRoot = styled(PickerViewRoot, {
  name: "MuiCalendarPicker",
  slot: "Root",
  overridesResolver: (props, styles) => styles.root
})({
  display: "flex",
  flexDirection: "column"
});
const CalendarPickerViewTransitionContainer = styled(PickersFadeTransitionGroup, {
  name: "MuiCalendarPicker",
  slot: "ViewTransitionContainer",
  overridesResolver: (props, styles) => styles.viewTransitionContainer
})({
  overflowY: "auto"
});
const CalendarPicker = /* @__PURE__ */ react.forwardRef(function CalendarPicker2(inProps, ref) {
  const props = useThemeProps({
    props: inProps,
    name: "MuiCalendarPicker"
  });
  const {
    autoFocus,
    onViewChange,
    date,
    disableFuture = false,
    disablePast = false,
    defaultCalendarMonth,
    loading = false,
    maxDate: maxDateProp,
    minDate: minDateProp,
    onChange,
    onMonthChange,
    reduceAnimations = defaultReduceAnimations,
    renderLoading = () => /* @__PURE__ */ jsxRuntime.jsx("span", {
      children: "..."
    }),
    shouldDisableDate,
    shouldDisableMonth,
    shouldDisableYear,
    view,
    views = ["year", "day"],
    openTo = "day",
    className,
    disabled,
    readOnly
  } = props, other = _objectWithoutPropertiesLoose(props, _excluded$7);
  const utils = useUtils();
  const defaultDates = useDefaultDates();
  const minDate = minDateProp != null ? minDateProp : defaultDates.minDate;
  const maxDate = maxDateProp != null ? maxDateProp : defaultDates.maxDate;
  const {
    openView,
    setOpenView
  } = useViews({
    view,
    views,
    openTo,
    onChange,
    onViewChange
  });
  const {
    calendarState,
    changeFocusedDay,
    changeMonth,
    isDateDisabled,
    handleChangeMonth,
    onMonthSwitchingAnimationEnd
  } = useCalendarState({
    date,
    defaultCalendarMonth,
    reduceAnimations,
    onMonthChange,
    minDate,
    maxDate,
    shouldDisableDate,
    disablePast,
    disableFuture
  });
  const onSelectedDayChange = react.useCallback((day, isFinish) => {
    if (date && day) {
      return onChange(utils.mergeDateAndTime(day, date), isFinish);
    }
    return onChange(day, isFinish);
  }, [utils, date, onChange]);
  react.useEffect(() => {
    if (date && isDateDisabled(date)) {
      const closestEnabledDate = findClosestEnabledDate({
        utils,
        date,
        minDate,
        maxDate,
        disablePast,
        disableFuture,
        shouldDisableDate: isDateDisabled
      });
      onChange(closestEnabledDate, "partial");
    }
  }, []);
  react.useEffect(() => {
    if (date) {
      changeMonth(date);
    }
  }, [date]);
  const ownerState = props;
  const classes2 = useUtilityClasses$1(ownerState);
  const monthPickerProps = {
    className,
    date,
    disabled,
    disablePast,
    disableFuture,
    onChange,
    minDate,
    maxDate,
    shouldDisableMonth,
    onMonthChange,
    readOnly
  };
  const minDateWithDisabled = disabled && date || minDate;
  const maxDateWithDisabled = disabled && date || maxDate;
  return /* @__PURE__ */ jsxRuntime.jsxs(CalendarPickerRoot, {
    ref,
    className: clsx(classes2.root, className),
    ownerState,
    children: [/* @__PURE__ */ jsxRuntime.jsx(PickersCalendarHeader, _extends({}, other, {
      views,
      openView,
      currentMonth: calendarState.currentMonth,
      onViewChange: setOpenView,
      onMonthChange: (newMonth, direction) => handleChangeMonth({
        newMonth,
        direction
      }),
      minDate: minDateWithDisabled,
      maxDate: maxDateWithDisabled,
      disabled,
      disablePast,
      disableFuture,
      reduceAnimations
    })), /* @__PURE__ */ jsxRuntime.jsx(CalendarPickerViewTransitionContainer, {
      reduceAnimations,
      className: classes2.viewTransitionContainer,
      transKey: openView,
      ownerState,
      children: /* @__PURE__ */ jsxRuntime.jsxs("div", {
        children: [openView === "year" && /* @__PURE__ */ jsxRuntime.jsx(YearPicker, _extends({}, other, {
          autoFocus,
          date,
          onChange,
          minDate,
          maxDate,
          disableFuture,
          disablePast,
          isDateDisabled,
          shouldDisableYear,
          onFocusedDayChange: changeFocusedDay,
          disabled,
          readOnly
        })), openView === "month" && /* @__PURE__ */ jsxRuntime.jsx(MonthPicker, _extends({}, monthPickerProps)), openView === "day" && /* @__PURE__ */ jsxRuntime.jsx(DayPicker, _extends({}, other, calendarState, {
          autoFocus,
          onMonthSwitchingAnimationEnd,
          onFocusedDayChange: changeFocusedDay,
          reduceAnimations,
          selectedDays: [date],
          onSelectedDaysChange: onSelectedDayChange,
          isDateDisabled,
          loading,
          renderLoading,
          disabled,
          readOnly
        }))]
      })
    })]
  });
});
const CLOCK_WIDTH = 220;
const CLOCK_HOUR_WIDTH = 36;
const clockCenter = {
  x: CLOCK_WIDTH / 2,
  y: CLOCK_WIDTH / 2
};
const baseClockPoint = {
  x: clockCenter.x,
  y: 0
};
const cx = baseClockPoint.x - clockCenter.x;
const cy = baseClockPoint.y - clockCenter.y;
const rad2deg = (rad) => rad * (180 / Math.PI);
const getAngleValue = (step, offsetX, offsetY) => {
  const x = offsetX - clockCenter.x;
  const y = offsetY - clockCenter.y;
  const atan = Math.atan2(cx, cy) - Math.atan2(x, y);
  let deg = rad2deg(atan);
  deg = Math.round(deg / step) * step;
  deg %= 360;
  const value = Math.floor(deg / step) || 0;
  const delta = x ** 2 + y ** 2;
  const distance = Math.sqrt(delta);
  return {
    value,
    distance
  };
};
const getMinutes = (offsetX, offsetY, step = 1) => {
  const angleStep = step * 6;
  let {
    value
  } = getAngleValue(angleStep, offsetX, offsetY);
  value = value * step % 60;
  return value;
};
const getHours = (offsetX, offsetY, ampm) => {
  const {
    value,
    distance
  } = getAngleValue(30, offsetX, offsetY);
  let hour = value || 12;
  if (!ampm) {
    if (distance < CLOCK_WIDTH / 2 - CLOCK_HOUR_WIDTH) {
      hour += 12;
      hour %= 24;
    }
  } else {
    hour %= 12;
  }
  return hour;
};
const _excluded$6 = ["className", "hasSelected", "isInner", "type", "value"];
const ClockPointerRoot = styled("div")(({
  theme,
  ownerState
}) => _extends({
  width: 2,
  backgroundColor: theme.palette.primary.main,
  position: "absolute",
  left: "calc(50% - 1px)",
  bottom: "50%",
  transformOrigin: "center bottom 0px"
}, ownerState.toAnimateTransform && {
  transition: theme.transitions.create(["transform", "height"])
}));
const ClockPointerThumb = styled("div")(({
  theme,
  ownerState
}) => _extends({
  width: 4,
  height: 4,
  backgroundColor: theme.palette.primary.contrastText,
  borderRadius: "50%",
  position: "absolute",
  top: -21,
  left: `calc(50% - ${CLOCK_HOUR_WIDTH / 2}px)`,
  border: `${(CLOCK_HOUR_WIDTH - 4) / 2}px solid ${theme.palette.primary.main}`,
  boxSizing: "content-box"
}, ownerState.hasSelected && {
  backgroundColor: theme.palette.primary.main
}));
class ClockPointer extends react.Component {
  constructor(...args) {
    super(...args);
    this.state = {
      toAnimateTransform: false,
      previousType: void 0
    };
  }
  render() {
    const _this$props = this.props, {
      className,
      isInner,
      type,
      value
    } = _this$props, other = _objectWithoutPropertiesLoose(_this$props, _excluded$6);
    const ownerState = _extends({}, this.props, this.state);
    const getAngleStyle = () => {
      const max = type === "hours" ? 12 : 60;
      let angle = 360 / max * value;
      if (type === "hours" && value > 12) {
        angle -= 360;
      }
      return {
        height: Math.round((isInner ? 0.26 : 0.4) * CLOCK_WIDTH),
        transform: `rotateZ(${angle}deg)`
      };
    };
    return /* @__PURE__ */ jsxRuntime.jsx(ClockPointerRoot, _extends({
      style: getAngleStyle(),
      className,
      ownerState
    }, other, {
      children: /* @__PURE__ */ jsxRuntime.jsx(ClockPointerThumb, {
        ownerState
      })
    }));
  }
}
ClockPointer.getDerivedStateFromProps = (nextProps, state) => {
  if (nextProps.type !== state.previousType) {
    return {
      toAnimateTransform: true,
      previousType: nextProps.type
    };
  }
  return {
    toAnimateTransform: false,
    previousType: nextProps.type
  };
};
const ClockRoot = styled("div")(({
  theme
}) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  margin: theme.spacing(2)
}));
const ClockClock = styled("div")({
  backgroundColor: "rgba(0,0,0,.07)",
  borderRadius: "50%",
  height: 220,
  width: 220,
  flexShrink: 0,
  position: "relative",
  pointerEvents: "none"
});
const ClockSquareMask = styled("div")(({
  ownerState
}) => _extends({
  width: "100%",
  height: "100%",
  position: "absolute",
  pointerEvents: "auto",
  outline: 0,
  touchAction: "none",
  userSelect: "none"
}, ownerState.disabled ? {} : {
  "@media (pointer: fine)": {
    cursor: "pointer",
    borderRadius: "50%"
  },
  "&:active": {
    cursor: "move"
  }
}));
const ClockPin = styled("div")(({
  theme
}) => ({
  width: 6,
  height: 6,
  borderRadius: "50%",
  backgroundColor: theme.palette.primary.main,
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)"
}));
const ClockAmButton = styled(IconButton)(({
  theme,
  ownerState
}) => _extends({
  zIndex: 1,
  position: "absolute",
  bottom: ownerState.ampmInClock ? 64 : 8,
  left: 8
}, ownerState.meridiemMode === "am" && {
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  "&:hover": {
    backgroundColor: theme.palette.primary.light
  }
}));
const ClockPmButton = styled(IconButton)(({
  theme,
  ownerState
}) => _extends({
  zIndex: 1,
  position: "absolute",
  bottom: ownerState.ampmInClock ? 64 : 8,
  right: 8
}, ownerState.meridiemMode === "pm" && {
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  "&:hover": {
    backgroundColor: theme.palette.primary.light
  }
}));
function Clock(props) {
  const {
    ampm,
    ampmInClock,
    autoFocus,
    children,
    date,
    getClockLabelText,
    handleMeridiemChange,
    isTimeDisabled,
    meridiemMode,
    minutesStep = 1,
    onChange,
    selectedId,
    type,
    value,
    disabled,
    readOnly
  } = props;
  const ownerState = props;
  const utils = useUtils();
  const wrapperVariant = react.useContext(WrapperVariantContext);
  const isMoving = react.useRef(false);
  const isSelectedTimeDisabled = isTimeDisabled(value, type);
  const isPointerInner = !ampm && type === "hours" && (value < 1 || value > 12);
  const handleValueChange = (newValue, isFinish) => {
    if (disabled || readOnly) {
      return;
    }
    if (isTimeDisabled(newValue, type)) {
      return;
    }
    onChange(newValue, isFinish);
  };
  const setTime = (event, isFinish) => {
    let {
      offsetX,
      offsetY
    } = event;
    if (offsetX === void 0) {
      const rect = event.target.getBoundingClientRect();
      offsetX = event.changedTouches[0].clientX - rect.left;
      offsetY = event.changedTouches[0].clientY - rect.top;
    }
    const newSelectedValue = type === "seconds" || type === "minutes" ? getMinutes(offsetX, offsetY, minutesStep) : getHours(offsetX, offsetY, Boolean(ampm));
    handleValueChange(newSelectedValue, isFinish);
  };
  const handleTouchMove = (event) => {
    isMoving.current = true;
    setTime(event, "shallow");
  };
  const handleTouchEnd = (event) => {
    if (isMoving.current) {
      setTime(event, "finish");
      isMoving.current = false;
    }
  };
  const handleMouseMove = (event) => {
    if (event.buttons > 0) {
      setTime(event.nativeEvent, "shallow");
    }
  };
  const handleMouseUp = (event) => {
    if (isMoving.current) {
      isMoving.current = false;
    }
    setTime(event.nativeEvent, "finish");
  };
  const hasSelected = react.useMemo(() => {
    if (type === "hours") {
      return true;
    }
    return value % 5 === 0;
  }, [type, value]);
  const keyboardControlStep = type === "minutes" ? minutesStep : 1;
  const listboxRef = react.useRef(null);
  useEnhancedEffect(() => {
    if (autoFocus) {
      listboxRef.current.focus();
    }
  }, [autoFocus]);
  const handleKeyDown = (event) => {
    if (isMoving.current) {
      return;
    }
    switch (event.key) {
      case "Home":
        handleValueChange(0, "partial");
        event.preventDefault();
        break;
      case "End":
        handleValueChange(type === "minutes" ? 59 : 23, "partial");
        event.preventDefault();
        break;
      case "ArrowUp":
        handleValueChange(value + keyboardControlStep, "partial");
        event.preventDefault();
        break;
      case "ArrowDown":
        handleValueChange(value - keyboardControlStep, "partial");
        event.preventDefault();
        break;
    }
  };
  return /* @__PURE__ */ jsxRuntime.jsxs(ClockRoot, {
    children: [/* @__PURE__ */ jsxRuntime.jsxs(ClockClock, {
      children: [/* @__PURE__ */ jsxRuntime.jsx(ClockSquareMask, {
        onTouchMove: handleTouchMove,
        onTouchEnd: handleTouchEnd,
        onMouseUp: handleMouseUp,
        onMouseMove: handleMouseMove,
        ownerState: {
          disabled
        }
      }), !isSelectedTimeDisabled && /* @__PURE__ */ jsxRuntime.jsxs(react.Fragment, {
        children: [/* @__PURE__ */ jsxRuntime.jsx(ClockPin, {}), date && /* @__PURE__ */ jsxRuntime.jsx(ClockPointer, {
          type,
          value,
          isInner: isPointerInner,
          hasSelected
        })]
      }), /* @__PURE__ */ jsxRuntime.jsx("div", {
        "aria-activedescendant": selectedId,
        "aria-label": getClockLabelText(type, date, utils),
        ref: listboxRef,
        role: "listbox",
        onKeyDown: handleKeyDown,
        tabIndex: 0,
        children
      })]
    }), ampm && (wrapperVariant === "desktop" || ampmInClock) && /* @__PURE__ */ jsxRuntime.jsxs(react.Fragment, {
      children: [/* @__PURE__ */ jsxRuntime.jsx(ClockAmButton, {
        onClick: readOnly ? void 0 : () => handleMeridiemChange("am"),
        disabled: disabled || meridiemMode === null,
        ownerState,
        children: /* @__PURE__ */ jsxRuntime.jsx(Typography, {
          variant: "caption",
          children: "AM"
        })
      }), /* @__PURE__ */ jsxRuntime.jsx(ClockPmButton, {
        disabled: disabled || meridiemMode === null,
        onClick: readOnly ? void 0 : () => handleMeridiemChange("pm"),
        ownerState,
        children: /* @__PURE__ */ jsxRuntime.jsx(Typography, {
          variant: "caption",
          children: "PM"
        })
      })]
    })]
  });
}
const _excluded$5 = ["className", "disabled", "index", "inner", "label", "selected"];
const classes$2 = generateUtilityClasses("PrivateClockNumber", ["selected", "disabled"]);
const ClockNumberRoot = styled("span")(({
  theme,
  ownerState
}) => _extends({
  height: CLOCK_HOUR_WIDTH,
  width: CLOCK_HOUR_WIDTH,
  position: "absolute",
  left: `calc((100% - ${CLOCK_HOUR_WIDTH}px) / 2)`,
  display: "inline-flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "50%",
  color: theme.palette.text.primary,
  fontFamily: theme.typography.fontFamily,
  "&:focused": {
    backgroundColor: theme.palette.background.paper
  },
  [`&.${classes$2.selected}`]: {
    color: theme.palette.primary.contrastText
  },
  [`&.${classes$2.disabled}`]: {
    pointerEvents: "none",
    color: theme.palette.text.disabled
  }
}, ownerState.inner && _extends({}, theme.typography.body2, {
  color: theme.palette.text.secondary
})));
function ClockNumber(props) {
  const {
    className,
    disabled,
    index,
    inner,
    label,
    selected
  } = props, other = _objectWithoutPropertiesLoose(props, _excluded$5);
  const ownerState = props;
  const angle = index % 12 / 12 * Math.PI * 2 - Math.PI / 2;
  const length = (CLOCK_WIDTH - CLOCK_HOUR_WIDTH - 2) / 2 * (inner ? 0.65 : 1);
  const x = Math.round(Math.cos(angle) * length);
  const y = Math.round(Math.sin(angle) * length);
  return /* @__PURE__ */ jsxRuntime.jsx(ClockNumberRoot, _extends({
    className: clsx(className, selected && classes$2.selected, disabled && classes$2.disabled),
    "aria-disabled": disabled ? true : void 0,
    "aria-selected": selected ? true : void 0,
    role: "option",
    style: {
      transform: `translate(${x}px, ${y + (CLOCK_WIDTH - CLOCK_HOUR_WIDTH) / 2}px`
    },
    ownerState
  }, other, {
    children: label
  }));
}
const getHourNumbers = ({
  ampm,
  date,
  getClockNumberText,
  isDisabled,
  selectedId,
  utils
}) => {
  const currentHours = date ? utils.getHours(date) : null;
  const hourNumbers = [];
  const startHour = ampm ? 1 : 0;
  const endHour = ampm ? 12 : 23;
  const isSelected = (hour) => {
    if (currentHours === null) {
      return false;
    }
    if (ampm) {
      if (hour === 12) {
        return currentHours === 12 || currentHours === 0;
      }
      return currentHours === hour || currentHours - 12 === hour;
    }
    return currentHours === hour;
  };
  for (let hour = startHour; hour <= endHour; hour += 1) {
    let label = hour.toString();
    if (hour === 0) {
      label = "00";
    }
    const inner = !ampm && (hour === 0 || hour > 12);
    label = utils.formatNumber(label);
    const selected = isSelected(hour);
    hourNumbers.push(/* @__PURE__ */ jsxRuntime.jsx(ClockNumber, {
      id: selected ? selectedId : void 0,
      index: hour,
      inner,
      selected,
      disabled: isDisabled(hour),
      label,
      "aria-label": getClockNumberText(label)
    }, hour));
  }
  return hourNumbers;
};
const getMinutesNumbers = ({
  utils,
  value,
  isDisabled,
  getClockNumberText,
  selectedId
}) => {
  const f = utils.formatNumber;
  return [[5, f("05")], [10, f("10")], [15, f("15")], [20, f("20")], [25, f("25")], [30, f("30")], [35, f("35")], [40, f("40")], [45, f("45")], [50, f("50")], [55, f("55")], [0, f("00")]].map(([numberValue, label], index) => {
    const selected = numberValue === value;
    return /* @__PURE__ */ jsxRuntime.jsx(ClockNumber, {
      label,
      id: selected ? selectedId : void 0,
      index: index + 1,
      inner: false,
      disabled: isDisabled(numberValue),
      selected,
      "aria-label": getClockNumberText(label)
    }, numberValue);
  });
};
function getClockPickerUtilityClass(slot) {
  return generateUtilityClass("MuiClockPicker", slot);
}
generateUtilityClasses("MuiClockPicker", ["root", "arrowSwitcher"]);
const useUtilityClasses = (ownerState) => {
  const {
    classes: classes2
  } = ownerState;
  const slots = {
    root: ["root"],
    arrowSwitcher: ["arrowSwitcher"]
  };
  return composeClasses(slots, getClockPickerUtilityClass, classes2);
};
const ClockPickerRoot = styled(PickerViewRoot, {
  name: "MuiClockPicker",
  slot: "Root",
  overridesResolver: (props, styles) => styles.root
})({
  display: "flex",
  flexDirection: "column"
});
const ClockPickerArrowSwitcher = styled(PickersArrowSwitcher, {
  name: "MuiClockPicker",
  slot: "ArrowSwitcher",
  overridesResolver: (props, styles) => styles.arrowSwitcher
})({
  position: "absolute",
  right: 12,
  top: 15
});
const defaultGetClockLabelText = (view, time, adapter) => `Select ${view}. ${time === null ? "No time selected" : `Selected time is ${adapter.format(time, "fullTime")}`}`;
const defaultGetMinutesClockNumberText = (minutes) => `${minutes} minutes`;
const defaultGetHoursClockNumberText = (hours) => `${hours} hours`;
const defaultGetSecondsClockNumberText = (seconds) => `${seconds} seconds`;
const ClockPicker = /* @__PURE__ */ react.forwardRef(function ClockPicker2(inProps, ref) {
  const props = useThemeProps({
    props: inProps,
    name: "MuiClockPicker"
  });
  const {
    ampm = false,
    ampmInClock = false,
    autoFocus,
    components,
    componentsProps,
    date,
    disableIgnoringDatePartForTimeValidation,
    getClockLabelText = defaultGetClockLabelText,
    getHoursClockNumberText = defaultGetHoursClockNumberText,
    getMinutesClockNumberText = defaultGetMinutesClockNumberText,
    getSecondsClockNumberText = defaultGetSecondsClockNumberText,
    leftArrowButtonText = "open previous view",
    maxTime,
    minTime,
    minutesStep = 1,
    rightArrowButtonText = "open next view",
    shouldDisableTime,
    showViewSwitcher,
    onChange,
    view,
    views = ["hours", "minutes"],
    openTo,
    onViewChange,
    className,
    disabled,
    readOnly
  } = props;
  const {
    openView,
    setOpenView,
    nextView,
    previousView,
    handleChangeAndOpenNext
  } = useViews({
    view,
    views,
    openTo,
    onViewChange,
    onChange
  });
  const now = useNow();
  const utils = useUtils();
  const midnight = utils.setSeconds(utils.setMinutes(utils.setHours(now, 0), 0), 0);
  const dateOrMidnight = date || midnight;
  const {
    meridiemMode,
    handleMeridiemChange
  } = useMeridiemMode(dateOrMidnight, ampm, handleChangeAndOpenNext);
  const isTimeDisabled = react.useCallback((rawValue, viewType) => {
    const isAfter = createIsAfterIgnoreDatePart(disableIgnoringDatePartForTimeValidation, utils);
    const containsValidTime = ({
      start,
      end
    }) => {
      if (minTime && isAfter(minTime, end)) {
        return false;
      }
      if (maxTime && isAfter(start, maxTime)) {
        return false;
      }
      return true;
    };
    const isValidValue = (value, step = 1) => {
      if (value % step !== 0) {
        return false;
      }
      if (shouldDisableTime) {
        return !shouldDisableTime(value, viewType);
      }
      return true;
    };
    switch (viewType) {
      case "hours": {
        const value = convertValueToMeridiem(rawValue, meridiemMode, ampm);
        if (date == null) {
          return !isValidValue(value);
        }
        const dateWithNewHours = utils.setHours(date, value);
        const start = utils.setSeconds(utils.setMinutes(dateWithNewHours, 0), 0);
        const end = utils.setSeconds(utils.setMinutes(dateWithNewHours, 59), 59);
        return !containsValidTime({
          start,
          end
        }) || !isValidValue(value);
      }
      case "minutes": {
        if (date == null) {
          return !isValidValue(rawValue, minutesStep);
        }
        const dateWithNewMinutes = utils.setMinutes(date, rawValue);
        const start = utils.setSeconds(dateWithNewMinutes, 0);
        const end = utils.setSeconds(dateWithNewMinutes, 59);
        return !containsValidTime({
          start,
          end
        }) || !isValidValue(rawValue, minutesStep);
      }
      case "seconds": {
        if (date == null) {
          return !isValidValue(rawValue);
        }
        const dateWithNewSeconds = utils.setSeconds(date, rawValue);
        const start = dateWithNewSeconds;
        const end = dateWithNewSeconds;
        return !containsValidTime({
          start,
          end
        }) || !isValidValue(rawValue);
      }
      default:
        throw new Error("not supported");
    }
  }, [ampm, date, disableIgnoringDatePartForTimeValidation, maxTime, meridiemMode, minTime, minutesStep, shouldDisableTime, utils]);
  const selectedId = useId();
  const viewProps = react.useMemo(() => {
    switch (openView) {
      case "hours": {
        const handleHoursChange = (value, isFinish) => {
          const valueWithMeridiem = convertValueToMeridiem(value, meridiemMode, ampm);
          handleChangeAndOpenNext(utils.setHours(dateOrMidnight, valueWithMeridiem), isFinish);
        };
        return {
          onChange: handleHoursChange,
          value: utils.getHours(dateOrMidnight),
          children: getHourNumbers({
            date,
            utils,
            ampm,
            onChange: handleHoursChange,
            getClockNumberText: getHoursClockNumberText,
            isDisabled: (value) => disabled || isTimeDisabled(value, "hours"),
            selectedId
          })
        };
      }
      case "minutes": {
        const minutesValue = utils.getMinutes(dateOrMidnight);
        const handleMinutesChange = (value, isFinish) => {
          handleChangeAndOpenNext(utils.setMinutes(dateOrMidnight, value), isFinish);
        };
        return {
          value: minutesValue,
          onChange: handleMinutesChange,
          children: getMinutesNumbers({
            utils,
            value: minutesValue,
            onChange: handleMinutesChange,
            getClockNumberText: getMinutesClockNumberText,
            isDisabled: (value) => disabled || isTimeDisabled(value, "minutes"),
            selectedId
          })
        };
      }
      case "seconds": {
        const secondsValue = utils.getSeconds(dateOrMidnight);
        const handleSecondsChange = (value, isFinish) => {
          handleChangeAndOpenNext(utils.setSeconds(dateOrMidnight, value), isFinish);
        };
        return {
          value: secondsValue,
          onChange: handleSecondsChange,
          children: getMinutesNumbers({
            utils,
            value: secondsValue,
            onChange: handleSecondsChange,
            getClockNumberText: getSecondsClockNumberText,
            isDisabled: (value) => disabled || isTimeDisabled(value, "seconds"),
            selectedId
          })
        };
      }
      default:
        throw new Error("You must provide the type for ClockView");
    }
  }, [openView, utils, date, ampm, getHoursClockNumberText, getMinutesClockNumberText, getSecondsClockNumberText, meridiemMode, handleChangeAndOpenNext, dateOrMidnight, isTimeDisabled, selectedId, disabled]);
  const ownerState = props;
  const classes2 = useUtilityClasses(ownerState);
  return /* @__PURE__ */ jsxRuntime.jsxs(ClockPickerRoot, {
    ref,
    className: clsx(classes2.root, className),
    ownerState,
    children: [showViewSwitcher && /* @__PURE__ */ jsxRuntime.jsx(ClockPickerArrowSwitcher, {
      className: classes2.arrowSwitcher,
      leftArrowButtonText,
      rightArrowButtonText,
      components,
      componentsProps,
      onLeftClick: () => setOpenView(previousView),
      onRightClick: () => setOpenView(nextView),
      isLeftDisabled: !previousView,
      isRightDisabled: !nextView,
      ownerState
    }), /* @__PURE__ */ jsxRuntime.jsx(Clock, _extends({
      autoFocus,
      date,
      ampmInClock,
      type: openView,
      ampm,
      getClockLabelText,
      minutesStep,
      isTimeDisabled,
      meridiemMode,
      handleMeridiemChange,
      selectedId,
      disabled,
      readOnly
    }, viewProps))]
  });
});
const isYearOnlyView = (views) => views.length === 1 && views[0] === "year";
const isYearAndMonthViews = (views) => views.length === 2 && views.indexOf("month") !== -1 && views.indexOf("year") !== -1;
const getFormatAndMaskByViews = (views, utils) => {
  if (isYearOnlyView(views)) {
    return {
      mask: "____",
      inputFormat: utils.formats.year
    };
  }
  if (isYearAndMonthViews(views)) {
    return {
      disableMaskedInput: true,
      inputFormat: utils.formats.monthAndYear
    };
  }
  return {
    mask: "__/__/____",
    inputFormat: utils.formats.keyboardDate
  };
};
function useDatePickerDefaultizedProps(props, name) {
  var _themeProps$views;
  const utils = useUtils();
  const defaultDates = useDefaultDates();
  const themeProps = useThemeProps({
    props,
    name
  });
  const views = (_themeProps$views = themeProps.views) != null ? _themeProps$views : ["year", "day"];
  return _extends({
    openTo: "day",
    minDate: defaultDates.minDate,
    maxDate: defaultDates.maxDate
  }, getFormatAndMaskByViews(views, utils), themeProps, {
    views
  });
}
const datePickerValueManager = {
  emptyValue: null,
  getTodayValue: (utils) => utils.date(),
  parseInput: parsePickerInputValue,
  areValuesEqual: (utils, a, b) => utils.isEqual(a, b)
};
const classes$1 = generateUtilityClasses("PrivatePickersToolbar", ["root", "dateTitleContainer"]);
const PickersToolbarRoot = styled("div")(({
  theme,
  ownerState
}) => _extends({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "space-between",
  padding: theme.spacing(2, 3)
}, ownerState.isLandscape && {
  height: "auto",
  maxWidth: 160,
  padding: 16,
  justifyContent: "flex-start",
  flexWrap: "wrap"
}));
const PickersToolbarGrid = styled(Grid)({
  flex: 1
});
const getViewTypeIcon = (viewType) => viewType === "clock" ? /* @__PURE__ */ jsxRuntime.jsx(Clock$1, {
  color: "inherit"
}) : /* @__PURE__ */ jsxRuntime.jsx(Calendar, {
  color: "inherit"
});
function defaultGetKeyboardInputSwitchingButtonText(isKeyboardInputOpen, viewType) {
  return isKeyboardInputOpen ? `text input view is open, go to ${viewType} view` : `${viewType} view is open, go to text input view`;
}
const PickersToolbar = /* @__PURE__ */ react.forwardRef(function PickersToolbar2(props, ref) {
  const {
    children,
    className,
    getMobileKeyboardInputViewButtonText = defaultGetKeyboardInputSwitchingButtonText,
    isLandscape,
    isMobileKeyboardViewOpen,
    landscapeDirection = "column",
    penIconClassName,
    toggleMobileKeyboardView,
    toolbarTitle,
    viewType = "calendar"
  } = props;
  const ownerState = props;
  return /* @__PURE__ */ jsxRuntime.jsxs(PickersToolbarRoot, {
    ref,
    className: clsx(classes$1.root, className),
    ownerState,
    children: [/* @__PURE__ */ jsxRuntime.jsx(Typography, {
      color: "text.secondary",
      variant: "overline",
      children: toolbarTitle
    }), /* @__PURE__ */ jsxRuntime.jsxs(PickersToolbarGrid, {
      container: true,
      justifyContent: "space-between",
      className: classes$1.dateTitleContainer,
      direction: isLandscape ? landscapeDirection : "row",
      alignItems: isLandscape ? "flex-start" : "flex-end",
      children: [children, /* @__PURE__ */ jsxRuntime.jsx(IconButton, {
        onClick: toggleMobileKeyboardView,
        className: penIconClassName,
        color: "inherit",
        "aria-label": getMobileKeyboardInputViewButtonText(isMobileKeyboardViewOpen, viewType),
        children: isMobileKeyboardViewOpen ? getViewTypeIcon(viewType) : /* @__PURE__ */ jsxRuntime.jsx(Pen, {
          color: "inherit"
        })
      })]
    })]
  });
});
const _excluded$4 = ["parsedValue", "isLandscape", "isMobileKeyboardViewOpen", "onChange", "toggleMobileKeyboardView", "toolbarFormat", "toolbarPlaceholder", "toolbarTitle", "views"];
const classes = generateUtilityClasses("PrivateDatePickerToolbar", ["penIcon"]);
const DatePickerToolbarRoot = styled(PickersToolbar)({
  [`& .${classes.penIcon}`]: {
    position: "relative",
    top: 4
  }
});
const DatePickerToolbarTitle = styled(Typography)(({
  ownerState
}) => _extends({}, ownerState.isLandscape && {
  margin: "auto 16px auto auto"
}));
const DatePickerToolbar = /* @__PURE__ */ react.forwardRef(function DatePickerToolbar2(props, ref) {
  const {
    parsedValue,
    isLandscape,
    isMobileKeyboardViewOpen,
    toggleMobileKeyboardView,
    toolbarFormat,
    toolbarPlaceholder = "\u2013\u2013",
    toolbarTitle = "Select date",
    views
  } = props, other = _objectWithoutPropertiesLoose(props, _excluded$4);
  const utils = useUtils();
  const dateText = react.useMemo(() => {
    if (!parsedValue) {
      return toolbarPlaceholder;
    }
    if (toolbarFormat) {
      return utils.formatByString(parsedValue, toolbarFormat);
    }
    if (isYearOnlyView(views)) {
      return utils.format(parsedValue, "year");
    }
    if (isYearAndMonthViews(views)) {
      return utils.format(parsedValue, "month");
    }
    return /en/.test(utils.getCurrentLocaleCode()) ? utils.format(parsedValue, "normalDateWithWeekday") : utils.format(parsedValue, "normalDate");
  }, [parsedValue, toolbarFormat, toolbarPlaceholder, utils, views]);
  const ownerState = props;
  return /* @__PURE__ */ jsxRuntime.jsx(DatePickerToolbarRoot, _extends({
    ref,
    toolbarTitle,
    isMobileKeyboardViewOpen,
    toggleMobileKeyboardView,
    isLandscape,
    penIconClassName: classes.penIcon,
    ownerState
  }, other, {
    children: /* @__PURE__ */ jsxRuntime.jsx(DatePickerToolbarTitle, {
      variant: "h4",
      align: isLandscape ? "left" : "center",
      ownerState,
      children: dateText
    })
  }));
});
const useRifm = (props) => {
  const [, refresh] = react.useReducer((c) => c + 1, 0);
  const valueRef = react.useRef(null);
  const {
    replace,
    append
  } = props;
  const userValue = replace ? replace(props.format(props.value)) : props.format(props.value);
  const isDeleleteButtonDownRef = react.useRef(false);
  const onChange = (evt) => {
    const eventValue = evt.target.value;
    valueRef.current = [
      eventValue,
      evt.target,
      eventValue.length > userValue.length,
      isDeleleteButtonDownRef.current,
      userValue === props.format(eventValue)
    ];
    refresh();
  };
  {
    react.useLayoutEffect(() => {
      if (valueRef.current == null)
        return;
      let [
        eventValue,
        input,
        isSizeIncreaseOperation,
        isDeleleteButtonDown,
        isNoOperation
      ] = valueRef.current;
      valueRef.current = null;
      const deleteWasNoOp = isDeleleteButtonDown && isNoOperation;
      const valueAfterSelectionStart = eventValue.slice(input.selectionStart);
      const acceptedCharIndexAfterDelete = valueAfterSelectionStart.search(props.accept || /\d/g);
      const charsToSkipAfterDelete = acceptedCharIndexAfterDelete !== -1 ? acceptedCharIndexAfterDelete : 0;
      const clean = (str) => (str.match(props.accept || /\d/g) || []).join("");
      const valueBeforeSelectionStart = clean(eventValue.substr(0, input.selectionStart));
      const getCursorPosition = (val) => {
        let start = 0;
        let cleanPos = 0;
        for (let i = 0; i !== valueBeforeSelectionStart.length; ++i) {
          let newPos = val.indexOf(valueBeforeSelectionStart[i], start) + 1;
          let newCleanPos = clean(val).indexOf(valueBeforeSelectionStart[i], cleanPos) + 1;
          if (newCleanPos - cleanPos > 1) {
            newPos = start;
            newCleanPos = cleanPos;
          }
          cleanPos = Math.max(newCleanPos, cleanPos);
          start = Math.max(start, newPos);
        }
        return start;
      };
      if (props.mask === true && isSizeIncreaseOperation && !isNoOperation) {
        let start = getCursorPosition(eventValue);
        const c = clean(eventValue.substr(start))[0];
        start = eventValue.indexOf(c, start);
        eventValue = `${eventValue.substr(0, start)}${eventValue.substr(start + 1)}`;
      }
      let formattedValue = props.format(eventValue);
      if (append != null && input.selectionStart === eventValue.length && !isNoOperation) {
        if (isSizeIncreaseOperation) {
          formattedValue = append(formattedValue);
        } else {
          if (clean(formattedValue.slice(-1)) === "") {
            formattedValue = formattedValue.slice(0, -1);
          }
        }
      }
      const replacedValue = replace ? replace(formattedValue) : formattedValue;
      if (userValue === replacedValue) {
        refresh();
      } else {
        props.onChange(replacedValue);
      }
      return () => {
        let start = getCursorPosition(formattedValue);
        if (props.mask != null && (isSizeIncreaseOperation || isDeleleteButtonDown && !deleteWasNoOp)) {
          while (formattedValue[start] && clean(formattedValue[start]) === "") {
            start += 1;
          }
        }
        input.selectionStart = input.selectionEnd = start + (deleteWasNoOp ? 1 + charsToSkipAfterDelete : 0);
      };
    });
  }
  react.useEffect(() => {
    const handleKeyDown = (evt) => {
      if (evt.code === "Delete") {
        isDeleleteButtonDownRef.current = true;
      }
    };
    const handleKeyUp = (evt) => {
      if (evt.code === "Delete") {
        isDeleleteButtonDownRef.current = false;
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("keyup", handleKeyUp);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("keyup", handleKeyUp);
    };
  }, []);
  return {
    value: valueRef.current != null ? valueRef.current[0] : userValue,
    onChange
  };
};
function getTextFieldAriaText(rawValue, utils) {
  return rawValue && utils.isValid(utils.date(rawValue)) ? `Choose date, selected date is ${utils.format(utils.date(rawValue), "fullDate")}` : "Choose date";
}
const getDisplayDate = (utils, rawValue, inputFormat) => {
  const date = utils.date(rawValue);
  const isEmpty = rawValue === null;
  if (isEmpty) {
    return "";
  }
  return utils.isValid(date) ? utils.formatByString(date, inputFormat) : "";
};
const MASK_USER_INPUT_SYMBOL = "_";
const staticDateWith2DigitTokens = "2019-11-21T22:30:00.000";
const staticDateWith1DigitTokens = "2019-01-01T09:00:00.000";
function checkMaskIsValidForCurrentFormat(mask, format, acceptRegex, utils) {
  const formattedDateWith1Digit = utils.formatByString(utils.date(staticDateWith1DigitTokens), format);
  const inferredFormatPatternWith1Digits = formattedDateWith1Digit.replace(acceptRegex, MASK_USER_INPUT_SYMBOL);
  const inferredFormatPatternWith2Digits = utils.formatByString(utils.date(staticDateWith2DigitTokens), format).replace(acceptRegex, "_");
  const isMaskValid = inferredFormatPatternWith2Digits === mask && inferredFormatPatternWith1Digits === mask;
  if (!isMaskValid && utils.lib !== "luxon" && false) {
    const defaultWarning = [`The mask "${mask}" you passed is not valid for the format used ${format}.`, `Falling down to uncontrolled no-mask input.`];
    if (format.includes("MMM")) {
      console.warn([...defaultWarning, `Mask does not support literals such as 'MMM'.`, `Either use numbers with fix length or disable mask feature with 'disableMaskedInput' prop`].join("\n"));
    } else if (inferredFormatPatternWith2Digits !== mask && inferredFormatPatternWith1Digits === mask) {
      console.warn([...defaultWarning, `Mask does not support numbers with variable length such as 'M'.`, `Either use numbers with fix length or disable mask feature with 'disableMaskedInput' prop`].join("\n"));
    } else {
      console.warn(defaultWarning.join("\n"));
    }
  }
  return isMaskValid;
}
const maskedDateFormatter = (mask, acceptRegexp) => (value) => {
  let outputCharIndex = 0;
  return value.split("").map((char, inputCharIndex) => {
    acceptRegexp.lastIndex = 0;
    if (outputCharIndex > mask.length - 1) {
      return "";
    }
    const maskChar = mask[outputCharIndex];
    const nextMaskChar = mask[outputCharIndex + 1];
    const acceptedChar = acceptRegexp.test(char) ? char : "";
    const formattedChar = maskChar === MASK_USER_INPUT_SYMBOL ? acceptedChar : maskChar + acceptedChar;
    outputCharIndex += formattedChar.length;
    const isLastCharacter = inputCharIndex === value.length - 1;
    if (isLastCharacter && nextMaskChar && nextMaskChar !== MASK_USER_INPUT_SYMBOL) {
      return formattedChar ? formattedChar + nextMaskChar : "";
    }
    return formattedChar;
  }).join("");
};
const useMaskedInput = ({
  acceptRegex = /[\d]/gi,
  disabled,
  disableMaskedInput,
  ignoreInvalidInputs,
  inputFormat,
  inputProps,
  label,
  mask,
  onChange,
  rawValue,
  readOnly,
  rifmFormatter,
  TextFieldProps,
  validationError
}) => {
  const utils = useUtils();
  const [isFocused, setIsFocused] = react.useState(false);
  const formatHelperText = utils.getFormatHelperText(inputFormat);
  const shouldUseMaskedInput = react.useMemo(() => {
    if (!mask || disableMaskedInput) {
      return false;
    }
    return checkMaskIsValidForCurrentFormat(mask, inputFormat, acceptRegex, utils);
  }, [acceptRegex, disableMaskedInput, inputFormat, mask, utils]);
  const formatter = react.useMemo(() => shouldUseMaskedInput && mask ? maskedDateFormatter(mask, acceptRegex) : (st) => st, [acceptRegex, mask, shouldUseMaskedInput]);
  const currentInputValue = getDisplayDate(utils, rawValue, inputFormat);
  const [innerInputValue, setInnerInputValue] = react.useState(currentInputValue);
  const previousInputValueRef = react.useRef(currentInputValue);
  react.useEffect(() => {
    previousInputValueRef.current = currentInputValue;
  }, [currentInputValue]);
  const notTyping = !isFocused;
  const valueChanged = previousInputValueRef.current !== currentInputValue;
  if (notTyping && valueChanged && (rawValue === null || utils.isValid(rawValue))) {
    if (currentInputValue !== innerInputValue) {
      setInnerInputValue(currentInputValue);
    }
  }
  const handleChange = (text) => {
    const finalString = text === "" || text === mask ? "" : text;
    setInnerInputValue(finalString);
    const date = finalString === null ? null : utils.parse(finalString, inputFormat);
    if (ignoreInvalidInputs && !utils.isValid(date)) {
      return;
    }
    onChange(date, finalString || void 0);
  };
  const rifmProps = useRifm({
    value: innerInputValue,
    onChange: handleChange,
    format: rifmFormatter || formatter
  });
  const inputStateArgs = shouldUseMaskedInput ? rifmProps : {
    value: innerInputValue,
    onChange: (event) => {
      handleChange(event.currentTarget.value);
    }
  };
  return _extends({
    label,
    disabled,
    error: validationError,
    inputProps: _extends({}, inputStateArgs, {
      disabled,
      placeholder: formatHelperText,
      readOnly,
      type: shouldUseMaskedInput ? "tel" : "text"
    }, inputProps, {
      onFocus: createDelegatedEventHandler(() => {
        setIsFocused(true);
      }, inputProps == null ? void 0 : inputProps.onFocus),
      onBlur: createDelegatedEventHandler(() => {
        setIsFocused(false);
      }, inputProps == null ? void 0 : inputProps.onBlur)
    })
  }, TextFieldProps);
};
const _excluded$3 = ["components", "disableOpenPicker", "getOpenDialogAriaText", "InputAdornmentProps", "InputProps", "inputRef", "openPicker", "OpenPickerButtonProps", "renderInput"];
const KeyboardDateInput = /* @__PURE__ */ react.forwardRef(function KeyboardDateInput2(props, ref) {
  const {
    components = {},
    disableOpenPicker,
    getOpenDialogAriaText = getTextFieldAriaText,
    InputAdornmentProps,
    InputProps,
    inputRef,
    openPicker,
    OpenPickerButtonProps,
    renderInput
  } = props, other = _objectWithoutPropertiesLoose(props, _excluded$3);
  const utils = useUtils();
  const textFieldProps = useMaskedInput(other);
  const adornmentPosition = (InputAdornmentProps == null ? void 0 : InputAdornmentProps.position) || "end";
  const OpenPickerIcon = components.OpenPickerIcon || Calendar;
  return renderInput(_extends({
    ref,
    inputRef
  }, textFieldProps, {
    InputProps: _extends({}, InputProps, {
      [`${adornmentPosition}Adornment`]: disableOpenPicker ? void 0 : /* @__PURE__ */ jsxRuntime.jsx(InputAdornment, _extends({
        position: adornmentPosition
      }, InputAdornmentProps, {
        children: /* @__PURE__ */ jsxRuntime.jsx(IconButton, _extends({
          edge: adornmentPosition,
          disabled: other.disabled || other.readOnly,
          "aria-label": getOpenDialogAriaText(other.rawValue, utils)
        }, OpenPickerButtonProps, {
          onClick: openPicker,
          children: /* @__PURE__ */ jsxRuntime.jsx(OpenPickerIcon, {})
        }))
      }))
    })
  }));
});
function getOrientation() {
  if (typeof window === "undefined") {
    return "portrait";
  }
  if (window.screen && window.screen.orientation && window.screen.orientation.angle) {
    return Math.abs(window.screen.orientation.angle) === 90 ? "landscape" : "portrait";
  }
  if (window.orientation) {
    return Math.abs(Number(window.orientation)) === 90 ? "landscape" : "portrait";
  }
  return "portrait";
}
const useIsLandscape = (views, customOrientation) => {
  const [orientation, setOrientation] = react.useState(getOrientation);
  useEnhancedEffect(() => {
    const eventHandler = () => {
      setOrientation(getOrientation());
    };
    window.addEventListener("orientationchange", eventHandler);
    return () => {
      window.removeEventListener("orientationchange", eventHandler);
    };
  }, []);
  if (arrayIncludes(views, ["hours", "minutes", "seconds"])) {
    return false;
  }
  const orientationToUse = customOrientation || orientation;
  return orientationToUse === "landscape";
};
const _excluded$2 = ["autoFocus", "className", "parsedValue", "DateInputProps", "isMobileKeyboardViewOpen", "onDateChange", "onViewChange", "openTo", "orientation", "showToolbar", "toggleMobileKeyboardView", "ToolbarComponent", "toolbarFormat", "toolbarPlaceholder", "toolbarTitle", "views"];
const MobileKeyboardInputView = styled("div")({
  padding: "16px 24px"
});
const PickerRoot = styled("div")(({
  ownerState
}) => _extends({
  display: "flex",
  flexDirection: "column"
}, ownerState.isLandscape && {
  flexDirection: "row"
}));
const MobileKeyboardTextFieldProps = {
  fullWidth: true
};
const isDatePickerView = (view) => view === "year" || view === "month" || view === "day";
const isTimePickerView = (view) => view === "hours" || view === "minutes" || view === "seconds";
function CalendarOrClockPicker(props) {
  const {
    autoFocus,
    parsedValue,
    DateInputProps,
    isMobileKeyboardViewOpen,
    onDateChange,
    onViewChange,
    openTo,
    orientation,
    showToolbar,
    toggleMobileKeyboardView,
    ToolbarComponent = () => null,
    toolbarFormat,
    toolbarPlaceholder,
    toolbarTitle,
    views
  } = props, other = _objectWithoutPropertiesLoose(props, _excluded$2);
  const isLandscape = useIsLandscape(views, orientation);
  const wrapperVariant = react.useContext(WrapperVariantContext);
  const toShowToolbar = showToolbar != null ? showToolbar : wrapperVariant !== "desktop";
  const handleDateChange = react.useCallback((newDate, selectionState) => {
    onDateChange(newDate, wrapperVariant, selectionState);
  }, [onDateChange, wrapperVariant]);
  const handleViewChange = react.useCallback((newView) => {
    if (isMobileKeyboardViewOpen) {
      toggleMobileKeyboardView();
    }
    if (onViewChange) {
      onViewChange(newView);
    }
  }, [isMobileKeyboardViewOpen, onViewChange, toggleMobileKeyboardView]);
  const {
    openView,
    setOpenView,
    handleChangeAndOpenNext
  } = useViews({
    view: void 0,
    views,
    openTo,
    onChange: handleDateChange,
    onViewChange: handleViewChange
  });
  return /* @__PURE__ */ jsxRuntime.jsxs(PickerRoot, {
    ownerState: {
      isLandscape
    },
    children: [toShowToolbar && /* @__PURE__ */ jsxRuntime.jsx(ToolbarComponent, _extends({}, other, {
      views,
      isLandscape,
      parsedValue,
      onChange: handleDateChange,
      setOpenView,
      openView,
      toolbarTitle,
      toolbarFormat,
      toolbarPlaceholder,
      isMobileKeyboardViewOpen,
      toggleMobileKeyboardView
    })), /* @__PURE__ */ jsxRuntime.jsx(PickerViewRoot, {
      children: isMobileKeyboardViewOpen ? /* @__PURE__ */ jsxRuntime.jsx(MobileKeyboardInputView, {
        children: /* @__PURE__ */ jsxRuntime.jsx(KeyboardDateInput, _extends({}, DateInputProps, {
          ignoreInvalidInputs: true,
          disableOpenPicker: true,
          TextFieldProps: MobileKeyboardTextFieldProps
        }))
      }) : /* @__PURE__ */ jsxRuntime.jsxs(react.Fragment, {
        children: [isDatePickerView(openView) && /* @__PURE__ */ jsxRuntime.jsx(CalendarPicker, _extends({
          autoFocus,
          date: parsedValue,
          onViewChange: setOpenView,
          onChange: handleChangeAndOpenNext,
          view: openView,
          views: views.filter(isDatePickerView)
        }, other)), isTimePickerView(openView) && /* @__PURE__ */ jsxRuntime.jsx(ClockPicker, _extends({}, other, {
          autoFocus,
          date: parsedValue,
          view: openView,
          views: views.filter(isTimePickerView),
          onChange: handleChangeAndOpenNext,
          onViewChange: setOpenView,
          showViewSwitcher: wrapperVariant === "desktop"
        }))]
      })
    })]
  });
}
const useOpenState = ({
  open,
  onOpen,
  onClose
}) => {
  const isControllingOpenProp = react.useRef(typeof open === "boolean").current;
  const [openState, setIsOpenState] = react.useState(false);
  react.useEffect(() => {
    if (isControllingOpenProp) {
      if (typeof open !== "boolean") {
        throw new Error("You must not mix controlling and uncontrolled mode for `open` prop");
      }
      setIsOpenState(open);
    }
  }, [isControllingOpenProp, open]);
  const setIsOpen = react.useCallback((newIsOpen) => {
    if (!isControllingOpenProp) {
      setIsOpenState(newIsOpen);
    }
    if (newIsOpen && onOpen) {
      onOpen();
    }
    if (!newIsOpen && onClose) {
      onClose();
    }
  }, [isControllingOpenProp, onOpen, onClose]);
  return {
    isOpen: openState,
    setIsOpen
  };
};
const usePickerState = (props, valueManager) => {
  const {
    onAccept,
    onChange,
    value,
    closeOnSelect
  } = props;
  const utils = useUtils();
  const {
    isOpen,
    setIsOpen
  } = useOpenState(props);
  const parsedDateValue = react.useMemo(() => valueManager.parseInput(utils, value), [valueManager, utils, value]);
  const [lastValidDateValue, setLastValidDateValue] = react.useState(parsedDateValue);
  const [dateState, setDateState] = react.useState(() => ({
    committed: parsedDateValue,
    draft: parsedDateValue,
    resetFallback: parsedDateValue
  }));
  const setDate = react.useCallback((params) => {
    setDateState((prev) => {
      switch (params.action) {
        case "setAll":
        case "acceptAndClose": {
          return {
            draft: params.value,
            committed: params.value,
            resetFallback: params.value
          };
        }
        case "setCommitted": {
          return _extends({}, prev, {
            draft: params.value,
            committed: params.value
          });
        }
        case "setDraft": {
          return _extends({}, prev, {
            draft: params.value
          });
        }
        default: {
          return prev;
        }
      }
    });
    if (!params.skipOnChangeCall && !valueManager.areValuesEqual(utils, dateState.committed, params.value)) {
      onChange(params.value);
    }
    if (params.action === "acceptAndClose") {
      setIsOpen(false);
      if (onAccept && !valueManager.areValuesEqual(utils, dateState.resetFallback, params.value)) {
        onAccept(params.value);
      }
    }
  }, [onAccept, onChange, setIsOpen, dateState, utils, valueManager]);
  react.useEffect(() => {
    if (utils.isValid(parsedDateValue)) {
      setLastValidDateValue(parsedDateValue);
    }
  }, [utils, parsedDateValue]);
  react.useEffect(() => {
    if (isOpen) {
      setDate({
        action: "setAll",
        value: parsedDateValue,
        skipOnChangeCall: true
      });
    }
  }, [isOpen]);
  if (!valueManager.areValuesEqual(utils, dateState.committed, parsedDateValue)) {
    setDate({
      action: "setCommitted",
      value: parsedDateValue,
      skipOnChangeCall: true
    });
  }
  const wrapperProps = react.useMemo(() => ({
    open: isOpen,
    onClear: () => {
      setDate({
        value: valueManager.emptyValue,
        action: "acceptAndClose"
      });
    },
    onAccept: () => {
      setDate({
        value: dateState.draft,
        action: "acceptAndClose"
      });
    },
    onDismiss: () => {
      setDate({
        value: dateState.committed,
        action: "acceptAndClose"
      });
    },
    onCancel: () => {
      setDate({
        value: dateState.resetFallback,
        action: "acceptAndClose"
      });
    },
    onSetToday: () => {
      setDate({
        value: valueManager.getTodayValue(utils),
        action: "acceptAndClose"
      });
    }
  }), [setDate, isOpen, utils, dateState, valueManager]);
  const [isMobileKeyboardViewOpen, setMobileKeyboardViewOpen] = react.useState(false);
  const pickerProps = react.useMemo(() => ({
    parsedValue: dateState.draft,
    isMobileKeyboardViewOpen,
    toggleMobileKeyboardView: () => setMobileKeyboardViewOpen(!isMobileKeyboardViewOpen),
    onDateChange: (newDate, wrapperVariant, selectionState = "partial") => {
      switch (selectionState) {
        case "shallow": {
          return setDate({
            action: "setDraft",
            value: newDate,
            skipOnChangeCall: true
          });
        }
        case "partial": {
          return setDate({
            action: "setDraft",
            value: newDate
          });
        }
        case "finish": {
          if (closeOnSelect != null ? closeOnSelect : wrapperVariant === "desktop") {
            return setDate({
              value: newDate,
              action: "acceptAndClose"
            });
          }
          return setDate({
            value: newDate,
            action: "setCommitted"
          });
        }
        default: {
          throw new Error("MUI: Invalid selectionState passed to `onDateChange`");
        }
      }
    }
  }), [setDate, isMobileKeyboardViewOpen, dateState.draft, closeOnSelect]);
  const handleInputChange = react.useCallback((newParsedValue, keyboardInputValue) => {
    const cleanParsedValue = valueManager.valueReducer ? valueManager.valueReducer(utils, lastValidDateValue, newParsedValue) : newParsedValue;
    onChange(cleanParsedValue, keyboardInputValue);
  }, [onChange, valueManager, lastValidDateValue, utils]);
  const inputProps = react.useMemo(() => ({
    onChange: handleInputChange,
    open: isOpen,
    rawValue: value,
    openPicker: () => setIsOpen(true)
  }), [handleInputChange, isOpen, value, setIsOpen]);
  const pickerState = {
    pickerProps,
    inputProps,
    wrapperProps
  };
  react.useDebugValue(pickerState, () => ({
    MuiPickerState: {
      dateState,
      other: pickerState
    }
  }));
  return pickerState;
};
const PickersModalDialogRoot = styled(Dialog)({
  [`& .${dialogClasses.container}`]: {
    outline: 0
  },
  [`& .${dialogClasses.paper}`]: {
    outline: 0,
    minWidth: DIALOG_WIDTH
  }
});
const PickersModalDialogContent = styled(DialogContent$1)({
  "&:first-of-type": {
    padding: 0
  }
});
const PickersModalDialogActions = styled(DialogActions)(({
  ownerState
}) => _extends({}, (ownerState.clearable || ownerState.showTodayButton) && {
  justifyContent: "flex-start",
  "& > *:first-of-type": {
    marginRight: "auto"
  }
}));
const PickersModalDialog = (props) => {
  const {
    cancelText = "Cancel",
    children,
    clearable = false,
    clearText = "Clear",
    DialogProps = {},
    okText = "OK",
    onAccept,
    onClear,
    onDismiss,
    onCancel,
    onSetToday,
    open,
    showTodayButton = false,
    todayText = "Today"
  } = props;
  const ownerState = props;
  return /* @__PURE__ */ jsxRuntime.jsxs(PickersModalDialogRoot, _extends({
    open,
    onClose: onDismiss
  }, DialogProps, {
    children: [/* @__PURE__ */ jsxRuntime.jsx(PickersModalDialogContent, {
      children
    }), /* @__PURE__ */ jsxRuntime.jsxs(PickersModalDialogActions, {
      ownerState,
      children: [clearable && /* @__PURE__ */ jsxRuntime.jsx(Button, {
        onClick: onClear,
        children: clearText
      }), showTodayButton && /* @__PURE__ */ jsxRuntime.jsx(Button, {
        onClick: onSetToday,
        children: todayText
      }), cancelText && /* @__PURE__ */ jsxRuntime.jsx(Button, {
        onClick: onCancel,
        children: cancelText
      }), okText && /* @__PURE__ */ jsxRuntime.jsx(Button, {
        onClick: onAccept,
        children: okText
      })]
    })]
  }));
};
const _excluded$1 = ["cancelText", "children", "clearable", "clearText", "DateInputProps", "DialogProps", "okText", "onAccept", "onClear", "onDismiss", "onCancel", "onSetToday", "open", "PureDateInputComponent", "showTodayButton", "todayText"];
function MobileWrapper(props) {
  const {
    cancelText,
    children,
    clearable,
    clearText,
    DateInputProps,
    DialogProps,
    okText,
    onAccept,
    onClear,
    onDismiss,
    onCancel,
    onSetToday,
    open,
    PureDateInputComponent,
    showTodayButton,
    todayText
  } = props, other = _objectWithoutPropertiesLoose(props, _excluded$1);
  return /* @__PURE__ */ jsxRuntime.jsxs(WrapperVariantContext.Provider, {
    value: "mobile",
    children: [/* @__PURE__ */ jsxRuntime.jsx(PureDateInputComponent, _extends({}, other, DateInputProps)), /* @__PURE__ */ jsxRuntime.jsx(PickersModalDialog, {
      cancelText,
      clearable,
      clearText,
      DialogProps,
      okText,
      onAccept,
      onClear,
      onDismiss,
      onCancel,
      onSetToday,
      open,
      showTodayButton,
      todayText,
      children
    })]
  });
}
const PureDateInput = /* @__PURE__ */ react.forwardRef(function PureDateInput2(props, ref) {
  const {
    disabled,
    getOpenDialogAriaText = getTextFieldAriaText,
    inputFormat,
    InputProps,
    inputRef,
    label,
    openPicker: onOpen,
    rawValue,
    renderInput,
    TextFieldProps = {},
    validationError
  } = props;
  const utils = useUtils();
  const PureDateInputProps = react.useMemo(() => _extends({}, InputProps, {
    readOnly: true
  }), [InputProps]);
  const inputValue = getDisplayDate(utils, rawValue, inputFormat);
  return renderInput(_extends({
    label,
    disabled,
    ref,
    inputRef,
    error: validationError,
    InputProps: PureDateInputProps,
    inputProps: _extends({
      disabled,
      readOnly: true,
      "aria-readonly": true,
      "aria-label": getOpenDialogAriaText(rawValue, utils),
      value: inputValue
    }, !props.readOnly && {
      onClick: onOpen
    }, {
      onKeyDown: onSpaceOrEnter(onOpen)
    })
  }, TextFieldProps));
});
PureDateInput.propTypes = {
  getOpenDialogAriaText: PropTypes.func,
  renderInput: PropTypes.func.isRequired
};
const _excluded = ["ToolbarComponent", "value", "onChange"];
const MobileDatePicker = /* @__PURE__ */ react.forwardRef(function MobileDatePicker2(inProps, ref) {
  const props = useDatePickerDefaultizedProps(inProps, "MuiMobileDatePicker");
  const validationError = useDateValidation(props) !== null;
  const {
    pickerProps,
    inputProps,
    wrapperProps
  } = usePickerState(props, datePickerValueManager);
  const {
    ToolbarComponent = DatePickerToolbar
  } = props, other = _objectWithoutPropertiesLoose(props, _excluded);
  const DateInputProps = _extends({}, inputProps, other, {
    ref,
    validationError
  });
  return /* @__PURE__ */ jsxRuntime.jsx(MobileWrapper, _extends({}, other, wrapperProps, {
    DateInputProps,
    PureDateInputComponent: PureDateInput,
    children: /* @__PURE__ */ jsxRuntime.jsx(CalendarOrClockPicker, _extends({}, pickerProps, {
      autoFocus: true,
      toolbarTitle: props.label || props.toolbarTitle,
      ToolbarComponent,
      DateInputProps
    }, other))
  }));
});
export {CalendarOrClockPicker as C, DateRange as D, KeyboardDateInput as K, MobileWrapper as M, PickersToolbar as P, Time as T, WrapperVariantContext as W, useDefaultDates as a, useValidation as b, createIsAfterIgnoreDatePart as c, usePickerState as d, PureDateInput as e, useDatePickerDefaultizedProps as f, useDateValidation as g, datePickerValueManager as h, DatePickerToolbar as i, MobileDatePicker as j, parsePickerInputValue as p, useUtils as u, validateDate as v};
