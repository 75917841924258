import React, {useState, useEffect, useContext} from "../../../_snowpack/pkg/react.js";
import {useMutation, useQuery} from "../../../_snowpack/pkg/@apollo/client.js";
import CloseIcon from "../../../_snowpack/pkg/@mui/icons-material/Close.js";
import {Box, Button, Card, Snackbar} from "../../../_snowpack/pkg/@mui/material.js";
import {useFlag} from "../../../_snowpack/pkg/@unleash/proxy-client-react.js";
import {startOfDay} from "../../../_snowpack/pkg/date-fns.js";
import {map} from "../../../_snowpack/pkg/lodash.js";
import {EMPTY_STRING, EPIC_275_STORY_5395, HYPHEN} from "../../../core/constants/index.js";
import {MthLoadingWrapper} from "../../../core/components/shared/MthLoadingWrapper/MthLoadingWrapper.js";
import {
  EnrollmentPacketSaveAction,
  EnrollmentPacketSaveContext,
  MthColor,
  WithdrawalOption,
  WithdrawalStatus,
  RoleLabels,
  StudentStatus,
  ScheduleStatus,
  StudentStatusLabel
} from "../../../core/enums/index.js";
import {saveWithdrawalMutation} from "../../../graphql/mutation/withdrawal.js";
import {submitDiplomaAnswerGql} from "../../../graphql/queries/diploma.js";
import {useCurrentSchoolYearByRegionId, useNextSchoolYearByRegionId} from "../../../core/hooks/index.js";
import {UserContext} from "../../../providers/UserContext/UserProvider.js";
import {approveApplicationMutation, updateScheduleStatus} from "../Applications/services.js";
import {savePacketMutation} from "../EnrollmentPackets/services.js";
import {Header} from "./components/Header/Header.js";
import {NewUserModal} from "./components/NewUserModal/NewUserModal.js";
import {Students} from "./components/Students/Students.js";
import {ParentProfile} from "./ParentProfile/ParentProfile.js";
import {
  DeleteWithdrawal,
  getParentDetail,
  updatePersonAddressMutation,
  updateStudentsAddressMutation,
  UpdateStudentMutation,
  getStudentDetail
} from "./services.js";
import {StudentProfile} from "./StudentProfile/StudentProfile.js";
import {useStyles} from "./styles.js";
import {stripHtmlTags} from "./utils.js";
export const UserProfile = ({handleClose, role, student_id, parent_id, setIsChanged}) => {
  const classes = useStyles;
  const epic275story5395 = useFlag(EPIC_275_STORY_5395);
  const [loading, setLoading] = useState(false);
  const [userInfo, setUserInfo] = useState();
  const [phoneInfo, setPhoneInfo] = useState();
  const [addresInfo, setAddressInfo] = useState();
  const [parentEmail, setParentEmail] = useState();
  const [students, setStudents] = useState([]);
  const [observers, setObservers] = useState([]);
  const [notes, setNotes] = useState(EMPTY_STRING);
  const [studentNotes, setStudentNotes] = useState(EMPTY_STRING);
  const [studentPerson, setStudentPerson] = useState();
  const [openObserverModal, setOpenObserverModal] = useState(false);
  const [student, setStudent] = useState();
  const [studentSchoolYears, setStudentSchoolYears] = useState([]);
  const [actualSchedule, setActualSchedule] = useState();
  const [studentStatus, setStudentStatus] = useState({
    student_id: 0,
    special_ed: 0,
    diploma_seeking: 0,
    testing_preference: EMPTY_STRING,
    status: 0,
    date: EMPTY_STRING,
    school_year_id: 0,
    birth: EMPTY_STRING
  });
  const [selectedParent, setSelectedParent] = useState(0);
  const [userRole, setUserRole] = useState(RoleLabels.PARENT);
  const [selectedStudent, setSelectedStudent] = useState(student_id);
  const [applicationState, setApplicationState] = useState(EMPTY_STRING);
  const [studentOldStatus, setStudentOldStatus] = useState(0);
  const [requesting, setRequesting] = useState(false);
  const {me} = useContext(UserContext);
  const [errorFlag, setErrorFlag] = useState(false);
  const [updateStudent] = useMutation(UpdateStudentMutation);
  const [createWithdrawal] = useMutation(saveWithdrawalMutation);
  const [deleteWithdrawal] = useMutation(DeleteWithdrawal);
  const [saveDiplomaAnswer] = useMutation(submitDiplomaAnswerGql);
  const [isEditedByExternal, setIsEditedByExternal] = useState(false);
  const {data: schoolYear} = useCurrentSchoolYearByRegionId(Number(me?.selectedRegionId));
  const {data: nextSchoolYear} = useNextSchoolYearByRegionId(Number(me?.selectedRegionId));
  const [updatePersonAddress] = useMutation(updatePersonAddressMutation);
  const [updateStudentsAddress] = useMutation(updateStudentsAddressMutation, {
    errorPolicy: "all",
    onError() {
      setErrorFlag(true);
    }
  });
  const {data: currentUserData, refetch} = useQuery(getParentDetail, {
    variables: {
      id: Number(parent_id)
    },
    fetchPolicy: "network-only"
  });
  useEffect(() => {
    if (role === RoleLabels.STUDENT) {
      setUserRole(RoleLabels.STUDENT);
    } else if (role !== RoleLabels.STUDENT && role !== RoleLabels.PARENT) {
      setUserRole(RoleLabels.OBSERVER);
    }
  }, []);
  const getStudentNotesForParent = (studentsList = students) => {
    let notes2 = "";
    studentsList.forEach((student2) => {
      const noHtmlNotes = stripHtmlTags(student2?.admin_notes);
      if (noHtmlNotes !== EMPTY_STRING) {
        notes2 += ` <p><strong>${student2?.person.first_name} ${HYPHEN} </strong>${noHtmlNotes}</p><br>`;
      }
    });
    return notes2;
  };
  useEffect(() => {
    if (currentUserData) {
      const studentsWithApplicationNotRemoved = currentUserData.parentDetail.students.filter((student2) => !student2?.applications?.every((application) => application?.status === StudentStatusLabel.DELETED)) ?? currentUserData;
      setUserInfo(currentUserData.parentDetail.person);
      setStudents(studentsWithApplicationNotRemoved);
      setPhoneInfo(currentUserData.parentDetail.phone);
      setAddressInfo(currentUserData.parentDetail.person.address);
      setNotes(currentUserData.parentDetail.notes);
      setParentEmail(currentUserData.parentDetail.person.email);
      setObservers(currentUserData.parentDetail.observers);
      if (!student_id) {
        if (epic275story5395)
          setStudentNotes(getStudentNotesForParent(studentsWithApplicationNotRemoved));
        setSelectedStudent(Number(currentUserData.parentDetail.students[0].student_id));
      } else {
        setSelectedStudent(Number(student_id));
        if (epic275story5395) {
          const selectedStudentData = studentsWithApplicationNotRemoved.find((student2) => Number(student2.student_id) === student_id);
          setStudentNotes(selectedStudentData?.admin_notes);
        }
      }
      if (currentUserData.parentDetail.person.user.userRegions.length) {
        setApplicationState(currentUserData.parentDetail.person.user.userRegions[0].regionDetail.name);
      } else if (currentUserData.parentDetail.person?.address?.state) {
        setApplicationState(currentUserData.parentDetail.person.address.state);
      }
      if (!selectedStudent) {
        setSelectedParent(parseInt(currentUserData.parentDetail.parent_id));
      }
    }
  }, [currentUserData]);
  const {data: currentStudentData, refetch: refetchStudent} = useQuery(getStudentDetail, {
    variables: {
      student_id: selectedStudent
    },
    skip: !selectedStudent,
    fetchPolicy: "cache-and-network"
  });
  useEffect(() => {
    if (currentStudentData) {
      const {student: student2, student_school_year} = currentStudentData;
      setStudent(student2);
      setStudentSchoolYears(student_school_year);
      setStudentOldStatus(currentStudentData.student?.status.at(-1)?.status);
    }
  }, [currentStudentData]);
  const [updateStatus] = useMutation(updateScheduleStatus);
  const [approveApplication] = useMutation(approveApplicationMutation);
  const approveApplicationAction = async (applicationId, currentStatus) => {
    await approveApplication({
      variables: {
        acceptApplicationInput: {
          application_ids: [String(applicationId)],
          fromAdmin: true,
          currentStatus
        }
      }
    });
  };
  const updateStatusScheduleAction = async (scheduleId, status) => {
    await updateStatus({
      variables: {
        scheduleId,
        status
      }
    });
  };
  const [savePacket] = useMutation(savePacketMutation);
  const approveEnrollmentPacketAction = async (packet_id) => {
    await savePacket({
      variables: {
        enrollmentPacketInput: {
          packet_id: Number(packet_id),
          status: EnrollmentPacketSaveAction.ACCEPTED,
          save_context: EnrollmentPacketSaveContext.USER_PROFILE_ACTION
        }
      }
    });
  };
  const handleSavePerson = async () => {
    setLoading(true);
    if (selectedParent) {
      const person = Object.assign({}, userInfo);
      delete person.address;
      delete person.phone;
      person.person_id = Number(person.person_id);
      const phone = Object.assign({}, phoneInfo);
      phone.phone_id = Number(phone.phone_id);
      delete person.user;
      const address = Object.assign({}, userInfo.address);
      delete person.uniqueId;
      address.address_id = address.address_id ? Number(address.address_id) : void 0;
      await updatePersonAddress({
        variables: {
          updatePersonAddressInput: {
            parent_id: selectedParent ? Number(selectedParent) : void 0,
            address,
            phone,
            person,
            notes
          }
        }
      });
      const {errors} = await updateStudentsAddress({
        variables: {
          updateStudentsAddressInput: {
            address,
            person_id: Number(person.person_id)
          }
        }
      });
      if (errors)
        return;
      setRequesting(false);
      handleClose(true);
    } else {
      const person = Object.assign({}, studentPerson);
      delete person.address;
      delete person.phone;
      person.person_id = Number(person.person_id);
      person.date_of_birth = studentStatus.birth;
      const phone = Object.assign({}, phoneInfo);
      phone.phone_id = Number(phone.phone_id);
      const address = Object.assign({}, studentPerson.address);
      address.address_id = Number(address.address_id);
      delete person.uniqueId;
      await updatePersonAddress({
        variables: {
          updatePersonAddressInput: {
            address,
            phone,
            person
          }
        }
      });
      await updateStudent({
        variables: {
          updateStudentInput: {
            student_id: studentStatus?.student_id ? studentStatus?.student_id : null,
            grade_level: studentStatus?.student_id ? String(studentStatus?.grade_level) : null,
            special_ed: studentStatus?.student_id ? studentStatus?.special_ed : null,
            diploma_seeking: studentStatus?.student_id ? studentStatus?.diploma_seeking : null,
            status: studentStatus?.student_id && studentStatus.status !== StudentStatus.WITHDRAWN ? studentStatus?.status : null,
            school_year_id: studentStatus?.student_id ? studentStatus?.school_year_id : null,
            testing_preference: studentStatus?.student_id ? studentStatus?.testing_preference : null,
            date: studentStatus?.student_id ? studentStatus?.date : null,
            ...epic275story5395 ? {admin_notes: studentNotes} : {}
          }
        }
      }).catch((error) => {
        console.error(error);
      });
      const isCurrentAccepted = (studentStatus?.student_id ? studentStatus?.status : null) === StudentStatus.ACCEPTED;
      const isCurrentPending = (studentStatus?.student_id ? studentStatus?.status : null) === StudentStatus.PENDING;
      const isCurrentActive = (studentStatus?.student_id ? studentStatus?.status : null) === StudentStatus.ACTIVE;
      if (isCurrentAccepted && (studentOldStatus === StudentStatus.APPLIED || studentOldStatus === StudentStatus.REAPPLIED)) {
        const curStudent = currentStudentData?.student;
        if (curStudent?.applications.length > 0) {
          const curApplicatonID = curStudent?.applications?.at(-1)?.application_id;
          approveApplicationAction(curApplicatonID, StudentStatus.ACCEPTED.toString());
        }
      } else if (isCurrentPending && studentOldStatus === StudentStatus.ACCEPTED) {
        const curStudent = currentStudentData?.student;
        if (curStudent?.packets.length > 0) {
          const curPacketID = curStudent?.packets[0].packet_id;
          approveEnrollmentPacketAction(curPacketID);
        }
      }
      if (isCurrentActive && studentOldStatus === StudentStatus.REAPPLIED) {
        if (currentStudentData.student?.applications.length > 0) {
          const curApplicatonID = currentStudentData.student?.applications?.at(-1)?.application_id;
          approveApplicationAction(curApplicatonID, StudentStatus.ACTIVE.toString());
        }
      }
      if (isCurrentPending && studentOldStatus === StudentStatus.REAPPLIED) {
        if (actualSchedule?.scheduleId) {
          updateStatusScheduleAction(actualSchedule?.scheduleId, ScheduleStatus.NOT_SUBMITTED);
        }
      }
      if (typeof studentStatus?.diploma_seeking === "number" && studentStatus?.school_year_id) {
        await saveDiplomaAnswer({
          variables: {
            saveDiplomaAnswerInput: {
              answer: studentStatus?.diploma_seeking,
              studentId: studentStatus?.student_id,
              schoolYearId: studentStatus?.school_year_id
            }
          }
        }).catch((error) => {
          console.error(error);
        });
      }
      if (studentStatus?.withdrawOption) {
        await createWithdrawal({
          variables: {
            withdrawalInput: {
              withdrawal: {
                StudentId: studentStatus?.student_id,
                school_year_id: studentStatus?.school_year_id,
                status: studentStatus?.withdrawOption === WithdrawalOption.NOTIFY_PARENT_OF_WITHDRAW ? WithdrawalStatus.NOTIFIED : WithdrawalStatus.WITHDRAWN,
                date_effective: studentStatus?.withdrawOption !== WithdrawalOption.NOTIFY_PARENT_OF_WITHDRAW ? startOfDay(new Date()).toISOString() : null
              },
              withdrawalOption: Number(studentStatus?.withdrawOption)
            }
          }
        });
      }
      if (studentStatus?.activeOption) {
        await deleteWithdrawal({
          variables: {
            studentId: studentStatus?.student_id,
            activeOption: studentStatus?.activeOption,
            schoolYearId: studentStatus?.school_year_id
          }
        });
      }
      setRequesting(false);
      handleClose(true);
    }
    setLoading(false);
  };
  const handleCloseObserverModal = () => {
    setOpenObserverModal(false);
    refetch();
  };
  const handleChangeParent = (parent) => {
    setSelectedStudent(0);
    if (parent?.observer_id) {
      setSelectedParent(parseInt(parent.observer_id));
      setUserInfo(parent.person);
      setPhoneInfo(parent.person.phone);
      setAddressInfo(parent.person.address);
      setNotes(parent.notes || EMPTY_STRING);
      setStudents(currentUserData.parentDetail.students.filter((x) => x.student_id == parent.student_id));
      setUserRole(RoleLabels.OBSERVER);
    } else if (currentUserData?.parentDetail) {
      setSelectedParent(parseInt(currentUserData.parentDetail.parent_id));
      setUserInfo(currentUserData.parentDetail.person);
      setPhoneInfo(currentUserData.parentDetail.phone);
      setAddressInfo(currentUserData.parentDetail.person.address);
      setNotes(currentUserData.parentDetail.notes);
      setParentEmail(currentUserData.parentDetail.person.email);
      setStudents(currentUserData.parentDetail.students);
      setUserRole(RoleLabels.PARENT);
    }
    if (epic275story5395)
      setStudentNotes(getStudentNotesForParent());
  };
  const handleChangeStudent = async (student2) => {
    setSelectedParent(0);
    await setSelectedStudent(Number(student2.student_id));
    if (epic275story5395) {
      setNotes(currentUserData.parentDetail.notes);
      setStudentNotes(student2.admin_notes);
      setIsEditedByExternal(!isEditedByExternal);
    }
    refetchStudent();
    setUserRole(RoleLabels.STUDENT);
  };
  return /* @__PURE__ */ React.createElement(Card, {
    sx: classes.content
  }, /* @__PURE__ */ React.createElement(Box, {
    sx: {
      display: "flex",
      flexDirection: "row",
      alignItems: "flex-start",
      justifyContent: "space-between"
    }
  }, /* @__PURE__ */ React.createElement(Header, {
    userData: currentUserData?.parentDetail?.person,
    setOpenObserverModal,
    observers,
    handleChangeParent,
    selectedParent,
    parentId: currentUserData?.parentDetail?.parent_id,
    me
  }), /* @__PURE__ */ React.createElement(Box, {
    sx: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "flex-end"
    }
  }, !requesting && /* @__PURE__ */ React.createElement(Button, {
    sx: {
      background: MthColor.BUTTON_LINEAR_GRADIENT,
      textTransform: "none",
      color: "white",
      marginRight: 2,
      width: "92px",
      height: "25px"
    },
    onClick: () => {
      setIsChanged(false);
      setRequesting(true);
      handleSavePerson();
    }
  }, "Save"), /* @__PURE__ */ React.createElement(CloseIcon, {
    style: {color: "white", background: MthColor.BLACK, borderRadius: 2, cursor: "pointer"},
    onClick: () => handleClose(false)
  }))), /* @__PURE__ */ React.createElement(MthLoadingWrapper, {
    loading
  }, /* @__PURE__ */ React.createElement(Students, {
    students,
    selectedStudent,
    handleChangeStudent,
    me,
    currentSchoolYear: schoolYear,
    nextSchoolYear,
    userRole
  }), userRole === RoleLabels.PARENT ? /* @__PURE__ */ React.createElement(ParentProfile, {
    userInfo,
    setUserInfo,
    phoneInfo,
    setPhoneInfo,
    notes,
    setNotes,
    applicationState,
    familyStudentIds: map(students, (familyMember) => Number(familyMember.student_id)),
    studentNotes,
    setStudentNotes
  }) : userRole === RoleLabels.STUDENT ? /* @__PURE__ */ React.createElement(StudentProfile, {
    setLoading,
    studentId: selectedStudent,
    setStudentPerson,
    setStudentStatus,
    studentStatus,
    setActualSchedule,
    actualSchedule,
    applicationState,
    setIsChanged,
    phoneInfo,
    addressInfo: addresInfo,
    notes,
    setNotes,
    setPhoneInfo,
    student,
    studentSchoolYears,
    refetchStudent,
    currentSchoolYear: schoolYear,
    nextSchoolYear,
    familyStudentIds: map(students, (familyMember) => Number(familyMember.student_id)),
    studentNotes,
    setStudentNotes,
    isEditedByExternal
  }) : /* @__PURE__ */ React.createElement(React.Fragment, null)), openObserverModal && /* @__PURE__ */ React.createElement(NewUserModal, {
    handleModem: handleCloseObserverModal,
    visible: openObserverModal,
    students,
    parent_id,
    ParentEmailValue: parentEmail,
    regionId: me?.selectedRegionId,
    schoolYearId: schoolYear.school_year_id,
    currentRegion: me?.userRegion?.find((region) => region.region_id === me?.selectedRegionId)
  }), /* @__PURE__ */ React.createElement(Snackbar, {
    open: errorFlag,
    autoHideDuration: 5e3,
    onClose: () => setErrorFlag(false),
    message: "Error",
    anchorOrigin: {vertical: "bottom", horizontal: "center"},
    sx: classes.snackbar
  }));
};
