import React, {useEffect, useState, useContext, useMemo, useCallback} from "../../../../_snowpack/pkg/react.js";
import {useMutation, useQuery} from "../../../../_snowpack/pkg/@apollo/client.js";
import {KeyboardArrowDown} from "../../../../_snowpack/pkg/@mui/icons-material.js";
import {Avatar, Button, Grid, TextField, Select, MenuItem, Typography} from "../../../../_snowpack/pkg/@mui/material.js";
import {Box} from "../../../../_snowpack/pkg/@mui/system.js";
import {useFlag} from "../../../../_snowpack/pkg/@unleash/proxy-client-react.js";
import dayjs from "../../../../_snowpack/pkg/dayjs.js";
import timezone from "../../../../_snowpack/pkg/dayjs/plugin/timezone.js";
import utc from "../../../../_snowpack/pkg/dayjs/plugin/utc.js";
import {isNil} from "../../../../_snowpack/pkg/lodash.js";
import {WarningModal} from "../../../../components/WarningModal/Warning.js";
import {
  EMPTY_STRING,
  EPIC_3537_BUG_4794,
  GRADES,
  STATES_WITH_ABBREVIATION,
  USER_ID,
  US_STATES,
  EPIC_275_STORY_5395,
  EPIC_3346_BUG_5434,
  EPIC_2209_STORY_5188
} from "../../../../core/constants/index.js";
import {StudentSoeTransferModal} from "../../../../core/components/SchoolOfEnrollment/index.js";
import {DropDown} from "../../../../core/components/shared/DropDown/DropDown.js";
import {Paragraph} from "../../../../core/components/shared/Paragraph/Paragraph.js";
import {Subtitle} from "../../../../core/components/shared/Subtitle/Subtitle.js";
import {Title} from "../../../../core/components/shared/Title/Title.js";
import {
  MthColor,
  MthRoute,
  MthTitle,
  PacketStatus,
  ScheduleStatus,
  Separator,
  SoETransferOption,
  StudentStatus,
  TEXT_SIZE,
  datePatterns,
  soeLowStatus,
  soeUppStatus
} from "../../../../core/enums/index.js";
import {updateStudentAssessmentMutation} from "../../../../graphql/mutation/assessment.js";
import {getAssessmentsBySchoolYearId, getStudentAssessmentsByStudentId} from "../../../../graphql/queries/assessment.js";
import {getWithdrawalStatusQuery} from "../../../../graphql/queries/withdrawal.js";
import {useSoeOperations} from "../../../../core/hooks/index.js";
import {UserContext} from "../../../../providers/UserContext/UserProvider.js";
import {ReimbursementModal} from "../../Reimbursements/ReimbursementModal/index.js";
import {
  expandAbbreviationSingleGrade,
  formatDateByPattern,
  getStringInitialLetter,
  getTimezone,
  isInEligibleStudentStatus,
  utcToTimezoned
} from "../../../../core/utils/index.js";
import {ProfilePacketModal} from "../../EnrollmentPackets/EnrollmentPacketModal/ProfilePacketModal.js";
import {GetSchoolsPartner} from "../../SchoolOfEnrollment/services.js";
import {HomeroomResourceModal} from "../components/NewUserModal/HomeroomResourceModal.js";
import {FamilyNotesAndStudentNotes} from "../components/Share/FamilyNotesAndStudentNotes.js";
import {
  getSchoolYearsByRegionId,
  getCountiesByRegionId,
  getSchoolDistrictsByRegionId,
  getScheduleByStudentId
} from "../services.js";
import {useStyles} from "../styles.js";
import {testIDs} from "./__tests__/StudentProfile.testIds.js";
import {StudentFilters} from "./components/StudentFilters.js";
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("MST");
export const StudentProfile = ({
  setLoading,
  studentId,
  setStudentPerson,
  setStudentStatus,
  studentStatus,
  applicationState,
  setIsChanged,
  phoneInfo,
  addressInfo,
  setPhoneInfo,
  notes,
  setNotes,
  studentNotes,
  setStudentNotes,
  student,
  studentSchoolYears,
  refetchStudent,
  currentSchoolYear,
  nextSchoolYear,
  familyStudentIds,
  setActualSchedule,
  actualSchedule,
  isEditedByExternal
}) => {
  const epic3537bug4794 = useFlag(EPIC_3537_BUG_4794);
  const epic275story5395 = useFlag(EPIC_275_STORY_5395);
  const epic3346bug5434 = useFlag(EPIC_3346_BUG_5434);
  const epic2209Story5188 = useFlag(EPIC_2209_STORY_5188);
  const {me} = useContext(UserContext);
  const timeZone = getTimezone(me);
  const DISABLE_STUDENT_ADDRESS = true;
  const classes = useStyles;
  const [originStudentStatus, setOriginStudentStatus] = useState();
  const [studentAssessments, setStudentAssessments] = useState([]);
  const [assessmentItems, setAssessmentItems] = useState([]);
  const [specialEdOptions, setSpecialEdOptions] = useState([]);
  const [showReimbursementModal, setShowReimbursementModal] = useState(false);
  const {transferStudentsSOE, assignStudentsSOE} = useSoeOperations();
  const selectedYearId = useMemo(() => {
    return currentSchoolYear?.school_year_id || nextSchoolYear?.school_year_id;
  }, [currentSchoolYear, nextSchoolYear]);
  const selectedSchoolYear = useMemo(() => {
    return currentSchoolYear || nextSchoolYear;
  }, [currentSchoolYear, nextSchoolYear]);
  const activeSchoolYear = studentSchoolYears.find((item) => item.school_year_id === Number(currentSchoolYear?.school_year_id)) || studentSchoolYears.find((item) => item.school_year_id === Number(nextSchoolYear?.school_year_id));
  const schoolYearToShow = activeSchoolYear ? activeSchoolYear : currentSchoolYear;
  const {data: regionData} = useQuery(getSchoolYearsByRegionId, {
    variables: {
      regionId: me?.selectedRegionId
    },
    fetchPolicy: "cache-and-network"
  });
  const {data: scheduleByStudent} = useQuery(getScheduleByStudentId, {
    variables: {
      studentId: Number(studentId)
    },
    skip: !studentId,
    fetchPolicy: "cache-and-network"
  });
  const {data: schoolPartnerData} = useQuery(GetSchoolsPartner, {
    variables: {
      schoolPartnerArgs: {
        region_id: me?.selectedRegionId,
        school_year_id: selectedYearId,
        sort: {
          column: "name",
          direction: "ASC"
        }
      }
    },
    skip: !selectedYearId,
    fetchPolicy: "network-only"
  });
  const {data: assessments, loading: assessmentsLoading} = useQuery(getAssessmentsBySchoolYearId, {
    variables: {
      schoolYearId: selectedYearId
    },
    skip: !selectedYearId,
    fetchPolicy: "network-only"
  });
  const {loading: studentAssessmentLoading, data: studentAssessmentsData} = useQuery(getStudentAssessmentsByStudentId, {
    variables: {
      studentId: Number(studentId)
    },
    skip: Number(studentId) ? false : true,
    fetchPolicy: "network-only"
  });
  const {data: withdrawalStatusData} = useQuery(getWithdrawalStatusQuery, {
    variables: {
      filter: {
        StudentId: Number(studentId)
      }
    },
    fetchPolicy: "network-only"
  });
  const SoEitems = useMemo(() => {
    if (schoolPartnerData?.getSchoolsOfEnrollmentByRegion?.length) {
      const partnerList = schoolPartnerData.getSchoolsOfEnrollmentByRegion.filter((el) => el.schoolEnrollmentYear.some((year) => year.active)).map((item) => ({
        value: String(item.id || soeLowStatus.UNASSIGNED),
        label: String(item.name),
        abb: String(item.abbreviation)
      }));
      partnerList.push({
        value: soeLowStatus.UNASSIGNED,
        label: soeUppStatus.UNASSIGNED,
        abb: soeUppStatus.UNASSIGNED
      });
      return partnerList;
    } else {
      return [];
    }
  }, [schoolPartnerData]);
  const [withdrawalStatus, setWithdrawalStatus] = useState();
  const [userInfo, setUserInfo] = useState();
  const [preferedFirstName, setPreferredFirstName] = useState(EMPTY_STRING);
  const [preferedLastName, setPreferredLastName] = useState(EMPTY_STRING);
  const [legalFirstName, setLegalFirstName] = useState(EMPTY_STRING);
  const [legalMiddleName, setLegalMiddleName] = useState(EMPTY_STRING);
  const [legalLastName, setLegalLastName] = useState(EMPTY_STRING);
  const [email, setEmail] = useState(EMPTY_STRING);
  const [parentEmail, setParentEmail] = useState(EMPTY_STRING);
  const [gender, setGender] = useState(EMPTY_STRING);
  const [city, setCity] = useState(EMPTY_STRING);
  const [zip, setZip] = useState(EMPTY_STRING);
  const [street1, setStreet1] = useState(EMPTY_STRING);
  const [street2, setStreet2] = useState(EMPTY_STRING);
  const [state, setState] = useState(userInfo?.address?.state ? EMPTY_STRING : STATES_WITH_ABBREVIATION[applicationState]);
  const [gradeLevel, setGradeLevel] = useState(EMPTY_STRING);
  const [packets, setPackets] = useState([]);
  const [openNotes, setOpenNotes] = useState(false);
  const [showPacketModal, setShowPacketModal] = useState(false);
  const [packetID, setPacketID] = useState(0);
  const [tempSoE, setTempSoE] = useState(soeLowStatus.UNASSIGNED.toString());
  const [modalAssign, setModalAssign] = useState(false);
  const [schoolPartnerId, setSchoolPartnerId] = useState(soeLowStatus.UNASSIGNED.toString());
  const [ableToEdit, setAbleToEdit] = useState(false);
  const [clickedEvent, setClickedEvent] = useState();
  const [counties, setCounties] = useState([]);
  const [schoolDistricts, setSchoolDistricts] = useState([]);
  const [county, setCounty] = useState(0);
  const [school_district, setSchoolDistrict] = useState(EMPTY_STRING);
  const [firstSemesterSchedule, setFirstSemesterSchedule] = useState();
  const [submitTestingPref] = useMutation(updateStudentAssessmentMutation);
  const [showHomeroomResourceModal, setShowHomeroomResourceModal] = useState(false);
  const handlePacket = () => {
    if (packets.length <= 0)
      return;
    setPacketID(packets[packets.length - 1].packet_id);
    setShowPacketModal(true);
  };
  const genderItems = [
    {
      label: "Male",
      value: "Male"
    },
    {
      label: "Female",
      value: "Female"
    }
  ];
  useEffect(() => {
    const soe = student?.currentSoe?.find((e) => String(e.school_year_id) === String(selectedYearId));
    setSchoolPartnerId(String(soe?.school_partner_id || soeLowStatus.UNASSIGNED));
  }, [selectedYearId, student?.currentSoe]);
  useEffect(() => {
    const schoolYears = regionData?.region?.SchoolYears || [];
    let special_ed_options = EMPTY_STRING;
    schoolYears.filter((item) => new Date(item.date_begin) <= new Date() && new Date(item.date_end) >= new Date()).map((item) => {
      if (item.special_ed === true && item.special_ed_options !== EMPTY_STRING && item.special_ed_options !== null && special_ed_options === EMPTY_STRING)
        special_ed_options = item.special_ed_options;
    });
    if (special_ed_options === EMPTY_STRING)
      setSpecialEdOptions([]);
    else
      setSpecialEdOptions(special_ed_options.split(Separator.COMMA));
  }, [regionData, selectedYearId]);
  const setSOE = useCallback(async (newSchoolPartnerId, isTransfer = false, generateTransferForm = false) => {
    setLoading(true);
    try {
      if (!isNil(newSchoolPartnerId) && newSchoolPartnerId !== schoolPartnerId) {
        const studentData = {
          studentId: studentStatus.student_id,
          name: `${student?.person.last_name}, ${student?.person.first_name}`
        };
        if (isTransfer) {
          await transferStudentsSOE(newSchoolPartnerId, studentStatus.school_year_id, [String(studentStatus.student_id)], [studentData], generateTransferForm);
        } else {
          await assignStudentsSOE(newSchoolPartnerId, studentStatus.school_year_id, [String(studentStatus.student_id)], [studentData]);
        }
      }
    } catch (error) {
      console.error("Error assigning an SOE to student");
      console.error(error);
    }
    setSchoolPartnerId(newSchoolPartnerId);
    setLoading(false);
  }, [schoolPartnerId, studentStatus, student, assignStudentsSOE, transferStudentsSOE]);
  const handleChangeSoE = useCallback((e) => {
    if (schoolPartnerId === soeLowStatus.UNASSIGNED || e.target.value === schoolPartnerId) {
      setSOE(e.target.value);
    } else {
      setTempSoE(e.target.value);
      setModalAssign(true);
    }
  }, [schoolPartnerId, setSOE]);
  const handleAssignOrTransfer = async (option) => {
    const generateTransferForm = option === SoETransferOption.ASSIGN_AND_FORM;
    const isTransfer = option !== SoETransferOption.UNASSSIGN;
    setSOE(tempSoE, isTransfer, generateTransferForm);
    setModalAssign(false);
  };
  const studentAseessOption = (assessment_id) => {
    const studentAsses = studentAssessments.find((ass) => ass.assessmentId === assessment_id);
    if (!studentAsses) {
      return EMPTY_STRING;
    }
    return studentAsses.optionId;
  };
  const handleTestPreferenceChange = async (assessId, optionId) => {
    let updatedValue = [...studentAssessments];
    if (studentAssessments.find((assement) => assement.assessmentId === assessId)) {
      updatedValue = studentAssessments.map((item) => {
        if (item.assessmentId === assessId) {
          return {
            ...item,
            optionId
          };
        } else {
          return item;
        }
      });
    } else {
      if (epic3346bug5434) {
        studentAssessments.push({
          assessmentId: assessId,
          optionId,
          assessmentOptionOutText: ""
        });
        updatedValue = [...studentAssessments];
      } else {
        const response = await submitTestingPref({
          variables: {
            studentAssessmentInput: {
              AssessmentId: Number(assessId),
              OptionId: Number(optionId),
              StudentId: Number(studentId),
              out_text: ""
            }
          }
        });
        if (response && response.data.createOrUpdateStudentAssessment) {
          const studentAssessment = response.data.createOrUpdateStudentAssessment;
          const newAssesment = {
            optionId,
            assessmentId: assessId,
            assessmentOptionOutText: EMPTY_STRING,
            assessmentOptionId: studentAssessment?.assessment_option_id
          };
          updatedValue.push(newAssesment);
        }
      }
    }
    setIsChanged(true);
    setStudentAssessments(updatedValue);
    const testString = updatedValue.map((i) => {
      return {
        assessmentId: i.assessmentId,
        assessmentOptionId: i.assessmentOptionId,
        optionId: i.optionId
      };
    });
    setStudentStatus({...studentStatus, ...{testing_preference: JSON.stringify(testString)}});
  };
  const isVisibleTestPreference = (grades) => {
    if (!gradeLevel) {
      return false;
    }
    const availableGrades = grades.split(Separator.COMMA);
    return availableGrades.indexOf(gradeLevel) !== -1;
  };
  const visibleAssessmentItems = (list) => {
    const result = [];
    list.map((item) => {
      if (isVisibleTestPreference(item?.grades)) {
        result.push(item);
      }
    });
    return result;
  };
  const {loading: countyLoading, data: countyData} = useQuery(getCountiesByRegionId, {
    variables: {regionId: Number(me?.selectedRegionId)},
    fetchPolicy: "network-only"
  });
  const {loading: schoolDistrictsDataLoading, data: schoolDistrictsData} = useQuery(getSchoolDistrictsByRegionId, {
    variables: {
      regionId: Number(me?.selectedRegionId)
    },
    skip: Number(me?.selectedRegionId) ? false : true,
    fetchPolicy: "network-only"
  });
  useEffect(() => {
    if (!studentAssessmentLoading && studentAssessmentsData?.getStudentAssessmentsByStudentId) {
      setStudentAssessments(studentAssessmentsData?.getStudentAssessmentsByStudentId?.map((assessment) => ({
        assessmentId: assessment?.AssessmentId,
        assessmentOptionId: assessment?.assessment_option_id,
        optionId: assessment?.OptionId,
        assessmentOptionOutText: assessment?.out_text
      })));
    }
  }, [studentAssessmentLoading, studentAssessmentsData]);
  useEffect(() => {
    if (withdrawalStatusData && withdrawalStatusData.withdrawalStatus.error === false) {
      if (withdrawalStatusData.withdrawalStatus.results.length > 0) {
        setWithdrawalStatus(withdrawalStatusData.withdrawalStatus.results[0]);
      } else {
        setWithdrawalStatus(void 0);
      }
    }
  }, [withdrawalStatusData]);
  useEffect(() => {
    if (!assessmentsLoading && assessments?.getAssessmentsBySchoolYearId) {
      const items = assessments?.getAssessmentsBySchoolYearId;
      setAssessmentItems(items.map((item) => ({...item, assessment_id: Number(item.assessment_id)})));
    } else {
      setAssessmentItems([]);
    }
  }, [assessments, assessmentsLoading]);
  useEffect(() => {
    if (userInfo)
      setStudentPerson(userInfo);
  }, [userInfo]);
  useEffect(() => {
    if (student) {
      const stateSelected = student.person.address.state || applicationState;
      const activeStatus = student?.status.find((studentStatusItem) => studentStatusItem.school_year_id === Number(currentSchoolYear?.school_year_id)) || student?.status.find((studentStatusItem) => studentStatusItem.school_year_id === Number(nextSchoolYear?.school_year_id));
      const activeGradeLevel = student.grade_levels.find((gradeLevel2) => Number(gradeLevel2.school_year_id) === Number(activeStatus?.school_year_id));
      setEmail(student.person.email ?? EMPTY_STRING);
      setParentEmail(student?.parent?.person?.email);
      setPreferredFirstName(student.person.preferred_first_name);
      setPreferredLastName(student.person.preferred_last_name);
      setLegalFirstName(student.person.first_name);
      setLegalLastName(student.person.last_name);
      setLegalMiddleName(student.person.middle_name ?? EMPTY_STRING);
      setGender(student.person.gender);
      setState(STATES_WITH_ABBREVIATION[stateSelected] || stateSelected);
      if (epic3537bug4794) {
        setCity(addressInfo?.city);
        setStreet1(addressInfo?.street);
        setStreet2(addressInfo?.street2);
        setZip(addressInfo?.zip);
        setCounty(addressInfo?.county_id || 0);
        setSchoolDistrict(addressInfo?.school_district || EMPTY_STRING);
      } else {
        setCity(student.person.address.city);
        setStreet1(student.person.address.street);
        setStreet2(student.person.address.street2);
        setZip(student.person.address.zip);
        setCounty(student.person.address.county_id || 0);
        setSchoolDistrict(student.person.address.school_district || EMPTY_STRING);
      }
      setUserInfo(student.person);
      setPackets(student.packets ?? []);
      if (student.grade_levels && activeGradeLevel) {
        setGradeLevel(activeGradeLevel.grade_level);
      }
      setStudentStatus({
        student_id: Number(studentId ?? 0),
        special_ed: selectedSchoolYear?.special_ed ? student.special_ed : void 0,
        diploma_seeking: selectedSchoolYear?.diploma_seeking ? student.diploma_seeking : void 0,
        testing_preference: student.testing_preference,
        status: activeStatus?.status ?? StudentStatus.INACTIVE,
        date: activeStatus?.date_updated || EMPTY_STRING,
        school_year_id: activeStatus?.school_year_id ?? 0,
        birth: student?.person.date_of_birth
      });
      setOriginStudentStatus(activeStatus?.status);
    }
  }, [student, currentSchoolYear, nextSchoolYear, addressInfo]);
  useEffect(() => {
    if (!countyLoading && countyData?.getCounties) {
      setCounties(countyData.getCounties.map((v) => {
        return {label: v.county_name, value: Number(v.id)};
      }));
    }
  }, [countyData]);
  useEffect(() => {
    if (!schoolDistrictsDataLoading && schoolDistrictsData?.schoolDistrict.length > 0) {
      setSchoolDistricts(schoolDistrictsData?.schoolDistrict.map((d) => {
        return {label: d.school_district_name, value: d.school_district_name};
      }));
    }
  }, [schoolDistrictsDataLoading]);
  useEffect(() => {
    if (ableToEdit === true)
      clickedEvent.target.focus();
  }, [ableToEdit]);
  const setFocused = (event) => {
    if (!ableToEdit || clickedEvent.target !== event.target) {
      event.preventDefault();
      event.target.blur();
      setClickedEvent(event);
    }
  };
  const setBlured = () => {
    setAbleToEdit(false);
  };
  const closeReimbursementModal = () => setShowReimbursementModal(false);
  const toggleHomeroomResourceModal = () => setShowHomeroomResourceModal(!showHomeroomResourceModal);
  const transformFields = (data) => {
    if (!Array.isArray(data)) {
      return [];
    }
    return data.map((item) => {
      return {
        status: item.status,
        scheduleId: item.schedule_id,
        lastModified: item.last_modified,
        isSecondSemester: item.is_second_semester,
        dateSubmitted: item.date_submitted,
        dateAccepted: item.date_accepted,
        currentSubmission: item.current_submission,
        studentId: item.StudentId
      };
    });
  };
  useEffect(() => {
    if (scheduleByStudent && scheduleByStudent.scheduleByStudentId.length) {
      const transformedData = transformFields([scheduleByStudent.scheduleByStudentId.at(-1)]);
      const secondSemesterSchedules = transformedData.filter((schedule) => schedule.isSecondSemester) || [];
      const selectedSchedule = secondSemesterSchedules.length > 0 ? secondSemesterSchedules[0] : transformedData[0];
      setActualSchedule(selectedSchedule);
      const firstSchedule = transformedData.filter((schedule) => !schedule.isSecondSemester);
      setFirstSemesterSchedule(firstSchedule.length > 0 ? firstSchedule[0] : transformedData[0]);
    } else if (scheduleByStudent && !scheduleByStudent.scheduleByStudentId.length) {
      setActualSchedule({});
    }
  }, [scheduleByStudent]);
  const shouldHaveWidth = scheduleByStudent && scheduleByStudent?.scheduleByStudentId.length > 0 && (scheduleByStudent.scheduleByStudentId.at(-1).status === ScheduleStatus.UPDATES_REQUESTED || scheduleByStudent.scheduleByStudentId.at(-1).status === ScheduleStatus.UPDATES_REQUIRED);
  return /* @__PURE__ */ React.createElement(Box, {
    sx: {
      marginTop: "24px"
    }
  }, /* @__PURE__ */ React.createElement(Box, {
    sx: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-around"
    }
  }, /* @__PURE__ */ React.createElement(Button, {
    sx: {
      background: MthColor.BUTTON_LINEAR_GRADIENT,
      textTransform: "none",
      color: "white",
      marginRight: 2,
      width: "264px",
      height: "34px",
      borderRadius: 2
    },
    onClick: () => setShowReimbursementModal(true)
  }, MthTitle.REIMBURSEMENTS), /* @__PURE__ */ React.createElement(Button, {
    onClick: toggleHomeroomResourceModal,
    sx: {
      background: MthColor.BUTTON_LINEAR_GRADIENT,
      textTransform: "none",
      color: "white",
      marginRight: 2,
      width: "264px",
      height: "34px",
      borderRadius: 2
    }
  }, "Homeroom Resources"), /* @__PURE__ */ React.createElement(Button, {
    sx: {
      background: MthColor.BUTTON_LINEAR_GRADIENT,
      textTransform: "none",
      color: "white",
      marginRight: 2,
      width: "264px",
      height: "34px",
      borderRadius: 2
    },
    onClick: () => {
      window.open(`${MthRoute.GRADEBOOK}/${studentId}`, "_blank", "noreferrer");
    }
  }, "Homeroom (Sample Teacher)")), /* @__PURE__ */ React.createElement(Grid, {
    container: true,
    marginTop: 4,
    columnSpacing: 4,
    rowSpacing: 3
  }, /* @__PURE__ */ React.createElement(Grid, {
    item: true,
    xs: 4
  }, /* @__PURE__ */ React.createElement(Box, {
    sx: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center"
    }
  }, /* @__PURE__ */ React.createElement(Avatar, {
    alt: preferedFirstName ?? legalFirstName,
    src: EMPTY_STRING,
    variant: "rounded",
    style: {height: "127px", width: "127px", marginRight: "12px", fontSize: "3rem"}
  }, getStringInitialLetter(preferedFirstName ?? legalFirstName)), /* @__PURE__ */ React.createElement(Box, {
    sx: {
      textAlign: "left"
    }
  }, /* @__PURE__ */ React.createElement(Title, {
    textAlign: "left",
    fontWeight: "500",
    color: MthColor.MTHBLUE
  }, legalFirstName, " ", legalLastName), /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(Select, {
    IconComponent: KeyboardArrowDown,
    value: gradeLevel,
    sx: {...classes.selectSoE, color: MthColor.SYSTEM_01, fontWeight: "600", fontSize: "16px"},
    onChange: (e) => {
      setGradeLevel(e.target.value);
      setIsChanged(true);
      setStudentStatus({...studentStatus, ...{grade_level: e.target.value}});
    },
    autoWidth: true
  }, GRADES.map((grade) => /* @__PURE__ */ React.createElement(MenuItem, {
    key: grade,
    value: grade
  }, expandAbbreviationSingleGrade(String(grade)))))), /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(Select, {
    IconComponent: KeyboardArrowDown,
    sx: {...classes.selectSoE, color: MthColor.SYSTEM_01, fontWeight: "600", fontSize: "16px"},
    value: schoolPartnerId,
    onChange: handleChangeSoE,
    autoWidth: true,
    disabled: epic2209Story5188 && (isNil(originStudentStatus) || isInEligibleStudentStatus(schoolYearToShow?.grades, student?.grade_levels?.at(-1), schoolYearToShow?.date_begin) || ![StudentStatus.ACTIVE, StudentStatus.PENDING].includes(originStudentStatus))
  }, SoEitems.map((el) => /* @__PURE__ */ React.createElement(MenuItem, {
    value: el.value,
    key: el.value
  }, el.label))))))), /* @__PURE__ */ React.createElement(Grid, {
    item: true,
    container: true,
    xs: 4
  }, /* @__PURE__ */ React.createElement(Grid, {
    item: true,
    xs: 3,
    sx: {alignItems: "center", display: "flex", fontWeight: "700"}
  }, "Packet"), /* @__PURE__ */ React.createElement(Grid, {
    item: true,
    xs: 9,
    sx: {alignItems: "center", display: "flex"}
  }, /* @__PURE__ */ React.createElement(Button, {
    sx: {
      background: MthColor.BUTTON_LINEAR_GRADIENT,
      textTransform: "none",
      color: "white",
      marginRight: 2,
      width: shouldHaveWidth ? "215px" : "198px",
      height: "29px",
      borderRadius: 2,
      fontWeight: "500"
    },
    onClick: handlePacket
  }, packets.length ? `${packets[packets.length - 1].status}` : EMPTY_STRING, packets.length && packets[packets.length - 1].status === PacketStatus.ACCEPTED ? ` ${dayjs(packets[packets.length - 1].date_accepted).format("MM/DD/YY")}` : EMPTY_STRING, packets.length && packets[packets.length - 1].status === PacketStatus.SUBMITTED ? ` ${dayjs(packets[packets.length - 1].date_submitted).format("MM/DD/YY")}` : EMPTY_STRING, packets.length && packets[packets.length - 1].status === PacketStatus.RESUBMITTED ? ` ${dayjs(packets[packets.length - 1].date_submitted).format("MM/DD/YY")}` : EMPTY_STRING)), /* @__PURE__ */ React.createElement(Grid, {
    item: true,
    xs: 3,
    sx: {alignItems: "center", display: "flex", fontWeight: "700"}
  }, "Schedule"), /* @__PURE__ */ React.createElement(Grid, {
    item: true,
    xs: 9,
    sx: {alignItems: "center", display: "flex"}
  }, /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(Button, {
    sx: {
      background: MthColor.BUTTON_LINEAR_GRADIENT,
      textTransform: "none",
      color: "white",
      marginRight: 2,
      width: shouldHaveWidth ? "215px" : "198px",
      height: "29px",
      borderRadius: 2,
      fontWeight: "500"
    },
    onClick: () => {
      window.open(`/enrollment/enrollment-schedule/${studentId}`, "_blank");
    }
  }, actualSchedule?.status ? actualSchedule.status : null, actualSchedule?.status && actualSchedule?.status !== ScheduleStatus.NOT_SUBMITTED && actualSchedule?.lastModified ? ` ${formatDateByPattern(utcToTimezoned(new Date(actualSchedule.lastModified), timeZone), datePatterns.ZMONTH_ZDAY_2DYEAR)}` : null), /* @__PURE__ */ React.createElement("br", null), /* @__PURE__ */ React.createElement(Paragraph, {
    sx: {color: MthColor.MTHBLUE, textAlign: "Center"}
  }, actualSchedule && actualSchedule.status && actualSchedule.status !== "Not Submitted" && (firstSemesterSchedule?.dateSubmitted ? `${MthTitle.ORIGINALLY_SUBMITTED} ${formatDateByPattern(utcToTimezoned(new Date(firstSemesterSchedule.dateSubmitted), timeZone), datePatterns.ZMONTH_ZDAY_2DYEAR)}` : EMPTY_STRING))))), /* @__PURE__ */ React.createElement(Grid, {
    item: true,
    container: true,
    xs: 4
  }, /* @__PURE__ */ React.createElement(Grid, {
    item: true,
    xs: 12,
    sx: {alignItems: "center", display: "flex"}
  }, /* @__PURE__ */ React.createElement(Subtitle, {
    textAlign: "left",
    fontWeight: "700",
    color: MthColor.GRAY,
    sx: {marginLeft: 3}
  }, `${MthTitle.FIRST_SEMESTER} # of Zeros`)), /* @__PURE__ */ React.createElement(Grid, {
    item: true,
    xs: 12,
    sx: {alignItems: "center", display: "flex"}
  }, /* @__PURE__ */ React.createElement(Subtitle, {
    textAlign: "left",
    fontWeight: "700",
    color: MthColor.GRAY,
    sx: {marginLeft: 3}
  }, `${MthTitle.SECOND_SEMESTER} # of Zeros`))), /* @__PURE__ */ React.createElement(Grid, {
    item: true,
    xs: 12,
    sx: {display: "flex", justifyContent: "flex-end"}
  }, /* @__PURE__ */ React.createElement(Grid, {
    item: true,
    container: true,
    xs: 4
  }, /* @__PURE__ */ React.createElement(Grid, {
    item: true,
    xs: 12,
    sx: {alignItems: "center", display: "flex"}
  }, /* @__PURE__ */ React.createElement(Button, {
    sx: {
      background: MthColor.BUTTON_LINEAR_GRADIENT,
      textTransform: "none",
      color: "white",
      marginLeft: 5,
      width: "198px",
      height: "29px",
      borderRadius: 2,
      fontWeight: "500"
    },
    onClick: () => setOpenNotes(true)
  }, "Notes and Inventions"))))), /* @__PURE__ */ React.createElement(Grid, {
    item: true,
    marginTop: 5,
    xs: 12
  }, /* @__PURE__ */ React.createElement(StudentFilters, {
    student,
    setStudentStatusData: setStudentStatus,
    studentStatusData: studentStatus,
    originStudentStatus,
    withdrawalStatus,
    setWithdrawalStatus,
    setIsChanged,
    specialEdOptions,
    schoolYears: studentSchoolYears,
    currentSchoolYear,
    nextSchoolYear,
    schoolYearToShowProp: schoolYearToShow
  })), /* @__PURE__ */ React.createElement(Grid, {
    container: true,
    marginTop: 1,
    columnSpacing: 4,
    rowSpacing: 3
  }, /* @__PURE__ */ React.createElement(Grid, {
    item: true,
    xs: 3
  }, /* @__PURE__ */ React.createElement(Paragraph, {
    size: TEXT_SIZE.LARGE,
    textAlign: "left",
    sx: classes.label
  }, "Preferred First Name"), /* @__PURE__ */ React.createElement(TextField, {
    sx: classes.text,
    size: "small",
    variant: "outlined",
    fullWidth: true,
    value: preferedFirstName || legalFirstName,
    onChange: (e) => {
      setPreferredFirstName(e.target.value);
      setUserInfo({...userInfo, ...{preferred_first_name: e.target.value}});
    }
  })), /* @__PURE__ */ React.createElement(Grid, {
    item: true,
    xs: 3
  }, /* @__PURE__ */ React.createElement(Paragraph, {
    size: "medium",
    textAlign: "left",
    sx: classes.label
  }, "Preferred Last Name"), /* @__PURE__ */ React.createElement(TextField, {
    sx: classes.text,
    size: "small",
    variant: "outlined",
    fullWidth: true,
    value: preferedLastName || legalLastName,
    onChange: (e) => {
      setPreferredLastName(e.target.value);
      setUserInfo({...userInfo, ...{preferred_last_name: e.target.value}});
    }
  })), /* @__PURE__ */ React.createElement(Grid, {
    item: true,
    xs: 2
  }, /* @__PURE__ */ React.createElement(Paragraph, {
    size: "medium",
    textAlign: "left",
    sx: classes.label
  }, "Gender"), /* @__PURE__ */ React.createElement(DropDown, {
    size: "medium",
    sx: classes.dropdown,
    dropDownItems: genderItems,
    defaultValue: gender,
    placeholder: gender,
    setParentValue: (e) => {
      setGender(e);
      setUserInfo({...userInfo, ...{gender: e}});
    }
  })), /* @__PURE__ */ React.createElement(Grid, {
    item: true,
    xs: 1
  }), /* @__PURE__ */ React.createElement(Grid, {
    item: true,
    xs: 3
  }, /* @__PURE__ */ React.createElement(Paragraph, {
    size: "medium",
    sx: classes.label
  }, "Testing Preference"), visibleAssessmentItems(assessmentItems).map((assess) => /* @__PURE__ */ React.createElement(Grid, {
    item: true,
    container: true,
    key: assess.assessment_id
  }, /* @__PURE__ */ React.createElement(Grid, {
    item: true,
    xs: 5,
    sx: {alignItems: "center", display: "flex", fontWeight: "700"}
  }, /* @__PURE__ */ React.createElement(Subtitle, {
    sx: {fontWeight: "700", fontSize: "12px", color: MthColor.SYSTEM_01}
  }, assess.test_name)), /* @__PURE__ */ React.createElement(Grid, {
    item: true,
    xs: 7,
    sx: {alignItems: "center", display: "flex"}
  }, /* @__PURE__ */ React.createElement(Select, {
    value: studentAseessOption(assess.assessment_id),
    IconComponent: KeyboardArrowDown,
    sx: {...classes.selectTP, ...classes.label, marginBottom: "0px"},
    onChange: (e) => handleTestPreferenceChange(assess.assessment_id, Number(e.target.value))
  }, assess.Options.map((el) => /* @__PURE__ */ React.createElement(MenuItem, {
    value: el.option_id,
    key: el.option_id
  }, el.label)))))))), /* @__PURE__ */ React.createElement(Grid, {
    container: true,
    marginTop: 1,
    columnSpacing: 4,
    rowSpacing: 3
  }, /* @__PURE__ */ React.createElement(Grid, {
    item: true,
    xs: 3
  }, /* @__PURE__ */ React.createElement(Paragraph, {
    size: "medium",
    textAlign: "left",
    sx: classes.label
  }, "Legal First Name"), /* @__PURE__ */ React.createElement(TextField, {
    sx: classes.text,
    size: "small",
    variant: "outlined",
    fullWidth: true,
    value: legalFirstName,
    onChange: (e) => {
      setLegalFirstName(e.target.value);
      setUserInfo({...userInfo, ...{first_name: e.target.value}});
    }
  })), /* @__PURE__ */ React.createElement(Grid, {
    item: true,
    xs: 3
  }, /* @__PURE__ */ React.createElement(Paragraph, {
    size: "medium",
    textAlign: "left",
    sx: classes.label
  }, "Legal Middle Name"), /* @__PURE__ */ React.createElement(TextField, {
    sx: classes.text,
    size: "small",
    variant: "outlined",
    fullWidth: true,
    value: legalMiddleName,
    onChange: (e) => {
      setLegalMiddleName(e.target.value);
      setUserInfo({...userInfo, ...{middle_name: e.target.value}});
    }
  })), /* @__PURE__ */ React.createElement(Grid, {
    item: true,
    xs: 3
  }, /* @__PURE__ */ React.createElement(Paragraph, {
    size: "medium",
    textAlign: "left",
    sx: classes.label
  }, "Legal Last Name"), /* @__PURE__ */ React.createElement(TextField, {
    sx: classes.text,
    size: "small",
    variant: "outlined",
    fullWidth: true,
    value: legalLastName,
    onChange: (e) => {
      setLegalLastName(e.target.value);
      setUserInfo({...userInfo, ...{last_name: e.target.value}});
    }
  })), /* @__PURE__ */ React.createElement(Grid, {
    item: true,
    xs: 3
  }, /* @__PURE__ */ React.createElement(Paragraph, {
    size: "medium",
    textAlign: "left",
    sx: classes.label
  }, "Student Email"), /* @__PURE__ */ React.createElement(TextField, {
    sx: classes.text,
    size: "small",
    variant: "outlined",
    fullWidth: true,
    value: email,
    onChange: (e) => {
      setEmail(e.target.value);
      setUserInfo({...userInfo, ...{email: e.target.value}});
    }
  }))), /* @__PURE__ */ React.createElement(Grid, {
    container: true,
    marginTop: 1,
    columnSpacing: 4,
    rowSpacing: 3
  }, /* @__PURE__ */ React.createElement(Grid, {
    item: true,
    xs: 3
  }, /* @__PURE__ */ React.createElement(Paragraph, {
    size: "medium",
    textAlign: "left",
    sx: DISABLE_STUDENT_ADDRESS ? classes.labelDisabled : classes.label
  }, "Parent Phone"), /* @__PURE__ */ React.createElement(TextField, {
    disabled: DISABLE_STUDENT_ADDRESS,
    sx: classes.text,
    size: "small",
    variant: "outlined",
    fullWidth: true,
    value: phoneInfo?.number,
    onChange: (e) => {
      const formattedNumber = e.target.value.replace(/\D+/g, EMPTY_STRING).slice(0, 10);
      setPhoneInfo({...phoneInfo, number: formattedNumber});
    }
  })), /* @__PURE__ */ React.createElement(Grid, {
    item: true,
    xs: 3
  }, /* @__PURE__ */ React.createElement(Paragraph, {
    size: "medium",
    textAlign: "left",
    sx: DISABLE_STUDENT_ADDRESS ? classes.labelDisabled : classes.label
  }, "Parent Email"), /* @__PURE__ */ React.createElement(TextField, {
    disabled: DISABLE_STUDENT_ADDRESS,
    sx: classes.text,
    size: "small",
    variant: "outlined",
    fullWidth: true,
    value: parentEmail
  })), /* @__PURE__ */ React.createElement(Grid, {
    item: true,
    xs: 6
  })), /* @__PURE__ */ React.createElement(Grid, {
    container: true,
    marginTop: 1,
    columnSpacing: 4,
    rowSpacing: 3
  }, /* @__PURE__ */ React.createElement(Grid, {
    item: true,
    xs: 6
  }, /* @__PURE__ */ React.createElement(Paragraph, {
    size: "medium",
    textAlign: "left",
    sx: DISABLE_STUDENT_ADDRESS ? classes.labelDisabled : classes.label
  }, "Address Line 1"), /* @__PURE__ */ React.createElement(TextField, {
    disabled: DISABLE_STUDENT_ADDRESS,
    sx: classes.text,
    size: "small",
    variant: "outlined",
    fullWidth: true,
    value: street1,
    onFocus: (e) => {
      setFocused(e);
    },
    onBlur: () => {
      setBlured();
    },
    onChange: () => {
    }
  })), /* @__PURE__ */ React.createElement(Grid, {
    item: true,
    xs: 6
  }, /* @__PURE__ */ React.createElement(Paragraph, {
    size: "medium",
    textAlign: "left",
    sx: DISABLE_STUDENT_ADDRESS ? classes.labelDisabled : classes.label
  }, "Address Line 2"), /* @__PURE__ */ React.createElement(TextField, {
    disabled: DISABLE_STUDENT_ADDRESS,
    sx: classes.text,
    size: "small",
    variant: "outlined",
    fullWidth: true,
    value: street2,
    onFocus: (e) => {
      setFocused(e);
    },
    onBlur: () => {
      setBlured();
    },
    onChange: () => {
    }
  })), /* @__PURE__ */ React.createElement(Grid, {
    item: true,
    xs: 6
  }, /* @__PURE__ */ React.createElement(Paragraph, {
    size: "medium",
    textAlign: "left",
    sx: DISABLE_STUDENT_ADDRESS ? classes.labelDisabled : classes.label
  }, "Citys"), /* @__PURE__ */ React.createElement(TextField, {
    disabled: DISABLE_STUDENT_ADDRESS,
    sx: classes.text,
    size: "small",
    variant: "outlined",
    fullWidth: true,
    value: city,
    onFocus: (e) => {
      setFocused(e);
    },
    onBlur: () => {
      setBlured();
    },
    onChange: () => {
    }
  })), /* @__PURE__ */ React.createElement(Grid, {
    item: true,
    xs: 3
  }, /* @__PURE__ */ React.createElement(Paragraph, {
    size: "medium",
    textAlign: "left",
    sx: DISABLE_STUDENT_ADDRESS ? classes.labelDisabled : classes.label
  }, "State"), /* @__PURE__ */ React.createElement(DropDown, {
    disabled: DISABLE_STUDENT_ADDRESS,
    sx: classes.dropdown,
    dropDownItems: US_STATES,
    setParentValue: () => {
    },
    alternate: true,
    size: "medium",
    defaultValue: state || EMPTY_STRING
  })), /* @__PURE__ */ React.createElement(Grid, {
    item: true,
    xs: 3
  }, /* @__PURE__ */ React.createElement(Paragraph, {
    size: "medium",
    textAlign: "left",
    sx: DISABLE_STUDENT_ADDRESS ? classes.labelDisabled : classes.label
  }, "Zip"), /* @__PURE__ */ React.createElement(TextField, {
    disabled: DISABLE_STUDENT_ADDRESS,
    sx: classes.text,
    size: "small",
    variant: "outlined",
    fullWidth: true,
    value: zip,
    onFocus: (e) => {
      setFocused(e);
    },
    onBlur: () => {
      setBlured();
    },
    onChange: () => {
    }
  })), /* @__PURE__ */ React.createElement(Grid, {
    item: true,
    xs: 6
  }, /* @__PURE__ */ React.createElement(Paragraph, {
    size: "medium",
    textAlign: "left",
    sx: DISABLE_STUDENT_ADDRESS ? classes.labelDisabled : classes.label
  }, "County"), /* @__PURE__ */ React.createElement(DropDown, {
    disabled: DISABLE_STUDENT_ADDRESS,
    size: "medium",
    sx: classes.dropdown,
    dropDownItems: counties,
    defaultValue: county,
    placeholder: "Select County",
    auto: false,
    setParentValue: () => {
    }
  })), /* @__PURE__ */ React.createElement(Grid, {
    item: true,
    xs: 6
  }, /* @__PURE__ */ React.createElement(Paragraph, {
    size: "medium",
    textAlign: "left",
    sx: DISABLE_STUDENT_ADDRESS ? classes.labelDisabled : classes.label
  }, "School District"), /* @__PURE__ */ React.createElement(DropDown, {
    disabled: DISABLE_STUDENT_ADDRESS,
    size: "medium",
    sx: classes.dropdown,
    dropDownItems: schoolDistricts,
    defaultValue: school_district,
    placeholder: "Select School District",
    auto: false,
    setParentValue: () => {
    }
  }))), epic275story5395 ? /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(Grid, {
    container: true,
    marginTop: 1,
    columnSpacing: 4,
    rowSpacing: 3
  }, /* @__PURE__ */ React.createElement(FamilyNotesAndStudentNotes, {
    subtittleStyle: classes.note,
    textFieldStyle: classes.text,
    notes,
    setNotes,
    familyNotesTestId: testIDs.familyNotes,
    studentNotesTestId: testIDs.studentNotes,
    studentNotes,
    setStudentNotes,
    isFamilyNotesEditable: false,
    isEditedByExternal
  })), /* @__PURE__ */ React.createElement(Grid, {
    container: true,
    marginTop: 1,
    columnSpacing: 4,
    rowSpacing: 3
  }, /* @__PURE__ */ React.createElement(Grid, {
    item: true,
    xs: 12,
    sx: {marginTop: "auto", textAlign: "right"}
  }, /* @__PURE__ */ React.createElement(Typography, {
    color: MthColor.DISABLED,
    sx: {fontWeight: "700"}
  }, USER_ID, " ", userInfo?.uniqueId)))) : /* @__PURE__ */ React.createElement(Grid, {
    container: true,
    marginTop: 1,
    columnSpacing: 4,
    rowSpacing: 3
  }, /* @__PURE__ */ React.createElement(Grid, {
    item: true,
    xs: 7
  }, /* @__PURE__ */ React.createElement(Subtitle, {
    fontWeight: "700",
    size: TEXT_SIZE.LARGE,
    textAlign: "left",
    sx: classes.note
  }, "Notes"), /* @__PURE__ */ React.createElement(TextField, {
    disabled: DISABLE_STUDENT_ADDRESS,
    sx: classes.text,
    size: "small",
    variant: "outlined",
    fullWidth: true,
    value: notes || EMPTY_STRING,
    onChange: (e) => {
      setNotes(e.target.value);
    },
    multiline: true,
    rows: 8
  })), /* @__PURE__ */ React.createElement(Grid, {
    item: true,
    xs: 5,
    sx: {marginTop: "auto", textAlign: "right"}
  }, /* @__PURE__ */ React.createElement(Typography, {
    color: MthColor.DISABLED,
    sx: {fontWeight: "700"}
  }, USER_ID, " ", userInfo?.uniqueId))), openNotes && /* @__PURE__ */ React.createElement(WarningModal, {
    handleModem: () => setOpenNotes(false),
    title: MthTitle.NOTES,
    subtitle: EMPTY_STRING,
    btntitle: MthTitle.CLOSE,
    handleSubmit: () => setOpenNotes(false),
    showIcon: false
  }), showPacketModal && /* @__PURE__ */ React.createElement(ProfilePacketModal, {
    handleModem: () => setShowPacketModal(false),
    packet_id: packetID,
    refetch: () => refetchStudent()
  }), modalAssign && /* @__PURE__ */ React.createElement(StudentSoeTransferModal, {
    unassign: tempSoE === soeLowStatus.UNASSIGNED,
    handleClose: () => setModalAssign(false),
    handleSubmit: (option) => handleAssignOrTransfer(option)
  }), showHomeroomResourceModal && /* @__PURE__ */ React.createElement(HomeroomResourceModal, {
    toggleHomeroomResourceModal,
    showHomeromResourceModal: showHomeroomResourceModal,
    schoolYearIdSelected: selectedYearId,
    studentIds: familyStudentIds
  }), showReimbursementModal && /* @__PURE__ */ React.createElement(ReimbursementModal, {
    closeModal: closeReimbursementModal,
    isFromParent: false,
    studentId: Number(studentId)
  }));
};
