import {gql} from "../../../_snowpack/pkg/@apollo/client.js";
export const getActiveSchoolYearsByRegionId = gql`
  query GetActiveSchoolYears($regionId: ID!) {
    getSchoolYearsByRegionId(region_id: $regionId) {
      date_begin
      date_end
      date_reg_close
      date_reg_open
      grades
      special_ed
      special_ed_options
      birth_date_cut
      school_year_id
      midyear_application
      midyear_application_open
      midyear_application_close
      diploma_seeking
      testing_preference
    }
  }
`;
export const GetSchoolsOfEnrollmentByRegionId = gql`
  query GetSchoolsOfEnrollmentByRegionId($schoolPartnerByRegionArgs: SchoolByRegionArgs!) {
    getSchoolsOfEnrollmentByRegionId(schoolPartnerByRegionArgs: $schoolPartnerByRegionArgs) {
      id
      name
      abbreviation
      schoolEnrollmentYear {
        id
        active
        schoolYear {
          school_year_id
        }
      }
    }
  }
`;
export const getMasterReportData = gql`
  query GetMasterReport(
    $regionId: Int
    $skip: Int
    $take: Int
    $filter: ReportFilters
    $sort: String
    $search: String
  ) {
    getMasterReport(region_id: $regionId, skip: $skip, take: $take, filter: $filter, sort: $sort, search: $search) {
      total
      results {
        city
        county
        date_begin
        date_end
        diploma_seeking
        gender
        grade_level
        midyear
        midyear_open
        midyear_close
        parent_email
        parent_unique_id
        parent_first_name
        parent_id
        parent_last_name
        parent_number
        parent_preferred_first_name
        parent_preferred_last_name
        school_district
        soe
        special_ed
        status
        status_date
        street
        street2
        student_dob
        student_email
        student_unique_id
        student_first_name
        student_id
        student_last_name
        student_preferred_first_name
        student_preferred_last_name
        zip
        special_ed_options
        intentToReenrollAnswer
        intentToReenrollReason
        intentToReenrollStatus
        SMSOptIn
      }
      page_total
    }
  }
`;
