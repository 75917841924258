export var EnrollmentQuestionsDisplayOptionLabel;
(function(EnrollmentQuestionsDisplayOptionLabel2) {
  EnrollmentQuestionsDisplayOptionLabel2["FULL_YEAR"] = "Full-year";
  EnrollmentQuestionsDisplayOptionLabel2["MID_YEAR"] = "Mid-year";
  EnrollmentQuestionsDisplayOptionLabel2["FULL_YEAR_AND_MID_YEAR"] = "Full-year and Mid-year";
})(EnrollmentQuestionsDisplayOptionLabel || (EnrollmentQuestionsDisplayOptionLabel = {}));
export var EnrollmentQuestionsDisplayOption;
(function(EnrollmentQuestionsDisplayOption2) {
  EnrollmentQuestionsDisplayOption2[EnrollmentQuestionsDisplayOption2["FULL_YEAR"] = 1] = "FULL_YEAR";
  EnrollmentQuestionsDisplayOption2[EnrollmentQuestionsDisplayOption2["MID_YEAR"] = 2] = "MID_YEAR";
  EnrollmentQuestionsDisplayOption2[EnrollmentQuestionsDisplayOption2["FULL_YEAR_AND_MID_YEAR"] = 3] = "FULL_YEAR_AND_MID_YEAR";
})(EnrollmentQuestionsDisplayOption || (EnrollmentQuestionsDisplayOption = {}));
