import * as __SNOWPACK_ENV__ from "../../_snowpack/env.js";
const env = __SNOWPACK_ENV__.SNOWPACK_PUBLIC_APP_STAGE;
export const exampleFeatureFlag = `epic_123_story_456_${env}`;
export const EPIC_1582_TASK_2739 = `epic-1582-task-2739-${env}`;
export const EPIC_401_STORY_2373 = `epic_401_story_2373_${env}`;
export const EPIC_1765_STORY_1865 = `epic_1765_story_1865_${env}`;
export const EPIC_258_STORY_1880 = `epic_258_story_1880_${env}`;
export const EPIC_4225_BUG_4520 = `release-epic-4225-bug-4520-${env}`;
export const EPIC_275_STORY_5395 = `release-epic-275-story-5395-${env}`;
export const EPIC_275_BUG_2046 = `release-epic-275-bug-2046-${env}`;
export const EPIC_1396_STORY_1578 = `epic_1396_story_1578_${env}`;
export const EPIC_1450 = `release-epic-1450-teacher-communication-announcements-${env}`;
export const EPIC_1450_STORY_1456 = `epic_1450_story_1456_${env}`;
export const EPIC_1450_STORY_1458 = `epic_1450_story_1458_${env}`;
export const EPIC_1450_STORY_1457 = `epic_1450_story_1457_${env}`;
export const EPIC_1450_STORY_1462 = `epic_1450_story_1462_${env}`;
export const EPIC_1450_TASK_5422 = `release-epic-1450-task-5422-${env}`;
export const EPIC_1847_BUG_3768 = `release-epic-1847-bug-3768-${env}`;
export const EPIC_1847_BUG_4293 = `release-epic-1847-bug-4293-${env}`;
export const EPIC_1847_BUG_4257 = `release-epic-1847-bug-4257-${env}`;
export const EPIC_1847_BUG_3930 = `release-epic-1847-bug-3930-${env}`;
export const EPIC_4225_BUG_4536 = `release-epic-4225-bug-4536-${env}`;
export const EPIC_3489_STORY_3405 = `epic_3489_story_3405_${env}`;
export const EPIC_3863_BUG_2265 = `release-epic-3863-bug-2265-${env}`;
export const EPIC_3863_BUG_4427 = `release-epic-3863-bug-4427-${env}`;
export const EPIC_3863_BUG_2999 = `release-epic-3863-bug-2999-${env}`;
export const EPIC_3346_STORY_2828 = `release-epic-3346-story-2828-${env}`;
export const EPIC_3346_STORY_4281 = `release-epic-3346-story-4281-${env}`;
export const EPIC_3346_BUG_5434 = `release-epic-3346-bug-5434-${env}`;
export const KILL_SWITCH_LL_DETAILED_REPORT = `kill-switch-job-reportforlearninglogfiles-${env}`;
export const EPIC_3725_BUG_5466 = `release-epic-3725-bug-5466-${env}`;
export const EPIC_3725_BUG_3440 = `release-epic-3725-bug-3440-${env}`;
export const EPIC_5462_BUG_5493 = `release-epic-5462-bug-5493-${env}`;
export const EPIC_4659_BUG_4976 = `release-epic-4659-bug-4976-${env}`;
export const EPIC_1582_STORY_4078 = `release-epic-1582-story-4078-${env}`;
export const EPIC_1582_BUG_4854 = `release-epic-1582-bug-4854-${env}`;
export const EPIC_3176_STORY_3472 = `release-epic-3176-bug-3472-${env}`;
export const EPIC_3489_STORY_3490 = `release-epic-3489-story-3490-${env}`;
export const EPIC_3489_STORY_4859 = `release-epic-3489-story-4859-${env}`;
export const EPIC_549_STORY_3389 = `release-epic-549-bug-3389-${env}`;
export const EPIC_1847_BUG_4313 = `release-epic-1847-bug-4313-${env}`;
export const EPIC_3733_BUG_4439 = `release-epic-3733-bug-4439-${env}`;
export const EPIC_3660_STORY_4377 = `release-epic-3660-story-4377-${env}`;
export const EPIC_3660_BUG_2691 = `release-epic-3660-bug-2691-${env}`;
export const EPIC_3662_STORY_4818 = `release-epic-3662-story-4818-${env}`;
export const OPERATIONAL_MAINTENANCE_SCREEN = `operational-maintenance-screen-${env}`;
export const OPERATIONAL_FORCE_REFRESH = `operational-Force-Frontend-Refresh-${env}`;
export const EPIC_3733_STORY_4380 = `release-epic-3733-story-4380-${env}`;
export const EPIC_3733_STORY_4143 = `release-epic-3733-story-4143-${env}`;
export const EPIC_3733_STORY_4142 = `release-epic-3733-story-4142-${env}`;
export const EPIC_3733_BUG_4599 = `release-epic-3733-bug-4599-${env}`;
export const EPIC_3733_BUG_4762 = `release-epic-3733-bug-4762-${env}`;
export const EPIC_3733_STORY_4792 = `release-epic-3733-story-4792-${env}`;
export const EPIC_3733_BUG_4987 = `release-epic-3733-bug-4987-${env}`;
export const EPIC_3658_BUG_4453 = `release-epic-3658-bug-4453-${env}`;
export const EPIC_3579_STORY_1428 = `release-epic-3579-story-1428-${env}`;
export const EPIC_3537_BUG_2359 = `release-epic-3537-bug-2359-${env}`;
export const EPIC_3537_BUG_4689 = `release-epic-3537-bug-4689-${env}`;
export const EPIC_3537_BUG_2116 = `release-epic-3537-bug-2116-${env}`;
export const EPIC_3537_BUG_4622 = `release-epic-3537-bug-4622-${env}`;
export const EPIC_3537_BUG_2087 = `release-epic-3537-bug-2087-${env}`;
export const EPIC_3537_STORY_5147 = `release-epic-3537-story-5147-${env}`;
export const EPIC_3537_STORY_4552 = `release-epic-3537-story-4552-${env}`;
export const EPIC_4318_STORY_4481 = `release-epic-4318-story-4481-${env}`;
export const EPIC_4318_STORY_4485 = `release-epic-4318-story-4485-${env}`;
export const EPIC_4318_STORY_4482 = `release-epic-4318-story-4482-${env}`;
export const EPIC_3665_BUG_4429 = `release-epic-3665-bug-4429-${env}`;
export const EPIC_3665_BUG_3699 = `release-epic-3665-bug-3699-${env}`;
export const EPIC_385_STORY_725 = `release-epic-385-story-752-${env}`;
export const EPIC_3152_BUG_3514 = `release-epic-3152-bug-3514-${env}`;
export const EPIC_2666_BUG_4717 = `release-epic-2666-bug-4717-${env}`;
export const EPIC_1766_STORY_4857 = `release-epic-1766-story-4857-${env}`;
export const EPIC_1766_BUG_5460 = `release-epic-1766-bug-5460-${env}`;
export const EPIC_4659_STORY_4709 = `release-epic-4659-story-4709-${env}`;
export const EPIC_4659_STORY_4896 = `release-epic-4659-story-4896-${env}`;
export const EPIC_3537_BUG_4794 = `release-epic-3537-bug-4794-${env}`;
export const EPIC_1766_STORY_5524 = `release-epic-1766-story-5524-${env}`;
export const EPIC_3864_STORY_4500 = `release-epic-3864-story-4500-${env}`;
export const EPIC_4601_STORY_4856 = `release-epic-4601-story-4856-${env}`;
export const EPIC_3489_STORY_3581 = `release-epic-3489-story-3581-${env}`;
export const EPIC_3664_STORY_1805 = `release-epic-3664-bug-1805-${env}`;
export const EPIC_4309_STORY_4339 = `release-epic-4309-story-4339-${env}`;
export const EPIC_3733_BUG_4892 = `release-epic-3733-bug-4892-${env}`;
export const EPIC_3660_STORY_3927 = `release-epic-3660-story-3927-${env}`;
export const EPIC_3698_BUG_4932 = `release-epic-3698-bug-4932-${env}`;
export const EPIC_3666_BUG_1938 = `release-epic-3666-bug-1938-${env}`;
export const EPIC_3666_BUG_4866 = `release-epic-3666-bug-4866-${env}`;
export const EPIC_3666_BUG_1963 = `release-epic-3666-bug-1963-${env}`;
export const EPIC_3666_BUG_5090 = `release-epic-3666-bug-5090-${env}`;
export const EPIC_3666_BUG_5150 = `release-epic-3666-bug-5150-${env}`;
export const EPIC_2966_BUG_5383 = `release-epic-2966-bug-5383-${env}`;
export const EPIC_4594 = `release-epic-4594-AnnouncementPopups-${env}`;
export const EPIC_4594_STORY_4663 = `release-epic-4594-story-4663-${env}`;
export const EPIC_385_STORY_752 = `release-epic-385-story-752-${env}`;
export const EPIC_3665_STORY_5127 = `release-epic-3665-story-5127-${env}`;
export const EPIC_4601_BUG_4983 = `release-epic-4601-bug-4983-${env}`;
export const EPIC_3176_story_3685 = `release-epic-3176-story-3685-${env}`;
export const EPIC_4861_TASK_5321 = `release-epic-4861-task-5321-${env}`;
export const EPIC_4861_TASK_5320 = `release-epic-4861-task-5320-${env}`;
export const EPIC_4861_STORY_2881 = `release-epic-4861-story-2881-${env}`;
export const EPIC_4861_STORY_5222 = `release-epic-4861-story-5222-${env}`;
export const EPIC_4861_STORY_3675 = `release-epic-4861-story-3675-${env}`;
export const EPIC_4861_STORY_3430 = `release-epic-4861-story-3430-${env}`;
export const EPIC_5134_BUG_5295 = `release-epic-5134-bug-5295-${env}`;
export const EPIC_5134_BUG_5297 = `release-epic-5134-bug-5297-${env}`;
export const EPIC_5134_BUG_5472 = `release-epic-5134-bug-5472-${env}`;
export const EPIC_3660_STORY_4182 = `release-epic-3660-story-4182-${env}`;
export const EPIC_3778_STORY_745 = `release-epic-3778-story-745-${env}`;
export const EPIC_2209_STORY_3779 = `release-epic-2209-story-3779-${env}`;
export const EPIC_2209_STORY_3780 = `release-epic-2209-story-3780-${env}`;
export const EPIC_2209_STORY_5188 = `release-epic-2209-story-5188-${env}`;
export const EPIC_3662_STORY_3189 = `release-epic-3662-story-3189-${env}`;
export const EPIC_2966_TASK_5300 = `release-epic-2966-task-5300-${env}`;
export const EPIC_2966_BUG_4694 = `release-epic-2966-bug-4694-${env}`;
export const EPIC_1450_STORY_5475 = `release-epic-1450-story-5475-${env}`;
export const EPIC_3681_STORY_5237 = `release-epic-3861-story-5237-${env}`;
export const EPIC_3861_STORY_5228 = `release-epic-3861-story-5228-${env}`;
export const EPIC_3861_STORY_5377 = `release-epic-3861-story-5377-${env}`;
export const EPIC_3861_STORY_5516 = `release-epic-3861-story-5516-${env}`;
export const EPIC_5209 = `release-epic-5209-company-name-and-logo-updates-${env}`;
export const EPIC_4210_STORY_2478 = `release-epic-4210-story-2478-${env}`;
export const OPERATIONAL_HUBSPOT_NEWSLETTER_SUBSCRIBE = `operational-hubspot-newsletter-subscribe-${env}`;
export const EPIC_2410_BUG_5257 = `release-epic-2410-bug-5257-${env}`;
export const EPIC_3725_BUG_4305 = `release-epic-3725-bug-4305-${env}`;
export const EPIC_3725_BUG_5410 = `release-epic-3725-bug-5410-${env}`;
export const EPIC_2966_BUG_3909 = `release-epic-2966-bug-3909-${env}`;
export const EPIC_121_BUG_5161 = `release-epic-121-story-5161-${env}`;
export const EPIC_4162_STORY_5469 = `release-epic-4162-story-5469-${env}`;
export const EPIC_4162_STORY_5536 = `release-epic-4162-story-5536-${env}`;
export const EPIC_4162_BUG_5548 = `release-epic-4162-bug-5548-${env}`;
export const EPIC_3346_STORY_4707 = `release-epic-3346-story-4707-${env}`;
export const EPIC_3303_TASK_4391 = `release-epic-3303-task-4391-${env}`;
export const EPIC_5532 = `release-epic-5532-${env}`;
export const EPIC_5481_STORY_5499 = `release-epic-5481-story-5499-${env}`;
export const EPIC_5481_STORY_5497 = `release-epic-5481-story-5497-${env}`;
export const EPIC_121_STORY_5578 = `release-epic-121-story-5578-${env}`;
