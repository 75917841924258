export var ApplicationReportColumns;
(function(ApplicationReportColumns2) {
  ApplicationReportColumns2["DateSubmitted"] = "date_submitted";
  ApplicationReportColumns2["StudentLegalName"] = "student_legal_name";
  ApplicationReportColumns2["StudentGradeLevel"] = "student_grade_level";
  ApplicationReportColumns2["AddressSchoolDistrict"] = "address_school_district";
  ApplicationReportColumns2["SpecialEducation"] = "meta_special_education";
  ApplicationReportColumns2["ParentLegalName"] = "parent_legal_name";
  ApplicationReportColumns2["ParentEmail"] = "parent_email";
})(ApplicationReportColumns || (ApplicationReportColumns = {}));
