import {ApplicationReportColumns} from "../enums/index.js";
export const applicationsReportDefaultColumns = [
  {
    label: "Date Submitted",
    columnName: "Date Submitted",
    value: ApplicationReportColumns.DateSubmitted
  },
  {
    label: "Student Legal Name",
    columnName: "Student Legal Name",
    value: ApplicationReportColumns.StudentLegalName
  },
  {
    label: "Student Grade Level",
    columnName: "Student Grade Level",
    value: ApplicationReportColumns.StudentGradeLevel
  },
  {
    label: "Address School District",
    columnName: "Address School District",
    value: ApplicationReportColumns.AddressSchoolDistrict
  },
  {
    label: "Special Education",
    columnName: "Special Education",
    value: ApplicationReportColumns.SpecialEducation
  },
  {
    label: "Parent Legal Name",
    columnName: "Parent Legal Name",
    value: ApplicationReportColumns.ParentLegalName
  },
  {
    label: "Parent Email",
    columnName: "Parent Email",
    value: ApplicationReportColumns.ParentEmail
  }
];
