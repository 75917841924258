import {gql} from "../../../../_snowpack/pkg/@apollo/client.js";
export const getCustomReportDataQuery = gql`
  query GetCustomReportDataByReportId(
    $reportId: Int!
    $skip: Int
    $sort: String
    $filter: ReportFilters
    $take: Int
    $search: String
  ) {
    getCustomReportDataByReportId(
      reportId: $reportId
      skip: $skip
      sort: $sort
      filter: $filter
      take: $take
      search: $search
    ) {
      data
      pageTotal
      report {
        id
        region_id
        school_year_id
        title
      }
      total
    }
  }
`;
export const getCustomReportQuery = gql`
  query GetCustomReportById($reportId: ID!) {
    getCustomReportById(reportId: $reportId) {
      id
      region_id
      title
      school_year_id
      customReportColumns {
        applicationQuestion {
          slug
        }
        application_question_id
        column_title
        custom_report_id
        defaultQuestion {
          slug
        }
        default_question_id
        default_value
        enrollmentQuestion {
          slug
        }
        enrollment_question_id
        id
        order
        programDetailColumn {
          slug
        }
        program_detail_column_id
        type
      }
    }
  }
`;
export const getCustomReportAndColumnsQuery = gql`
  query GetCustomReportById($reportId: ID!) {
    getCustomReportById(reportId: $reportId) {
      id
      region_id
      title
      school_year_id
      customReportColumns {
        id
        type
        order
        default_value
        column_title
        application_question_id
        default_question_id
        custom_report_id
        enrollment_question_id
        program_detail_column_id
        applicationQuestion {
          question
          id
          slug
          type
          order
        }
        defaultQuestion {
          id
          slug
          type
        }
        enrollmentQuestion {
          question
          id
          slug
          type
          order
        }
        programDetailColumn {
          id
          slug
          type
        }
      }
    }
  }
`;
