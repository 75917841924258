import React, {useEffect, useMemo, useState} from "../../../../_snowpack/pkg/react.js";
import DeleteForeverOutlinedIcon from "../../../../_snowpack/pkg/@mui/icons-material/DeleteForeverOutlined.js";
import ExpandMoreIcon from "../../../../_snowpack/pkg/@mui/icons-material/ExpandMore.js";
import {FormControl, Select, MenuItem, FormHelperText, Divider, IconButton, Tooltip, InputLabel} from "../../../../_snowpack/pkg/@mui/material.js";
import {Box} from "../../../../_snowpack/pkg/@mui/system.js";
import {map} from "../../../../_snowpack/pkg/lodash.js";
import {MthColor} from "../../../enums/index.js";
import {dropdownClasses} from "./styles.js";
export const DropDown = ({
  testId,
  dropDownItems,
  placeholder,
  placeholderColor,
  setParentValue,
  labelTop,
  disabled,
  disableColorChangeWhenDisabled = false,
  size,
  defaultValue,
  sx,
  error,
  dropdownColor,
  isAddable,
  auto = true,
  borderNone = false,
  color,
  id,
  labelTopBgColor,
  labelTopColor,
  deselectable,
  name,
  parentValue,
  useParentValue
}) => {
  const convertDefaultValue = (val) => {
    return !!val || val === 0 ? val : "";
  };
  const [value, setValue] = useState(convertDefaultValue(defaultValue));
  const handleChange = (val) => {
    const dropDownValidation = setParentValue(val);
    if (dropDownValidation)
      return;
    if (auto) {
      setValue(val);
    }
  };
  const shouldRenderDropdownValue = (dropdownValue2) => (!!dropdownValue2 || dropdownValue2 === 0) && dropdownValue2 !== "";
  useEffect(() => {
    setValue(convertDefaultValue(defaultValue));
  }, [defaultValue]);
  const dropdownValue = useMemo(() => {
    if (useParentValue) {
      return parentValue ?? "";
    }
    return value;
  }, [value, parentValue, useParentValue]);
  let renderDropDownItems = map(dropDownItems, (dropDownItem, index) => /* @__PURE__ */ React.createElement(MenuItem, {
    key: index,
    sx: {display: "flex", justifyContent: "space-between", whiteSpace: "inherit"},
    value: dropDownItem.value,
    disabled: dropDownItem.disabled === true
  }, dropDownItem.label, dropDownItem.hasDeleteIcon && /* @__PURE__ */ React.createElement(Tooltip, {
    title: "Delete",
    placement: "top"
  }, /* @__PURE__ */ React.createElement(IconButton, {
    onClick: () => {
      if (dropDownItem?.handleDeleteItem)
        dropDownItem?.handleDeleteItem(dropDownItem.value);
    }
  }, /* @__PURE__ */ React.createElement(DeleteForeverOutlinedIcon, {
    sx: {cursor: "pointer", width: "25px", height: "25px"},
    fontSize: "medium"
  })))));
  if (deselectable) {
    renderDropDownItems = [
      ...[
        /* @__PURE__ */ React.createElement(MenuItem, {
          key: `${name || "xunknown"}-0`,
          sx: {display: "flex", justifyContent: "space-between"},
          value: ""
        }, /* @__PURE__ */ React.createElement("span", {
          style: {color: "gray"}
        }, "Select"))
      ],
      ...renderDropDownItems
    ];
  }
  if (isAddable) {
    renderDropDownItems.push(/* @__PURE__ */ React.createElement(Divider, {
      key: -1
    }));
    renderDropDownItems.push(/* @__PURE__ */ React.createElement(MenuItem, {
      key: -2,
      value: 0
    }, "Add..."));
  }
  return /* @__PURE__ */ React.createElement(Box, {
    sx: {minWidth: 120, zIndex: 1, ...sx},
    "data-testid": testId,
    id
  }, !labelTop ? /* @__PURE__ */ React.createElement(React.Fragment, null, borderNone ? /* @__PURE__ */ React.createElement(FormControl, {
    variant: "standard",
    fullWidth: true,
    disabled: disabled || false,
    sx: {
      ...disableColorChangeWhenDisabled ? {
        ".Mui-disabled": {
          color: "initial",
          "-webkit-text-fill-color": "initial"
        }
      } : null
    }
  }, /* @__PURE__ */ React.createElement(Select, {
    size: "small",
    value: dropdownValue,
    IconComponent: (props) => /* @__PURE__ */ React.createElement(ExpandMoreIcon, {
      style: {color: color ?? MthColor.SKY},
      ...props
    }),
    disableUnderline: true,
    onChange: (e) => handleChange(e.target?.value),
    displayEmpty: true,
    renderValue: shouldRenderDropdownValue(dropdownValue) ? () => /* @__PURE__ */ React.createElement("span", {
      style: {color, marginRight: "15px"}
    }, dropDownItems?.find((item) => item.value.toString() === dropdownValue.toString())?.label) : () => /* @__PURE__ */ React.createElement("span", {
      style: {color: MthColor.BLUE_GRADIENT, marginRight: "15px"}
    }, placeholder),
    sx: {...dropdownClasses.borderNone},
    name
  }, renderDropDownItems), error?.error && /* @__PURE__ */ React.createElement(FormHelperText, {
    sx: {color: MthColor.RED}
  }, error?.errorMsg)) : /* @__PURE__ */ React.createElement(FormControl, {
    fullWidth: true,
    className: "MthFormField",
    sx: {
      ...disableColorChangeWhenDisabled ? {
        ".Mui-disabled": {
          color: "initial",
          "-webkit-text-fill-color": "initial"
        }
      } : null
    }
  }, /* @__PURE__ */ React.createElement(Select, {
    labelId: "demo-simple-select-label",
    id: "demo-simple-select",
    value: dropdownValue,
    onChange: (e) => handleChange(e.target?.value),
    displayEmpty: true,
    renderValue: shouldRenderDropdownValue(dropdownValue) ? () => /* @__PURE__ */ React.createElement("span", null, dropDownItems?.find((item) => item.value.toString() === dropdownValue.toString())?.label) : () => /* @__PURE__ */ React.createElement("span", {
      style: {color: placeholderColor ?? "gray"}
    }, placeholder),
    sx: {
      ...sx,
      "& .MuiSelect-outlined": {
        background: dropdownColor
      }
    },
    size: size || "medium",
    error: error?.error,
    disabled: disabled || false,
    name
  }, renderDropDownItems), error?.error && /* @__PURE__ */ React.createElement(FormHelperText, {
    sx: {color: MthColor.RED}
  }, error?.errorMsg))) : /* @__PURE__ */ React.createElement(FormControl, {
    fullWidth: true,
    className: "MthFormField",
    focused: true,
    error: error?.error,
    sx: {
      ...disableColorChangeWhenDisabled ? {
        ".Mui-disabled": {
          color: "initial",
          "-webkit-text-fill-color": "initial"
        }
      } : null
    }
  }, /* @__PURE__ */ React.createElement(InputLabel, {
    sx: {
      backgroundColor: labelTopBgColor ? labelTopBgColor : "#FFF",
      color: labelTopColor ? `${labelTopColor} !important` : MthColor.BLACK,
      paddingX: 1
    }
  }, placeholder), /* @__PURE__ */ React.createElement(Select, {
    labelId: "demo-simple-select-label",
    id: "demo-simple-select",
    value: dropdownValue,
    onChange: (e) => handleChange(e.target?.value),
    displayEmpty: true,
    renderValue: shouldRenderDropdownValue(dropdownValue) ? () => /* @__PURE__ */ React.createElement("span", {
      style: {
        color: dropDownItems?.find((item) => item.value.toString() === dropdownValue.toString())?.color ?? MthColor.BLACK
      }
    }, dropDownItems?.find((item) => item.value.toString() === dropdownValue.toString())?.label) : () => /* @__PURE__ */ React.createElement("span", {
      style: {color: "gray"}
    }, "Select"),
    sx: {
      ...sx,
      "& .MuiSelect-outlined": {
        background: dropdownColor
      }
    },
    size: size || "medium",
    error: error?.error,
    disabled: disabled || false,
    name
  }, renderDropDownItems), error?.error && /* @__PURE__ */ React.createElement(FormHelperText, {
    sx: {color: MthColor.RED}
  }, error?.errorMsg)));
};
